import Cookies from "js-cookie";
import { cookieNames } from "../constants";
const baseApiUrl = import.meta.env.VITE_REACT_APP_API_URL;

export const updateNote = async (note_id, noteText) => {
    try {
        let CSRFToken = Cookies.get(cookieNames.csrfToken);
        const response = await fetch(`${baseApiUrl}/usernotes/${note_id}`,
            {
                method: 'PATCH',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': CSRFToken
                },
                body: JSON.stringify({text: noteText})
            }
        );
        if (!response.ok) {
            throw new Error(`Error updating note: Status: ${response.status}`)
        }

        //await fetchNotesData(technology_id); // Should move this to component that is using this API call

    } catch (error) {
        console.error('Error fetching history data:', error);
    }
};

export const deleteNote = async (note_id) => {
    try {
        let CSRFToken = Cookies.get(cookieNames.csrfToken);
        const response = await fetch(`${baseApiUrl}/usernotes/${note_id}`,
            {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': CSRFToken
                },
            }
        );
        if (!response.ok) {
            throw new Error(`Error deleting note: Status: ${response.status}`)
        }
        //await fetchNotesData(technology_id); // Should move this to component that is using this API call

    } catch (error) {
        console.error('Error fetching history data:', error);
    }
};


export const fetchNotesData = async (rowId, search='', selectedFilters=[], verbose=false) => {
    try {
        const url = new URL(`${baseApiUrl}/usernotes`);
        const params = new URLSearchParams();

        if (rowId) {
            params.append('http_asset_id', rowId);
        }
        if (verbose) {
            params.append('verbose', verbose.toString());
        }
        if (search) {
            params.append('search', search);
        }
        if (selectedFilters.length > 0){
            console.log(selectedFilters.length);
            params.append('filter', selectedFilters.join(','));
        }

        url.search = params.toString();

        const response = await fetch(url.toString(),
            {
                method: 'GET',
                credentials: 'include'
            }
        );
        if (!response.ok) {
            throw new Error('Failed getting notes')
        }
        const data = await response.json();
        return data
        // Handle the received history data
        // You might want to store this in a state or handle it differently
    } catch (error) {
        console.error('Error fetching history data:', error);
    }
};

export const fetchAllNotes = async () => {
    try {

        const response = await fetch(`${baseApiUrl}/usernotes`,
            {
                method: 'GET',
                credentials: 'include'
            }
        );
        if (!response.ok) {
            throw new Error('Failed getting notes')
        }
        const data = await response.json();
        return data
        // Handle the received history data
        // You might want to store this in a state or handle it differently
    } catch (error) {
        console.error('Error fetching history data:', error);
    }
};

export const createNewNote = async (http_asset_id, noteText) => {
            try {
                let CSRFToken = Cookies.get(cookieNames.csrfToken);
                const response = await fetch(`${baseApiUrl}/usernotes`,
                    {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRFToken': CSRFToken
                            },
                        body: JSON.stringify({ text: noteText, http_asset: http_asset_id})}
                    );
                if(!response.ok){
                    throw new Error('Failed creating note')
                }
                return await response.json();
            } catch (error) {
                console.error('Error fetching history data:', error);
            }
        };


export const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    let CSRFToken = Cookies.get(cookieNames.csrfToken);

    try {
        const response = await fetch(`${baseApiUrl}/userimages`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'X-CSRFToken': CSRFToken
            },
            body: formData,
        });
        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error uploading image:', error);
        // Handle error
    }
};

export const getAssetSuggestions = async (startingWith, filterType="startswith") => {
    try {
        let url;
        if (filterType === "startswith") {
            url = `${baseApiUrl}/assets/search?startswith=${startingWith}`
        }
        else if (filterType === "contains") {
            url = `${baseApiUrl}/assets/search?contains=${startingWith}`
        }
        else{
            throw new Error('Invalid filter type')
        }

        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error searching assets')
    }
}

export const createAlertFromNote = async (noteID, severity, title) => {
    try {
        let CSRFToken = Cookies.get(cookieNames.csrfToken);
        const response = await fetch(`${baseApiUrl}/alerts`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': CSRFToken
            },
            body: JSON.stringify({
                description: noteID,
                severity: severity,
                title: title
            })
        });

        if (!response.ok) {
            throw new Error(`Error creating alert: Status: ${response.status}`)
        }

        const data = await response.json();
        console.log(data);
    } catch (error) {
        console.error(error);
    }
};

export const getAlerts = async ( filter = '', page= 1) => {
    try {
        const response = await fetch(`${baseApiUrl}/alerts?resolved=${filter}&page=${page}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error creating alert: Status: ${response.status}`)
        }

        const data = await response.json();
        return data;
    } catch (error) {
    }
};

export const updateAlertStatus = async (alertId, newStatus) => {
    try {
        let CSRFToken = Cookies.get(cookieNames.csrfToken);
        const response = await fetch(`${baseApiUrl}/alerts/${alertId}`,
            {
                method: 'PATCH',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': CSRFToken
                },
                body: JSON.stringify({resolved: newStatus === 'Yes'})
            }
        );
        if (!response.ok) {
            throw new Error(`Error updating alert: Status: ${response.status}`)
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error updating alert status:', error);
    }
};