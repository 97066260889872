import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { PortScan } from '@/types/api/response/investigations';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import UniversalFilter, { AvailableFilter } from '@/v2/components/UniversalFilter/UniversalFilter';
import { getPortScanChangesQuery, getPortStatsQuery } from '@/v2/queries/investigation';
import { formatTimestamp } from '@/v2/utils';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ListFilter } from 'lucide-react';
import { parseAsArrayOf, parseAsBoolean, parseAsInteger, parseAsString, useQueryState } from 'nuqs';
import { DateRange } from 'react-day-picker';

export default function PortAnalyzer() {
  const [selectedPorts, setSelectedPorts] = useQueryState(
    'selectedPorts',
    parseAsArrayOf(parseAsInteger).withDefault([]),
  );
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [fromDate, setFromDate] = useQueryState(
    'fromDate',
    parseAsString.withDefault(
      new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0],
    ),
  );
  const [toDate, setToDate] = useQueryState(
    'toDate',
    parseAsString.withDefault(new Date().toISOString().split('T')[0]),
  );
  const [quickSelect, setQuickSelect] = useQueryState(
    'quickSelect',
    parseAsString.withDefault(null),
  );
  const [ipFilter, setIpFilter] = useQueryState('ipFilter', parseAsString.withDefault(null));
  const [showClosedPorts, setShowClosedPorts] = useQueryState(
    'showClosedPorts',
    parseAsBoolean.withDefault(false),
  );
  const [selectedServices, setSelectedServices] = useQueryState(
    'selectedServices',
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [selectedVersions, setSelectedVersions] = useQueryState(
    'selectedVersions',
    parseAsArrayOf(parseAsString).withDefault([]),
  );

  const porScansParams = {
    page,
    ipaddress: ipFilter,
    ports: selectedPorts,
    services: selectedServices,
    versions: selectedVersions,
    state: showClosedPorts ? ['closed', 'open'] : ['open'],
    from_date: undefined,
    to_date: undefined,
  };

  const portStatsParams = {
    from_date: undefined,
    to_date: undefined,
    page,
  };

  if (quickSelect) {
    porScansParams.from_date = fromDate;
    porScansParams.to_date = toDate;
    portStatsParams.from_date = fromDate;
    portStatsParams.to_date = toDate;
  }

  const { data, error, isLoading } = useQuery(getPortScanChangesQuery(porScansParams));
  const { data: portStats } = useQuery(getPortStatsQuery(portStatsParams));

  const isPortFilterActive = () => {
    return selectedPorts.length > 0;
  };

  const isServiceFilterActive = () => {
    return selectedServices.length > 0;
  };

  const isVersionFilterActive = () => {
    return selectedVersions.length > 0;
  };

  const handleServiceChange = (service: string, checked: boolean) => {
    const newServices = checked
      ? [...selectedServices, service]
      : selectedServices.filter((s) => s !== service);
    setSelectedServices(newServices);
    setPage(1);
  };

  const handleVersionChange = (version: string, checked: boolean) => {
    const newVersions = checked
      ? [...selectedVersions, version]
      : selectedVersions.filter((v) => v !== version);
    setSelectedVersions(newVersions);
    setPage(1);
  };

  const handleSelectPorts = (ports: number[]) => {
    setSelectedPorts(ports);
    setPage(1);
  };

  const handleSelectState = (state: boolean) => {
    setShowClosedPorts(state);
    setPage(1);
  };

  const stateColumnHeader = () => (
    <div>
      <Popover>
        <PopoverTrigger asChild>
          <button className="flex items-center justify-center w-full">
            <span>State</span>
            <ListFilter
              className={`h-3.5 w-3.5 ml-1 ${showClosedPorts ? 'text-blue-400' : 'opacity-50 hover:opacity-100'}`}
            />
          </button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-2 bg-gray-50">
          <div className="max-h-[300px] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
            <label className="flex items-center space-x-2 p-1 hover:bg-gray-100">
              <input
                type="checkbox"
                checked={showClosedPorts}
                onChange={(e) => handleSelectState(e.target.checked)}
                className="bg-white border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span>Show closed ports</span>
            </label>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );

  const portColumnHeader = () => (
    <Popover>
      <PopoverTrigger asChild>
        <button className="flex items-end justify-center w-full">
          <span>Port</span>
          <ListFilter
            className={`h-3.5 w-3.5 ml-1 mb-0.5 ${isPortFilterActive() ? 'text-blue-400' : 'opacity-50 hover:opacity-100'}`}
          />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-2 bg-gray-50">
        <div className="max-h-[300px] overflow-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:bg-gray-300 [&::-webkit-scrollbar-thumb]:rounded-full">
          {portStats?.port_stats.map((port) => (
            <label key={port.port} className="flex items-center space-x-2 p-1 hover:bg-gray-100">
              <input
                type="checkbox"
                checked={selectedPorts.includes(port.port)}
                onChange={(e) => {
                  const newPorts = e.target.checked
                    ? [...selectedPorts, port.port]
                    : selectedPorts.filter((p) => p !== port.port);
                  handleSelectPorts(newPorts);
                }}
                className="bg-white border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span>{`${port.port} (${port.count})`}</span>
            </label>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );

  const serviceColumnHeader = () => (
    <Popover>
      <PopoverTrigger asChild>
        <button className="flex items-center justify-center w-full">
          <span>Service</span>
          <ListFilter
            className={`h-3.5 w-3.5 ml-1 ${isServiceFilterActive() ? 'text-blue-400' : 'opacity-50 hover:opacity-100'}`}
          />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-2 bg-gray-50">
        <div className="max-h-[300px] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
          {portStats?.service_stats.map((service) => (
            <label
              key={service.service}
              className="flex items-center space-x-2 p-1 hover:bg-gray-100"
            >
              <input
                type="checkbox"
                checked={selectedServices.includes(service.service)}
                onChange={(e) => handleServiceChange(service.service, e.target.checked)}
                className="bg-white border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span>{`${service.service} (${service.count})`}</span>
            </label>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );

  const versionColumnHeader = () => (
    <Popover>
      <PopoverTrigger asChild>
        <button className="flex items-center justify-center w-full">
          <span>Version</span>
          <ListFilter
            className={`h-3.5 w-3.5 ml-1 ${isVersionFilterActive() ? 'text-blue-400' : 'opacity-50 hover:opacity-100'}`}
          />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-2 bg-gray-50">
        <div className="max-h-[300px] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
          {portStats?.service_version_stats.map((version) => (
            <label
              key={version.service_version}
              className="flex items-center space-x-2 p-1 hover:bg-gray-100"
            >
              <input
                type="checkbox"
                checked={selectedVersions.includes(version.service_version)}
                onChange={(e) => handleVersionChange(version.service_version, e.target.checked)}
                className="bg-white border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <span>{`${version.service_version} (${version.count})`}</span>
            </label>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );

  const stateChangesIndicator = (row: PortScan) => {
    const state = row.state;
    const previousState = row.previous_state;

    if (state === previousState) {
      return state;
    }

    if (previousState === 'unknown') {
      return `${state} (New)`;
    }

    return `${previousState} -> ${state}`;
  };

  const serviceChangesIndicator = (row: PortScan) => {
    const service = row.service;
    const previousService = row.previous_service;

    if (service === previousService) {
      return service;
    }

    if (previousService === '') {
      return `${service} (New)`;
    }

    return `${previousService} -> ${service}`;
  };

  const versionChangesIndicator = (row: PortScan) => {
    const version = row.version;
    const previousVersion = row.previous_version;

    if (!version && !previousVersion) {
      return '-';
    }

    if (version === previousVersion) {
      return version;
    }

    if (version === '') {
      return '-';
    }

    if (previousVersion === '') {
      return `${version} (New)`;
    }

    return `${previousVersion} -> ${version}`;
  };

  const columnHelper = createColumnHelper<PortScan>();

  const columns: ColumnDef<PortScan>[] = [
    columnHelper.accessor('ipaddress', {
      header: 'IP Address',
      cell: ({ row }) => (
        <div>
          <span
            className="cursor-pointer hover:underline"
            onClick={() => setIpFilter(row.original.ipaddress)}
            title="Click to filter results by this IP address"
          >
            {row.original.ipaddress}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor('port', {
      header: portColumnHeader,
      cell: ({ row }) => (
        <div className="text-center">
          <span
            className="cursor-pointer hover:underline"
            onClick={() => setSelectedPorts([row.original.port])}
            title="Click to filter results by this port"
          >
            {row.original.port}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor('state', {
      header: stateColumnHeader,
      cell: ({ row }) => (
        <div
          className={`text-center p-1 rounded-md ${
            row.original.state === 'open'
              ? 'bg-green-100 text-green-800'
              : 'bg-red-100 text-red-800'
          }`}
        >
          {stateChangesIndicator(row.original)}
        </div>
      ),
    }),
    columnHelper.accessor('service', {
      header: serviceColumnHeader,
      cell: ({ row }) => (
        <div className="text-center">
          <span
            className={row.original.service ? 'cursor-pointer hover:underline' : ''}
            onClick={() => row.original.service && setSelectedServices([row.original.service])}
            title={row.original.service ? 'Click to filter results by this service' : ''}
          >
            {serviceChangesIndicator(row.original)}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor('version', {
      header: versionColumnHeader,
      cell: ({ row }) => (
        <div className="text-center">
          <span
            className={row.original.version ? 'cursor-pointer hover:underline' : ''}
            onClick={() => row.original.version && setSelectedVersions([row.original.version])}
            title={row.original.version ? 'Click to filter results by this version' : ''}
          >
            {versionChangesIndicator(row.original)}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor('scanned_at', {
      header: () => <div className="flex justify-center">Scanned At</div>,
      cell: ({ row }) => (
        <span className="flex justify-center">{formatTimestamp(row.original.scanned_at)}</span>
      ),
    }),
  ];

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleDateRangeChange = (newRange: DateRange | undefined) => {
    setFromDate(newRange?.from?.toISOString().split('T')[0] ?? '');
    setToDate(newRange?.to?.toISOString().split('T')[0] ?? '');
  };

  const handleIpFilterChange = (ip: string | undefined | null) => {
    setPage(1);
    setIpFilter(ip === null || ip === undefined || ip === '' ? null : ip);
  };

  const availableFilters: AvailableFilter[] = [
    {
      type: 'date-input',
      key: 'date-input',
      label: 'Date Range',
      fromDate,
      toDate,
      state: quickSelect,
      setState: setQuickSelect as (value: string | null) => void,
      placeholder: 'Select date range',
      setDateRange: handleDateRangeChange,
    },
    {
      type: 'text',
      key: 'ipaddress',
      label: 'IP Address',
      placeholder: 'Type IP address or CIDR (e.g. 10.0.0.0/24)',
      state: ipFilter,
      setState: handleIpFilterChange,
    },
  ];

  const clearAllFilters = () => {
    setQuickSelect(null);
    setFromDate(new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0]);
    setToDate(new Date().toISOString().split('T')[0]);
    setIpFilter(null);
    setSelectedPorts([]);
    setSelectedServices([]);
    setSelectedVersions([]);
  };

  return (
    <div className="p-4 max-w-[2000px] mx-auto">
      <UniversalFilter
        filters={availableFilters}
        clearAllFilters={clearAllFilters}
        className="mb-4"
      />
      <DataTable
        columns={columns}
        data={data?.entries ?? []}
        currentPage={page}
        totalPages={data?.total_pages ?? 0}
        totalEntries={data?.total_count ?? 0}
        onPageChange={handlePageChange}
        error={error}
        loading={isLoading}
        maxWidth="w-full"
      />
    </div>
  );
}
