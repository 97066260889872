import { DateRange } from 'react-day-picker';
import DateTimeRangePicker from '../../../pages/Investigation/DateTimeRangePicker';
interface DateInputComponentProps {
  fromDate: string;
  toDate: string;
  setDateRange: (dateRange: DateRange) => void;
  quickSelect: string;
  setQuickSelect: (quickSelect: string) => void;
}

export default function DateInputComponent({
  fromDate,
  toDate,
  setDateRange,
  quickSelect,
  setQuickSelect,
}: DateInputComponentProps) {
  return (
    <div className="flex items-start w-fit">
      <DateTimeRangePicker
        dateRange={{
          from: fromDate ? new Date(fromDate) : undefined,
          to: toDate ? new Date(toDate) : undefined,
        }}
        setDateRange={setDateRange}
        quickSelect={quickSelect}
        setQuickSelect={setQuickSelect}
      />
    </div>
  );
}
