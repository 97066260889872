import { getOrganizationsApi, getUserDataApi, loginApi } from '@/api/auth.api';
import { Organization, User } from '@/types/api/response/user';
import Cookies from 'js-cookie';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { cookieNames } from '../constants';

// Define the Google response type
interface GoogleResponse {
  credential: string;
  // Add other relevant fields from Google response
}

// Define error types
interface AuthError {
  message: string;
  code?: string;
}

export interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  username: string;
  organizationIcon: string;
  isStaff: boolean;
  organizationFocus: string;
  setOrganizationFocus: (organizationFocus: string) => void;
  staffOrganizations: Organization[];
  userOrganization: string;
  login: (username: string, password: string) => Promise<Response>;
  logout: () => Promise<void>;
  handleGoogleSuccess: (googleResponse: GoogleResponse) => Promise<void>;
  handleGoogleFailure: (error: AuthError) => void;
  getOrganizations: () => Promise<void>;
  setAuthState: () => void;
  getCSRFCookie: () => string | undefined;
  loading: boolean;
  userData: User | null;
}

// Create the context with proper typing
export const AuthContext = createContext<AuthContextType | null>(null);

const baseApiUrl = import.meta.env.VITE_REACT_APP_API_URL;

interface AuthProviderProps {
  children: React.ReactNode;
}

let authState: { isAuthenticated: boolean; loading: boolean } | null = null;

export const setAuth = (state: typeof authState) => {
  authState = state;
};

export const getAuth = () => {
  if (!authState) {
    throw new Error('Auth not initialized');
  }
  return authState;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState<User | null>(null);
  const [username, setUsername] = useState<string>('');
  const [isStaff, setIsStaff] = useState<boolean>(false);
  const [staffOrganizations, setStaffOrganizations] = useState<Organization[]>([]);
  const [organizationFocus, setOrganizationFocus] = useState<string>('');
  const [userOrganization, setUserOrganization] = useState<string>('');
  const [organizationIcon, setOrganizationIcon] = useState<string>('');
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    const getUserData = async () => {
      const [userData, organizations] = await Promise.all([
        getUserDataApi(),
        getOrganizationsApi(),
      ]);
      setUserData(userData);
      setUserOrganization(organizations.entries[0].customername);
      setOrganizationIcon(organizations.entries[0].icon_url);
      setUsername(userData.username);
      setIsStaff(userData.is_staff);
      setIsAuthenticated(true);
    };
    getUserData()
      .catch((error) => {
        console.error('Error getting user data:', error);
        setIsAuthenticated(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const login = async (username: string, password: string) => {
    try {
      // Replace this with your login API call
      const response = await loginApi(username, password);
      if (response.ok) {
        setIsAuthenticated(true);
        const answer = await getUserDataApi();
        setUserData(answer);
        setUsername(answer.username); // Set user data received from backend
        setIsStaff(answer.is_staff);
      }
      return response;
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
      // Handle login error (e.g., show error message)
    }
  };

  const handleGoogleSuccess = async (googleResponse: GoogleResponse) => {
    /* Not currently implemented */
    try {
      // Send token to backend for validation and further processing
      // Adjust according to the structure of googleResponse
      const response = await fetch(baseApiUrl + '/google-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: googleResponse.credential }),
      });

      // Handle the response from your backend
      // You may want to authenticate the user in your React app
      // after successful Google authentication
    } catch (error) {
      console.error('Google Login Error', error);
      // Handle errors (e.g., displaying a message)
    }
  };

  const handleGoogleFailure = (error: AuthError) => {
    console.error('Google Login Failed', error);
    // Handle Google Login failure
  };

  const getOrganizationsDetails = async () => {
    try {
      const response = await getOrganizationsApi();
      if (response.entries && response.entries.length === 1) {
        setUserOrganization(response.entries[0].customername);
        setOrganizationIcon(response.entries[0].icon_url);
      } else {
        setStaffOrganizations(response.entries);
        setOrganizationFocus('All organizations');
      }
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };

  const setAuthState = () => {
    setIsAuthenticated(true);
  };

  const getCSRFCookie = () => {
    return Cookies.get(cookieNames.csrfToken);
  };

  const logout = async () => {
    try {
      const response = await fetch(baseApiUrl + '/logout', {
        method: 'GET',
        credentials: 'include',
      });
      await response.json();
      setIsAuthenticated(false);
      setUserData(null);
      setStaffOrganizations([]);
      setOrganizationFocus('');
      setUserOrganization('');
      setIsStaff(false);
    } catch (error) {
      console.error('Error logging out: ', error);
    }
  };

  async function getCSRFToken() {
    // Function not currently used
    try {
      const response = await fetch(baseApiUrl + '/get-csrf', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const respAsJSON = await response.json();
      return respAsJSON.csrfToken;
    } catch (error) {
      console.error('Error obtaining CSRF token:', error);
      return null;
    }
  }

  // Placeholder function to simulate API login
  async function handleLogin(username, password) {
    try {
      let CSRFToken = Cookies.get(cookieNames.csrfToken);

      if (!CSRFToken) {
        CSRFToken = await getCSRFToken();
        console.log(CSRFToken);
      }
      const response = await fetch(baseApiUrl + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(CSRFToken && { 'X-CSRFToken': CSRFToken }),
        },
        credentials: 'include',
        body: JSON.stringify({ username, password }),
      });

      return response;
    } catch (error) {
      console.error('Error logging in:', error);
      throw error;
    }
  }

  const value: AuthContextType = {
    isAuthenticated,
    setIsAuthenticated,
    username,
    organizationIcon,
    isStaff,
    organizationFocus,
    setOrganizationFocus,
    staffOrganizations,
    userOrganization,
    login,
    logout,
    handleGoogleSuccess,
    handleGoogleFailure,
    getOrganizations: getOrganizationsDetails,
    setAuthState,
    getCSRFCookie,
    loading,
    userData,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Type-safe useAuth hook
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
