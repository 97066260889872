import { DateTime } from 'luxon';

export function formatTimestamp(timestamp: string, options?: Intl.DateTimeFormatOptions): string {
  const date = DateTime.fromISO(timestamp);
  return date.toLocaleString(options);
}

export function checkIfEmpty(value: string): boolean {
  return value === '' || value === null || value === undefined;
}
