import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useState } from 'react';
interface GenericInputWithSelectProps {
  inputValue: string;
  setInputValue: (value: string) => void;
  inputType: string;
  setInputType: (value: string) => void;
  onClear: () => void;
  placeholder: string;
  options: { label: string; value: string }[];
  label: string;
  defaultValue: string;
}

export default function GenericInputWithSelect({
  inputValue,
  setInputValue,
  inputType,
  setInputType,
  onClear,
  placeholder,
  options,
  label,
  defaultValue,
}: GenericInputWithSelectProps) {
  const [search, setSearch] = useState<string>(inputValue);

  const handleInputTypeChange = (value: string) => {
    setInputType(value);
  };

  const handleSearchChange = (value: string) => {
    if (value.length > 0) {
      setSearch(value);
    } else {
      setSearch('');
      onClear();
    }
  };

  const onSubmit = () => {
    setInputValue(search);
  };

  return (
    <div className="flex flex-row w-fit">
      <Button variant="outline" className="font-normal">
        {label}
      </Button>
      <Select onValueChange={handleInputTypeChange} defaultValue={defaultValue} value={inputType}>
        <SelectTrigger className="w-fit">
          <SelectValue placeholder="Search by" />
        </SelectTrigger>
        <SelectContent defaultValue={options[0].value}>
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Input
        placeholder={placeholder}
        value={search}
        onChange={(e) => handleSearchChange(e.target.value)}
        showClearButton={search.length > 0}
        onClear={onClear}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSubmit();
          }
        }}
      />
    </div>
  );
}
