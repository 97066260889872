import { addIPAddress, getServices } from '@/api/services.api';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Form, FormControl, FormDescription, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/hooks/use-toast';
import { ServiceResponse } from '@/types/api/response/services';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import UniversalFilter, { AvailableFilter } from '@/v2/components/UniversalFilter/UniversalFilter';
import { formatTimestamp } from '@/v2/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { parseAsArrayOf, parseAsInteger, parseAsString, useQueryState } from 'nuqs';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const formSchema = z.object({
  ipAddress: z.string().min(2, { message: 'IP Address or CIDR Range is required' }),
  notes: z.string().optional(),
});

const Services = () => {
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [selectedPorts, setSelectedPorts] = useQueryState(
    'ports',
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [selectedServices, setSelectedServices] = useQueryState(
    'services',
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [selectedBanners, setSelectedBanners] = useQueryState(
    'banners',
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [addIPDialogOpen, setAddIPDialogOpen] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ipAddress: '',
      notes: '',
    },
  });

  const { data, isLoading, error, refetch } = useQuery<ServiceResponse>({
    queryKey: ['services', page, selectedPorts, selectedServices, selectedBanners],
    queryFn: async () => {
      const response = await getServices({
        page,
        ports: selectedPorts.join(','),
        services: selectedServices.join(','),
        serviceversion: selectedBanners.join(','),
      });
      return response;
    },
  });

  const ports = data?.port_overview
    ? data.port_overview.map((port) => ({
        label: `${port.port} (${port.count})`,
        value: port.port.toString(),
      }))
    : [];
  const services = data?.service_overview
    ? data.service_overview.map((service) => ({
        label: `${service.service} (${service.count})`,
        value: service.service,
      }))
    : [];
  const banners = data?.service_version_overview
    ? data.service_version_overview.map((banner) => ({
        label: `${
          banner.service_version === '' ? 'Unknown' : banner.service_version
        } (${banner.count})`,
        value: banner.service_version,
      }))
    : [];

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    mutate(data);
  };

  const handleSelectPorts = (value: string | string[]) => {
    setSelectedPorts(value as string[]);
    setPage(1);
  };

  const handleSelectServices = (value: string | string[]) => {
    setSelectedServices(value as string[]);
    setPage(1);
  };

  const handleSelectBanners = (value: string | string[]) => {
    setSelectedBanners(value as string[]);
    setPage(1);
  };

  const columns: ColumnDef<ServiceResponse['entries'][0]>[] = [
    {
      header: 'IP Address',
      accessorKey: 'ps_ipaddress',
    },
    {
      header: 'Port',
      accessorKey: 'ps_port',
      cell: ({ row }) => {
        return (
          <div
            onClick={() => handleSelectPorts([row.original.ps_port.toString()])}
            className="cursor-pointer hover:underline"
            title="Click to select port"
          >
            {row.original.ps_port || 'N/A'}
          </div>
        );
      },
    },
    {
      header: 'Service',
      accessorKey: 'ss_service',
      cell: ({ row }) => {
        return (
          <div
            onClick={() => handleSelectServices([row.original.ss_service])}
            className="cursor-pointer hover:underline"
            title="Click to select service"
          >
            {row.original.ss_service || 'N/A'}
          </div>
        );
      },
    },
    {
      header: 'Service Version',
      accessorKey: 'ss_serviceversion',
      cell: ({ row }) => {
        return (
          <div
            onClick={() => handleSelectBanners([row.original.ss_serviceversion])}
            className="cursor-pointer hover:underline"
            title="Click to select service version"
          >
            {row.original.ss_serviceversion || 'N/A'}
          </div>
        );
      },
    },
    {
      header: 'Last Scanned',
      accessorKey: 'ps_last_scanned',
      cell: ({ row }) => {
        return <div>{formatTimestamp(row.original.ps_last_scanned)}</div>;
      },
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const {
    mutate,
    isPending,
    error: addIPError,
  } = useMutation({
    mutationFn: async (data: z.infer<typeof formSchema>) => {
      return await addIPAddress({
        ipaddress: data.ipAddress.split('\n'),
        notes: data.notes,
      });
    },
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'IP address added successfully',
      });
      setAddIPDialogOpen(false);
      form.reset();
      refetch();
    },
    onError: (error) => {
      toast({
        title: 'Error',
        description: error.message,
      });
    },
  });

  const filters: AvailableFilter[] = [
    {
      label: 'Port',
      state: selectedPorts,
      type: 'multiSelect',
      key: 'ports',
      setState: handleSelectPorts,
      placeholder: 'Select Ports',
      options: ports,
    },
    {
      label: 'Service',
      state: selectedServices,
      type: 'multiSelect',
      key: 'services',
      setState: handleSelectServices,
      placeholder: 'Select Services',
      options: services,
    },
    {
      label: 'Service Version',
      state: selectedBanners,
      type: 'multiSelect',
      key: 'banners',
      setState: handleSelectBanners,
      placeholder: 'Select Service Versions',
      options: banners,
    },
  ];

  const handleClearAllFilters = () => {
    setSelectedPorts([]);
    setSelectedServices([]);
    setSelectedBanners([]);
    setPage(1);
  };

  return (
    <>
      <div className="p-4 max-w-[2000px] mx-auto">
        <div className="flex justify-between items-center mb-4 gap-4">
          <UniversalFilter filters={filters} clearAllFilters={handleClearAllFilters} />
          <Dialog open={addIPDialogOpen} onOpenChange={setAddIPDialogOpen}>
            <DialogTrigger>
              <Button>Add IP address or CIDR</Button>
            </DialogTrigger>
            <DialogContent aria-describedby="dialog-description">
              <DialogHeader>
                <DialogTitle>Add IP Address or CIDR Range</DialogTitle>
                <DialogDescription id="dialog-description">
                  Add IP addresses or CIDR ranges to associate with your organization
                </DialogDescription>
              </DialogHeader>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                  <div className="mb-4">
                    <FormItem>
                      <FormLabel>IP Addresses</FormLabel>
                      <FormDescription>IP Address range or CIDR range.</FormDescription>
                      <FormControl>
                        <Textarea
                          {...form.register('ipAddress')}
                          placeholder="Enter IP adresses , one per line"
                        />
                      </FormControl>
                      <FormDescription className="text-red-500">
                        {form.formState.errors.ipAddress?.message}
                      </FormDescription>
                    </FormItem>
                    <FormItem>
                      <FormLabel>Notes</FormLabel>
                      <FormDescription>
                        Optional text to indicate why this IP is associated with your organization..
                      </FormDescription>
                      <FormControl>
                        <Input {...form.register('notes')} placeholder="Any relevant notes" />
                      </FormControl>
                      <FormDescription className="text-red-500">
                        {form.formState.errors.notes?.message}
                      </FormDescription>
                    </FormItem>
                  </div>
                  <Button type="submit" disabled={isPending}>
                    {isPending ? 'Adding...' : 'Add'}
                  </Button>
                </form>
              </Form>
            </DialogContent>
          </Dialog>
        </div>
        <DataTable
          columns={columns}
          data={data?.entries || []}
          loading={isLoading}
          currentPage={page}
          totalPages={data?.total_pages || 1}
          totalEntries={data?.total_count || 0}
          onPageChange={handlePageChange}
          error={error}
          tableHeight="calc(100vh - 220px)"
        />
      </div>
    </>
  );
};

export default Services;
