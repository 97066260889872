import {
  createAlertFromNoteAPI,
  createAlertFromNoteParams,
  createNewNoteAPI,
  createNewNoteParams,
  deleteNoteAPI,
  getUserNotes,
  getUserNotesParams,
  updateNoteAPI,
} from '@/api/notes.api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const getUserNotesQuery = ({ verbose, httpAsset, search, filter }: getUserNotesParams) => {
  return {
    queryKey: ['userNotes', verbose, httpAsset, search, filter],
    queryFn: () => getUserNotes({ verbose, httpAsset, search, filter }),
  };
};

type UseCreateNewNoteParams = {
  onSuccess?: () => void;
};

export function useCreateNewNote({ onSuccess = () => {} }: UseCreateNewNoteParams) {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: (params: createNewNoteParams) => createNewNoteAPI(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userNotes'] });
      onSuccess?.();
    },
    onError: (error) => {
      console.error('Error creating new note', error);
      return error;
    },
  });
  return { mutate, isPending, error };
}

type UseDeleteNoteParams = {
  onSuccess?: () => void;
};

export function useDeleteNote({ onSuccess = () => {} }: UseDeleteNoteParams) {
  const queryClient = useQueryClient();
  const { mutate, isPending, error, data } = useMutation({
    mutationFn: (noteId: string) => deleteNoteAPI(noteId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userNotes'] });
      onSuccess?.();
    },
  });
  return { mutate, isPending, error, data };
}

type UseUpdateNoteParams = {
  onSuccess?: () => void;
};

export function useUpdateNote({ onSuccess = () => {} }: UseUpdateNoteParams) {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: ({ noteId, text }: { noteId: string; text: string }) =>
      updateNoteAPI(noteId, { text }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userNotes'] });
      onSuccess?.();
    },
  });
  return { mutate, isPending, error };
}

export function useCreateAlertFromNote() {
  const { mutate, isPending, error } = useMutation({
    mutationFn: (params: createAlertFromNoteParams) => createAlertFromNoteAPI(params),
  });
  return { mutate, isPending, error };
}
