import React, {useEffect, useState} from 'react';
import NotesList from './NotesList';
import NoteCreator from './NoteCreator';
import {createNewNote, deleteNote, fetchNotesData, updateNote} from "./NotesAPI";
import {Button} from '@chakra-ui/react';

const NotesTabDisplay = ({assetid, hostname}) => {
    const [showNoteCreator, setShowNoteCreator] = useState(false);
    const [notes, setNotes] = useState([]);
    const [editNote, setEditNote] = useState(null);

    useEffect(() => {
        const getNotes = async() => {
            const data = await fetchNotesData(assetid);
            setNotes(data);
        }
        getNotes();
    }, [assetid]);

    const handleOpenNoteCreator = () => {
        setShowNoteCreator(true);
    };

    const startNoteEdit = (noteToEdit) => {
        setEditNote(noteToEdit);
        setShowNoteCreator(true);
    }

    const formatDate = (dateString) => {
        const options = {year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'};
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handleCloseNoteCreator = () => {
        setShowNoteCreator(false);
        setEditNote(null);
    };

    const handleCreateNewNote = async (technology_id, noteText) => {
        const resp = await createNewNote(technology_id, noteText);
        // Should ideally just update the content with the response so we don't have to fetch all notes again
        const data2 = await fetchNotesData(technology_id);
        setNotes(data2);
        setShowNoteCreator(false);
        // Show data somewhere that note was successfully processed?
    }

    const handleNoteEdit = async (technology_id, note_id, noteText) => {
        const resp = await updateNote(note_id, noteText);
        const data2 = await fetchNotesData(technology_id);
        setNotes(data2);
        setShowNoteCreator(false);
        setEditNote(null);
    }

    const handleDeleteNote = async (noteId, technology_id) => {
        await deleteNote(noteId);
        const data = await fetchNotesData(technology_id);
        setNotes(data);
    };

    return (
        <div>
            <NotesList
                notes={notes}
                onDelete={(noteToDelete) => handleDeleteNote(noteToDelete, assetid)}
                onEdit = {(noteToEdit) => startNoteEdit(noteToEdit)}
            />
            <Button size="sm" className="load-more" onClick={handleOpenNoteCreator}>Create New Note</Button>
            {showNoteCreator && (
                <NoteCreator
                    headerContent={editNote ? "Editing note created on " + formatDate(editNote.created) : "New Note for " + hostname}
                    initialContent={editNote ? editNote.text : ''}
                    onSave={editNote ? (chosenTechnology, newNoteText) => handleNoteEdit(assetid, editNote.id, newNoteText) :
                        (chosenTechnology, newNoteText) => handleCreateNewNote(assetid, newNoteText)}
                    onCloseAction={handleCloseNoteCreator}
                />
            )}

        </div>
    );
};

export default NotesTabDisplay;
