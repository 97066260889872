import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Suggestion } from '@/types/api/response/assets';
interface AssetsFinderAutocompleteProps {
  assetFilter: string;
  handleAssetFilterChange: (value: string) => void;
  assetFilterValue: string;
  handleAssetFilterValueChange: (value: string) => void;
  suggestions: Suggestion[];
  showSuggestions: boolean;
  handleSuggestionClick: (suggestion: Suggestion) => void;
  onClear?: () => void;
  searchingBy?: string;
}

export default function AssetsFinderAutocomplete({
  assetFilter,
  handleAssetFilterChange,
  assetFilterValue,
  handleAssetFilterValueChange,
  suggestions,
  showSuggestions,
  handleSuggestionClick,
  onClear,
  searchingBy = 'Domain',
}: AssetsFinderAutocompleteProps) {
  const handleClear = () => {
    if (onClear) {
      onClear();
    } else {
      handleAssetFilterValueChange('');
    }
  };
  return (
    <div className="flex flex-row w-fit relative">
      <Button className="cursor-default font-normal" variant="outline">
        {searchingBy}
      </Button>
      <Select value={assetFilter} onValueChange={handleAssetFilterChange}>
        <SelectTrigger>
          <SelectValue placeholder="Search" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="startswith">Starts with</SelectItem>
          <SelectItem value="contains">Contains</SelectItem>
        </SelectContent>
      </Select>
      <Input
        placeholder="Search"
        value={assetFilterValue}
        onChange={(e) => handleAssetFilterValueChange(e.target.value)}
        className="min-w-[260px]"
        showClearButton={assetFilterValue.length > 0}
        onClear={handleClear}
      />
      {suggestions && showSuggestions && (
        <div className="absolute top-full left-0 z-50 w-full bg-background border border-border rounded-[--radius] shadow-md max-h-[200px] overflow-y-auto">
          {suggestions.map((suggestion) => (
            <div
              key={suggestion.id}
              className="px-3 py-2 hover:bg-accent cursor-pointer"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {`${suggestion.domain.domainname} (${suggestion.port})`}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
