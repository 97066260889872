import { fetchWrapper } from '@/lib/fetchWrapper';
import { Domain, DomainResponse } from '@/types/api/response/domains';

const endpoint = '/domains';

export interface GetDomainsParams {
  page?: number;
  domainname?: string;
}

export const getDomainsApi = async (
  params: Partial<GetDomainsParams> = { page: 1 },
): Promise<DomainResponse> => {
  const response = await fetchWrapper.get<DomainResponse>(endpoint, {
    params,
  });
  return response.data;
};

export type SearchDomainsParams = { startswith: string } | { contains: string };

export const searchDomainsApi = async (params: SearchDomainsParams): Promise<Domain[]> => {
  const response = await fetchWrapper.get<Domain[]>(`/domain/search`, {
    params,
  });
  return response.data;
};

export const addDomainApi = async (domains: string[], notes: string): Promise<Domain> => {
  const response = await fetchWrapper.post<Domain>(endpoint, {
    domainname: domains,
    notes,
  });
  return response.data;
};
