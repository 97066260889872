import { fetchWrapper } from '../lib/fetchWrapper';
import { IPAddressV4, IPAddressV4Response } from '../types/api/response/ipaddress';

export const getIP = async (ipId: number): Promise<IPAddressV4> => {
  const { data } = await fetchWrapper.get<IPAddressV4>(`/ipaddress/${encodeURIComponent(ipId)}`);
  return data;
};

export const getIPSuggestions = async (startingWith: string): Promise<IPAddressV4Response> => {
  const { data } = await fetchWrapper.get<IPAddressV4Response>('/ipaddress', {
    params: {
      search: startingWith,
    },
  });
  return data;
};

export default {
  getIP,
  getIPSuggestions,
};
