import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { cookieNames } from '@/constants';
import Cookies from 'js-cookie';
import { AlertCircle, AlertTriangle, Info } from 'lucide-react';
import React from 'react';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import { useLocation, useSearchParams } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';

const colorByImpact = {
  CRITICAL: 'text-red-500',
  HIGH: 'text-red-500',
  MEDIUM: 'text-yellow-500',
  LOW: 'text-green-500',
  INFORMATIONAL: 'text-green-500',
};

const HTTPAnalyzer = () => {
  const [error, setError] = React.useState('');
  const [url, setURL] = React.useState('');
  const [activeURLIdx, setActiveURLIdx] = React.useState(null);
  const [urls, setURLs] = React.useState([]);
  const [progress, setProgress] = React.useState(false);
  const [analysisResult, setAnalysisResult] = React.useState('');
  const [LLMExplanation, setLLMExplanation] = React.useState('');
  const [requestLogs, setRequestLogs] = React.useState('');
  const location = useLocation();
  const ApiURL = `${import.meta.env.VITE_REACT_APP_API_URL}/xhr-analyzer`;
  const CSRFToken = Cookies.get(cookieNames.csrfToken);
  const [searchParams] = useSearchParams();

  const urlParam = searchParams.get('url');

  const handleInputChange = (event) => {
    setURL(event.target.value);
  };

  let checkStatusInterval = null;

  React.useEffect(() => {
    console.log(progress);
  }, [progress]);

  React.useEffect(() => {
    if (urlParam) {
      setURL(urlParam);
      triggerAnalysis(urlParam);
    }
  }, [urlParam]);

  React.useEffect(() => {
    console.log('Selected URL item #', activeURLIdx);
    if (activeURLIdx != null) {
      setLLMExplanation(safeJsonParse(analysisResult[activeURLIdx].llm_analysis));
      const request = safeJsonParse(analysisResult[activeURLIdx].request);
      if (request?.postData) {
        request.postData = safeJsonParse(request.postData);
      }
      if (request?.response?.body) {
        request.response.body = safeJsonParse(request.response.body);
      }
      setRequestLogs(request);
    }
  }, [activeURLIdx]);

  function _loadResults(data) {
    console.log('Analysis completed');
    setProgress(false);
    clearInterval(checkStatusInterval);
    const urlsWithIssueCounters = data.map((element) => {
      const url = element?.llm_analysis?.ajax_url;
      const issuesCount = element?.llm_analysis?.issues_count ?? {};
      return { url, issuesCount };
    });

    setURLs(urlsWithIssueCounters);
    setAnalysisResult(data);
    setActiveURLIdx(0);
  }

  const checkJobStatus = async (jobId) => {
    return fetch(ApiURL + '?job_id=' + jobId, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'X-CSRFToken': CSRFToken,
      },
    })
      .then((response) => response.json())
      .then((job) => {
        if (job && job.status === 'COMPLETED') {
          console.log('Job is completed. Fetching results for:', job);
          fetchAnalysis(job.params.url);
        } else if (job.status === 'IN_PROGRESS') {
          console.log('Analysis in progress');
          return job;
        } else if (job.status === 'FAILED') {
          setProgress(false);
          clearInterval(checkStatusInterval);
          setError('Something went wrong: ' + JSON.stringify(job.result));
          return job;
        }
      })
      .catch((error) => {
        setProgress(false);
        setError(JSON.stringify(error));
        console.error('Failed to get analysis results:', error);
      });
  };

  const fetchAnalysis = async (url) => {
    return fetch(ApiURL + '?url=' + url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'X-CSRFToken': CSRFToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Status:', data);
        if (data) {
          _loadResults(data);
          return data;
        } else {
          return null;
        }
      })
      .catch((error) => {
        setProgress(false);
        setError(JSON.stringify(error));
        console.error('Failed to get analysis results:', error);
      });
  };

  const triggerAnalysis = async (url) => {
    if (!url) {
      return;
    }

    setError(null);
    setProgress(true);
    // Check if analysis for this url has already completed
    const analysis = await fetchAnalysis(url);
    if (analysis) {
      console.log(`Analysis for ${url} has already completed`);
      setProgress(false);
      return;
    }

    console.log('Triggering analysis for:', url);
    fetch(ApiURL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': CSRFToken,
      },
      body: JSON.stringify({
        url: url,
      }),
    })
      .then((response) => response.json())
      .then((job) => {
        console.log('Sheduled:', job);
        checkStatusInterval = setInterval(checkJobStatus, 15000, job.job_id);
      })
      .catch((error) => {
        setProgress(false);
        setError(error);
        console.error('Failed to trigger analysis:', error);
      });
  };

  function safeJsonParse(input) {
    try {
      // Attempt to parse the input as JSON
      return JSON.parse(input);
    } catch (error) {
      // If parsing fails, return the original string
      console.log('Error parsing JSON:', error);
      return input;
    }
  }

  function reset() {
    setLLMExplanation(null);
    setAnalysisResult(null);
    setActiveURLIdx(null);
    setRequestLogs(null);
    setURLs([]);
    setError(null);
  }

  return (
    <div className="min-h-screen bg-background p-6">
      <div className="container mx-auto">
        <div className="flex min-h-[70vh] flex-col items-center justify-center">
          {!LLMExplanation ? (
            <>
              <div className="w-full max-w-md space-y-4">
                <Alert variant="warning">
                  <AlertTriangle className="h-4 w-4" />
                  <AlertTitle>Beta Feature</AlertTitle>
                  <AlertDescription>
                    This feature is experimental and may be changed or removed at any time.
                  </AlertDescription>
                </Alert>

                <div className="text-center">
                  <p className="text-xl">Enter URL to analyze</p>
                </div>

                <div className="flex items-center space-x-2 justify-center">
                  <Input
                    value={url}
                    onChange={handleInputChange}
                    placeholder="Enter URL..."
                    className="w-full"
                  />
                </div>

                <div className="flex justify-center">
                  {progress ? (
                    <LoadingSpinner size="large" />
                  ) : (
                    <Button onClick={() => triggerAnalysis(url)}>Analyze</Button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <Button onClick={() => reset()} className="mt-8">
              New Analysis
            </Button>
          )}

          {error && (
            <div className="mt-7">
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            </div>
          )}

          {!error && LLMExplanation && (
            <div className="mt-4 w-full">
              <h3 className="mb-6 text-center text-xl font-semibold text-orange-400">
                Analysis for {url}
              </h3>

              <div className="flex gap-6">
                {/* URLs List */}
                <div className="w-1/4">
                  <p className="mb-4 text-center">XHR / Fetch requests</p>
                  <ScrollArea className="h-[50vh] rounded-md border p-4">
                    <div className="space-y-2">
                      {urls.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => setActiveURLIdx(index)}
                          className={`cursor-pointer rounded-md p-3 transition-colors
                            ${
                              urls[activeURLIdx]?.url === item.url
                                ? 'bg-primary text-primary-foreground'
                                : 'hover:bg-accent'
                            }`}
                        >
                          <div className="flex items-center justify-between gap-2">
                            <p className="break-all text-sm">{decodeURIComponent(item.url)}</p>
                            <div className="flex gap-2">
                              {item.issuesCount?.HIGH > 0 && (
                                <div className="flex items-center gap-1">
                                  <AlertCircle className="h-4 w-4 text-red-500" />
                                  <span className="text-red-500">{item.issuesCount.HIGH}</span>
                                </div>
                              )}
                              {item.issuesCount?.MEDIUM > 0 && (
                                <div className="flex items-center gap-1">
                                  <AlertTriangle className="h-4 w-4 text-yellow-500" />
                                  <span className="text-yellow-500">{item.issuesCount.MEDIUM}</span>
                                </div>
                              )}
                              {item.issuesCount?.LOW > 0 && (
                                <div className="flex items-center gap-1">
                                  <Info className="h-4 w-4 text-green-500" />
                                  <span className="text-green-500">{item.issuesCount.LOW}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </ScrollArea>
                </div>

                {/* Analysis Tabs */}
                <div className="w-3/4">
                  <Tabs defaultValue="analysis" className="w-full">
                    <TabsList className="grid w-full grid-cols-3">
                      <TabsTrigger value="analysis">Analysis</TabsTrigger>
                      <TabsTrigger value="request">Request</TabsTrigger>
                      <TabsTrigger value="response">Response</TabsTrigger>
                    </TabsList>

                    <TabsContent value="analysis">
                      <ScrollArea className="h-[60vh] w-full rounded-md p-4">
                        <div className="space-y-4">
                          {LLMExplanation?.issues && LLMExplanation.issues.length > 0 ? (
                            LLMExplanation.issues.map((issue, index) => (
                              <div key={index} className="rounded-lg border p-4 space-y-3">
                                <h3
                                  className={`text-lg font-semibold ${colorByImpact[issue.impact]}`}
                                >
                                  <span className="text-orange-400">Severity</span> {issue.impact}
                                </h3>
                                <p>{issue.description}</p>

                                <div>
                                  <h4 className="mb-2 font-semibold text-orange-400">Steps</h4>
                                  {issue.steps.split(/(?=\d+\.\s+)/).map((step, idx) => (
                                    <p key={idx}>{step.trim()}</p>
                                  ))}
                                </div>

                                <div>
                                  <h4 className="mb-2 font-semibold text-orange-400">Example</h4>
                                  <p>{issue.example}</p>
                                </div>

                                <div>
                                  <h4 className="mb-2 font-semibold text-orange-400">
                                    Recommendation
                                  </h4>
                                  <p>{issue.recommendation}</p>
                                </div>
                              </div>
                            ))
                          ) : (
                            <h4 className="mt-4 text-lg font-semibold text-green-500">
                              No issues have been discovered by AI.
                            </h4>
                          )}
                        </div>
                      </ScrollArea>
                    </TabsContent>

                    <TabsContent value="request">
                      <ScrollArea className="h-[60vh] w-full rounded-md p-4">
                        {requestLogs && (
                          <div className="space-y-4">
                            <h3 className="text-lg font-semibold">Request Details</h3>
                            <p className="text-orange-400">
                              <strong>{requestLogs.method}</strong>{' '}
                              {decodeURIComponent(requestLogs.url)}
                            </p>

                            <div>
                              <h4 className="mb-2 font-semibold text-orange-400">Headers</h4>
                              <div className="ml-4">
                                {requestLogs.headers &&
                                  Object.entries(requestLogs.headers).map(([key, value]) => (
                                    <p key={key}>
                                      <strong>{key}:</strong> {value}
                                    </p>
                                  ))}
                              </div>
                            </div>

                            {requestLogs.postData && (
                              <div>
                                <h4 className="mb-2 font-semibold text-orange-400">POST body</h4>
                                <div className="ml-4">
                                  {typeof requestLogs.postData === 'object' ? (
                                    <JSONPretty id="json-post-body" data={requestLogs.postData} />
                                  ) : (
                                    <pre className="rounded-md bg-muted p-2">
                                      {requestLogs.postData}
                                    </pre>
                                  )}
                                </div>
                              </div>
                            )}

                            <div>
                              <h4 className="mb-2 font-semibold text-orange-400">Cookies</h4>
                              <div className="ml-4">
                                {requestLogs.cookies &&
                                  Object.entries(requestLogs.cookies).map(([key, value]) => (
                                    <p key={key}>
                                      <strong>{key}:</strong> {value}
                                    </p>
                                  ))}
                              </div>
                            </div>

                            {LLMExplanation?.technologies &&
                              LLMExplanation.technologies.length > 0 && (
                                <div>
                                  <h4 className="mb-2 font-semibold text-orange-400">
                                    Possible technologies
                                  </h4>
                                  <p className="ml-4">{LLMExplanation.technologies.join(', ')}</p>
                                </div>
                              )}
                          </div>
                        )}
                      </ScrollArea>
                    </TabsContent>

                    <TabsContent value="response">
                      <ScrollArea className="h-[60vh] w-full rounded-md p-4">
                        <div className="space-y-4">
                          <p className="text-orange-400">
                            <strong>Response Status</strong> {requestLogs?.response?.status}
                          </p>

                          <div className="mt-2">
                            {typeof requestLogs?.response?.body === 'object' ? (
                              <JSONPretty
                                id="json-response-body"
                                data={requestLogs?.response?.body}
                              />
                            ) : (
                              <pre className="rounded-md bg-muted p-2 whitespace-pre-line">
                                {requestLogs?.response?.body}
                              </pre>
                            )}
                          </div>
                        </div>
                      </ScrollArea>
                    </TabsContent>
                  </Tabs>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HTTPAnalyzer;
