import { Info } from 'lucide-react';

import { Tooltip, TooltipTrigger } from '@/components/ui/tooltip';

import { TooltipProvider } from '@/components/ui/tooltip';

import { Card, CardContent } from '@/components/ui/card';

const colorMap = {
  critical: 'rgb(219, 78, 78)',
  high: 'rgb(215, 132, 102)',
  medium: 'rgb(230, 230, 127)',
  low: 'rgb(111, 205, 118)',
};

const CriticalityCard = (count: number, title: string, type: string) => {
  return (
    <Card
      className={`bg-[#FDFCFB] w-full  shadow-md rounded-lg`}
      style={{
        borderBottomColor: colorMap[type],
        borderBottomWidth: '6px',
        boxShadow: `0 4px 6px -1px ${colorMap[type]}30, 0 2px 4px -2px ${colorMap[type]}30`,
      }}
    >
      <CardContent className="p-4">
        <div className="flex items-start justify-between">
          <span className="text-xs text-muted-foreground font-poppins">{title}</span>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Info className="size-4 text-muted-foreground/60" />
              </TooltipTrigger>
            </Tooltip>
          </TooltipProvider>
        </div>
        <span className="mt-2 block text-2xl font-semibold font-poppins">{count}</span>
      </CardContent>
    </Card>
  );
};

export default CriticalityCard;
