import {
  fetchPortStats,
  getPortScanChangesApi,
  GetPortScanChangesParams,
  GetPortStatsParams,
  getVulnerabilitiesApi,
  getVulnerabilitiesCountApi,
  getVulnerabilitiesNucleiASNOverviewApi,
  GetVulnerabilitiesParams,
  getVulnerabilitiesProductsAndVendorsListApi,
  getVulnerabilitiesScanApi,
  getVulnerabilitiesScanResultsApi,
  GetVulnerabilitiesScanResultsParams,
  getVulnerabilityASNOverviewApi,
} from '@/api/investigations.api';
import { VulnerabilityASNOverview } from '@/types/api/response/investigations';
import { queryOptions, useQuery } from '@tanstack/react-query';

export const getVulnerabilitiesListQuery = (getVulnerabilitiesParams: GetVulnerabilitiesParams) =>
  queryOptions({
    queryKey: ['vulnerabilities', getVulnerabilitiesParams],
    queryFn: () => getVulnerabilitiesApi(getVulnerabilitiesParams),
  });

export const getVulnerabilitiesCountQuery = queryOptions({
  queryKey: ['vulnerabilities-count'],
  queryFn: () => getVulnerabilitiesCountApi(),
});

export const getVulnerabilityASNOverviewQuery = queryOptions({
  queryKey: ['vulnerabilities-asn-overview'],
  queryFn: () => getVulnerabilityASNOverviewApi(),
  select: (data: VulnerabilityASNOverview[]) =>
    data.map((item) => ({
      label: `${item.owner ? item.owner : 'Unknown'} (ASN: ${item.asn ? item.asn.toString() : ''}) - ${item.count} entries`,
      value: item.asn?.toString() ?? 'Unknown',
    })),
});

export const getVulnerabilitiesScanQuery = (
  cveFilterField: string,
  cveFilterOperator: string,
  page: number,
) =>
  queryOptions({
    queryKey: ['vulnerabilities-scan', cveFilterField, cveFilterOperator, page],
    queryFn: () => getVulnerabilitiesScanApi({ cveFilterField, cveFilterOperator, page }),
  });

export const getVulnerabilitiesScanResultsQuery = ({
  page,
  asn,
  http_asset,
  domainname,
  filter_type,
  severity,
}: GetVulnerabilitiesScanResultsParams) =>
  queryOptions({
    queryKey: [
      'vulnerabilities-scan-results',
      page,
      asn,
      http_asset,
      domainname,
      filter_type,
      severity,
    ],
    queryFn: () =>
      getVulnerabilitiesScanResultsApi({
        page,
        asn,
        http_asset,
        domainname,
        filter_type,
        severity,
      }),
  });

export const getVulnerabilitiesNucleiASNOverviewQuery = () =>
  queryOptions({
    queryKey: ['vulnerabilities-nuclei-asn-overview'],
    queryFn: () => getVulnerabilitiesNucleiASNOverviewApi(),
  });

export const getPortScanChangesQuery = ({
  page,
  state,
  from_date,
  to_date,
  ipaddress,
  ports,
  services,
  versions,
}: GetPortScanChangesParams) =>
  queryOptions({
    queryKey: [
      'port-scan-changes',
      page,
      state,
      from_date,
      to_date,
      ipaddress,
      ports,
      services,
      versions,
    ],
    queryFn: () =>
      getPortScanChangesApi({
        page,
        state,
        from_date,
        to_date,
        ipaddress,
        ports,
        services,
        versions,
      }),
  });

export const getPortStatsQuery = ({ from_date, to_date, page }: GetPortStatsParams) =>
  queryOptions({
    queryKey: ['port-stats', from_date, to_date, page],
    queryFn: () => fetchPortStats({ from_date, to_date, page }),
  });

export const useGetVulnerabilitiesProductsAndVendorsList = () =>
  useQuery({
    queryKey: ['vulnerabilities-products-and-vendors-list'],
    queryFn: () => getVulnerabilitiesProductsAndVendorsListApi(),
    select: (data) => {
      return {
        products: data.products.map((product) => ({ label: product, value: product })),
        vendors: data.vendors.map((vendor) => ({ label: vendor, value: vendor })),
      };
    },
  });
