import { useLocalStorage } from '@/hooks/useLocalStorage';
import { cn } from '@/lib/utils';
import NavAssets from '@/v2/icons/nav-assets.svg?react';
import NavChevronLeft from '@/v2/icons/nav-chevron-left.svg?react';
import NavChevronRight from '@/v2/icons/nav-chevron-right.svg?react';
import NavIssues from '@/v2/icons/nav-issues.svg?react';
import NavNotes from '@/v2/icons/nav-notes.svg?react';
import NavSpecularLogo from '@/v2/icons/nav-specular-logo.svg?react';
import NavWorkspace from '@/v2/icons/nav-workspace.svg?react';
import { usePrefetchAssetsList } from '@/v2/queries/assets';
import { NavLink } from 'react-router-dom';

export const Navbar = () => {
  const [isExpanded, setIsExpanded] = useLocalStorage('isNavbarExpanded', true);
  const prefetchAssetsList = usePrefetchAssetsList();

  const navbarItems = [
    {
      id: 'issues',
      icon: NavWorkspace,
      label: 'Issues',
      to: '/issues',
    },
    {
      id: 'investigation',
      icon: NavIssues,
      label: 'Investigation',
      to: '/investigation',
    },
    {
      id: 'assets',
      icon: NavAssets,
      label: 'Assets',
      to: '/assets',
      prefetch: () => prefetchAssetsList({}),
    },
    {
      id: 'notes',
      icon: NavNotes,
      label: 'Notes',
      to: '/notes',
    },
  ];

  return (
    <nav
      className={cn(
        'flex flex-col gap-1 overflow-hidden p-2 bg-v2-black text-v2-white transition-all ease-in duration-100',
        isExpanded ? 'w-40' : 'w-16',
      )}
    >
      <NavSpecularLogo className="w-12 h-12 mb-8 p-2 flex-none" />

      {navbarItems.map((item) => (
        <NavLink
          to={item.to}
          title={item.label}
          key={item.id}
          onMouseEnter={() => item.prefetch?.()}
        >
          {({ isActive }) => (
            <span
              className={cn(
                'flex items-center gap-2 p-3 rounded-lg hover:bg-v2-orange/10',
                isActive && 'text-v2-orange bg-v2-orange/20',
              )}
            >
              <item.icon className="w-6 h-6 flex-none" />

              <span
                className={cn(
                  'text-sm transition-opacity duration-100',
                  !isExpanded && 'opacity-0 hidden',
                  isExpanded && 'opacity-100',
                )}
              >
                {item.label}
              </span>
            </span>
          )}
        </NavLink>
      ))}

      <span className="flex-1" />

      <button
        className="flex items-center justify-center w-12 h-12 p-2 rounded-lg text-v2-white/80 hover:bg-v2-orange/10"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? <NavChevronLeft /> : <NavChevronRight />}
      </button>
    </nav>
  );
};
