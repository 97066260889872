import fetchWrapper from '@/lib/fetchWrapper';
import { Note } from '@/types/api/response/notes';

export type getUserNotesParams = {
  verbose?: boolean;
  httpAsset?: number;
  search?: string;
  filter?: string[];
};

export type createNewNoteParams = {
  http_asset_id: string;
  noteText: string;
};

export const getUserNotes = async (params?: getUserNotesParams): Promise<Note[]> => {
  const response = await fetchWrapper.get<Note[]>('/usernotes', {
    params: {
      verbose: String(!!params?.verbose),
      ...(params?.httpAsset && { http_asset: params.httpAsset }),
      ...(params?.search && { search: params.search }),
      ...(params?.filter && { filter: params.filter.join(',') }),
    },
  });
  return response.data;
};

export const createNewNoteAPI = async (params: createNewNoteParams) => {
  const response = await fetchWrapper.post('/usernotes', {
    http_asset: params.http_asset_id,
    text: params.noteText,
  });
  return response.data;
};

export const deleteNoteAPI = async (noteId: string) => {
  const response = await fetchWrapper.delete(`/usernotes/${noteId}`);
  return response;
};

export const updateNoteAPI = async (noteId: string, params: { text: string }) => {
  const response = await fetchWrapper.patch(`/usernotes/${noteId}`, { text: params.text });
  return response;
};

export type createAlertFromNoteParams = {
  noteId: string;
  severity: string;
  title: string;
};

export const createAlertFromNoteAPI = async (params: createAlertFromNoteParams) => {
  const response = await fetchWrapper.post('/alerts', {
    description: params.noteId,
    severity: params.severity,
    title: params.title,
  });
  return response.data;
};
