import { GetVulnerabilitiesScanResultsParams } from '@/api/investigations.api';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { VulnerabilitiesScanResults } from '@/types/api/response/investigations';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import UniversalFilter, { AvailableFilter } from '@/v2/components/UniversalFilter/UniversalFilter';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { parseAsArrayOf, parseAsInteger, parseAsString, useQueryState } from 'nuqs';
import {
  getVulnerabilitiesNucleiASNOverviewQuery,
  getVulnerabilitiesScanResultsQuery,
} from '../../queries/investigation';
export default function Dynamic() {
  const [selectedASNs, setSelectedASNs] = useQueryState(
    'selectedASNs',
    parseAsArrayOf(parseAsString).withDefault([]),
  );
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [severityFilter, setSeverityFilter] = useQueryState('severityFilter');
  const [domainFilter, setDomainFilter] = useQueryState('domainFilter', {
    defaultValue: '',
  });
  const [domainFilterType, setDomainFilterType] = useQueryState('domainFilterType', {
    defaultValue: 'startswith',
  });

  const getVulnerabilitiesScanResultsQueryParams: GetVulnerabilitiesScanResultsParams = {
    page,
    asn: selectedASNs.join(','),
    domainname: domainFilter,
    filter_type: domainFilterType,
    severity: severityFilter,
  };

  const { data: ScanResultsData, isLoading } = useQuery(
    getVulnerabilitiesScanResultsQuery(getVulnerabilitiesScanResultsQueryParams),
  );

  const { data: ASNOverviewData = [] } = useQuery(getVulnerabilitiesNucleiASNOverviewQuery());

  const formatTimestamp = (timestamp: string) => {
    const date = DateTime.fromISO(timestamp);
    return date.toLocaleString({
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const descriptionCell = (description: string) => {
    return description && description.trim() !== '' ? description : 'N/A';
  };

  const severityCell = (severity: string) => {
    if (severity === 'critical')
      return (
        <div className="bg-red-500 text-white px-2 py-1 rounded-md text-xs text-center">
          Critical
        </div>
      );
    if (severity === 'high')
      return (
        <div className="bg-red-500 text-white px-2 py-1 rounded-md text-xs text-center">High</div>
      );
    if (severity === 'medium')
      return (
        <div className="bg-orange-500 text-white px-2 py-1 rounded-md text-xs text-center">
          Medium
        </div>
      );
    if (severity === 'low')
      return (
        <div className="bg-yellow-500 text-white px-2 py-1 rounded-md text-xs text-center">Low</div>
      );
    return (
      <div className="bg-green-500 text-white px-2 py-1 rounded-md text-xs text-center">Info</div>
    );
  };

  const handleSelectDomain = (domain: string) => {
    setDomainFilter(domain);
    setPage(1);
  };

  const handleSelectDomainFilterType = (type: string) => {
    setDomainFilterType(type);
    setPage(1);
  };

  const handleSelectASN = (asn: string[]) => {
    setSelectedASNs(asn);
    setPage(1);
  };

  const handleSelectSeverity = (severity: string) => {
    setSeverityFilter(severity);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const clearAllFilters = () => {
    setSeverityFilter(null);
    setDomainFilter('');
    setDomainFilterType('startswith');
    setSelectedASNs([]);
    setPage(1);
  };

  const columns: ColumnDef<VulnerabilitiesScanResults>[] = [
    {
      header: 'Domain',
      accessorFn: (row) => row.http_asset?.domain?.domainname,
      cell: ({ row }) => (
        <div
          className="cursor-pointer hover:underline"
          onClick={() => handleSelectDomain(row.original.http_asset.domain.domainname)}
          title="Select Domain"
          data-click-bypass="true"
        >
          {row.original.http_asset?.domain?.domainname}
        </div>
      ),
    },
    { header: 'Port', accessorFn: (row) => row.http_asset?.port },
    {
      accessorKey: 'nuclei_template.name',
      header: () => <div className="text-center">Name</div>,
      accessorFn: (row) => row.nuclei_template?.name,
    },
    {
      accessorKey: 'nuclei_template.description',
      header: () => <div className="text-center">Description</div>,
      accessorFn: (row) => descriptionCell(row.nuclei_template?.description),
    },
    {
      accessorKey: 'nuclei_template.severity',
      header: () => <div className="text-center">Severity</div>,
      cell: ({ row }) => severityCell(row.original.nuclei_template?.severity),
    },
    {
      accessorKey: 'date_added',
      header: () => <div className="text-center">First Identified</div>,
      cell: ({ row }) => (
        <div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span className="text-xs">{formatTimestamp(row.original.date_added)}</span>
              </TooltipTrigger>
              <TooltipContent>
                <p>When the vulnerability was first identified by Specular.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ),
    },
    {
      accessorKey: 'last_seen',
      header: () => <div className="text-center">Service Last Seen</div>,
      cell: ({ row }) => (
        <div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span className="text-xs">{formatTimestamp(row.original.last_seen)}</span>
              </TooltipTrigger>
              <TooltipContent>
                <p>When the service was last observed by Specular.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ),
    },
  ];

  const availableFilters: AvailableFilter[] = [
    {
      label: 'Severity',
      key: 'severity',
      type: 'select',
      setState: handleSelectSeverity,
      state: severityFilter,
      placeholder: 'Search by severity',
      options: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Critical',
          value: 'critical',
        },
        {
          label: 'High',
          value: 'high',
        },
        {
          label: 'Medium',
          value: 'medium',
        },
        {
          label: 'Low',
          value: 'low',
        },
        {
          label: 'Info',
          value: 'info',
        },
      ],
      onClear: () => {
        handleSelectSeverity(null);
      },
    },
    {
      label: 'Domain',
      key: 'domain',
      type: 'domainSearch',
      setState: handleSelectDomain,
      state: domainFilter,
      setValueType: handleSelectDomainFilterType,
      valueType: domainFilterType,
      placeholder: 'Search by domain',
      options: [
        {
          label: 'starts with',
          value: 'startswith',
        },
        {
          label: 'contains',
          value: 'contains',
        },
      ],
      defaultValue: 'startswith',
    },
    {
      label: 'ASN',
      key: 'asn',
      type: 'multiSelect',
      setState: handleSelectASN,
      state: selectedASNs,
      placeholder: 'Filter by ASN',
      options: ASNOverviewData.map((asn) => ({
        label: `${asn.owner ? asn.owner : 'Unknown'} (ASN: ${asn.asn ? asn.asn.toString() : 'Unknown'}) - ${asn.count} entries`,
        value: asn.asn ? asn.asn.toString() : null,
      })),
      onClear: () => {},
    },
  ];

  return (
    <div className="p-6 max-w-[2000px] mx-auto">
      <UniversalFilter
        filters={availableFilters}
        clearAllFilters={clearAllFilters}
        className="mb-4"
      />
      <DataTable
        columns={columns}
        data={ScanResultsData?.entries}
        loading={isLoading}
        currentPage={page}
        totalPages={ScanResultsData?.total_pages}
        onPageChange={handlePageChange}
        totalEntries={ScanResultsData?.total_count}
        error={null}
        maxWidth="w-full"
      />
    </div>
  );
}
