import { UsersResponse } from '../types/api/response/user';
import { fetchWrapper } from '../lib/fetchWrapper';

export const getUsers = async (page: number = 1): Promise<UsersResponse> => {
  const { data } = await fetchWrapper.get<UsersResponse>('/users', {
    params: {
      page,
    },
  });
  return data;
};
