import { useAuth } from '@/Helpers/AuthContext';
import { cn } from '@/lib/utils';
import { NavLink, useNavigate } from 'react-router-dom';
import AuthDropdown from '../AuthDropdown/AuthDropdown';
interface LayoutHeaderProps {
  links: {
    label: string;
    to: string;
    prefetch?: () => void;
  }[];
}

const TabbedNavLink = ({
  lone,
  label,
  to,
  prefetch,
}: {
  lone: boolean;
  label: string;
  to: string;
  prefetch?: () => void;
}) => (
  <NavLink end to={to} title={label} onMouseEnter={prefetch}>
    {({ isActive }) => (
      <span
        className={cn(
          'flex items-center gap-2 px-4 py-2 rounded-lg text-gray-500 text-xl font-semibold transition-all duration-200',
          !lone && 'hover:text-gray-700 hover:bg-gray-100',
          (lone || isActive) && 'text-black bg-gray-100 shadow-sm',
        )}
      >
        <span>{label}</span>
      </span>
    )}
  </NavLink>
);

const LayoutHeader = ({ links }: LayoutHeaderProps) => {
  const { userData, userOrganization, setAuthState, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogoff = async () => {
    setAuthState();
    await logout();
    navigate('/login');
  };

  return (
    <header className="header sticky top-0 z-10 flex flex-row py-4 px-6  border-b bg-v2-white border-b-v2-warm-gray">
      <div className="flex flex-1 items-center gap-4">
        {links.map((link) => (
          <TabbedNavLink lone={links.length === 1} key={link.to} label={link.label} to={link.to} />
        ))}
      </div>

      <AuthDropdown
        userData={userData}
        userOrganization={userOrganization}
        handleLogoff={handleLogoff}
      />
    </header>
  );
};

export default LayoutHeader;
