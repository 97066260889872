import { registerApi } from '@/api/auth.api';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import AuthBackground from '@/v2/components/AuthBackground/AuthBackground';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

const formSchema = z
  .object({
    firstName: z.string().min(1, { message: 'First name is required' }),
    lastName: z.string().min(1, { message: 'Last name is required' }),
    email: z.string().email({ message: 'Invalid email address' }),
    username: z.string().email({ message: 'Invalid email address' }),
    password: z.string().min(8, { message: 'Password must be at least 8 characters long' }),
    repeatPassword: z.string().min(8, { message: 'Password must be at least 8 characters long' }),
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: "Passwords don't match",
    path: ['repeatPassword'],
  });

const Register = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      username: '',
      repeatPassword: '',
    },
  });

  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [token, setToken] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      setToken(token);
    }
  }, [location]);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    try {
      setError('');
      setMessage('');
      const formData = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        username: data.username,
        password: data.password,
        registration_key: token,
      };

      if (data.password !== data.repeatPassword) {
        setError('Passwords do not match');
        return;
      }

      const response = await registerApi(formData);

      if (response.ok) {
        console.log('Registration successful');
        setMessage('Registration successful, redirecting to login page...');
        setTimeout(() => {
          navigate('/login');
        }, 3000);
        // Handle success, maybe redirect to a login page or show success message
      } else {
        console.error('Registration failed');
        const tempError = await response.json();
        let errorMessage = '';
        for (const field in tempError.error) {
          if (field !== '0') {
            errorMessage += field.toUpperCase();
          }
          if (tempError.error[field].isArray) {
            errorMessage += tempError.error[field][0];
          } else {
            errorMessage += ' ' + tempError.error[field];
          }
        }
        setError(errorMessage);
      }
    } catch (error) {
      console.error('There was an error sending the data', error);
      setError(error);
    }
  };

  return (
    <div className="relative flex justify-center items-center max-h-screen bg-v2-black overflow-hidden gap-16">
      <div className="relative flex justify-between items-center min-h-screen bg-v2-black overflow-hidden min-w-[500px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full max-w-[400px]">
            <h1 className="text-v2-white text-2xl mb-3 uppercase tracking-wider text-center font-lato font-bold">
              Registration
            </h1>
            {error && (
              <div className="mb-4 p-3 rounded-md bg-red-500/10 border border-red-500 text-red-500">
                {error}
              </div>
            )}
            {message && (
              <div className="mb-4 p-3 rounded-md bg-green-500/10 border border-green-500 text-green-500">
                {message}
              </div>
            )}
            <div className="mb-3">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="text"
                        required
                        placeholder="First Name"
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>This is your public name</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>
            <div className="mb-3">
              <FormField
                control={form.control}
                name="lastName"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="text"
                        required
                        placeholder="Last Name"
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>This is your last name</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>
            <div className="mb-3">
              <FormField
                control={form.control}
                name="email"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="text"
                        required
                        placeholder="Email"
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>This is your email</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>
            <div className="mb-3">
              <FormField
                control={form.control}
                name="username"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="text"
                        required
                        placeholder="Username"
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>This is your username</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>
            <div className="mb-3">
              <FormField
                control={form.control}
                name="password"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="password"
                        required
                        placeholder="Password"
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>This is your password</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>
            <div className="mb-3">
              <FormField
                control={form.control}
                name="repeatPassword"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="password"
                        required
                        placeholder="Repeat Password"
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>Repeat your password</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>
            <Button
              type="submit"
              className="w-full py-3 px-3 bg-v2-orange text-v2-white text-base rounded-md
                   cursor-pointer transition-colors hover:bg-v2-orange/90 font-poppins font-semibold"
            >
              Registration
            </Button>

            <p className="text-v2-white/50 text-center mt-6 text-sm">
              Do you already have an account?
              <Link to="/login" className="text-v2-orange no-underline ml-2 hover:underline">
                Login
              </Link>
            </p>
          </form>
        </Form>
      </div>
      <AuthBackground />
    </div>
  );
};

export default Register;
