import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@chakra-ui/react';

export default function GenericSelectWithOperator({
  options,
  valueType,
  setValueType,
  onClear,
  operator,
  setOperator,
  operatorOptions,
  label,
  placeholder,
}: {
  options: { label: string; value: string }[];
  valueType: string;
  setValueType: (value: string) => void;
  onClear: () => void;
  operator: string;
  setOperator: (value: string) => void;
  operatorOptions: { label: string; value: string }[];
  label: string;
  placeholder: string;
}) {
  return (
    <div className="flex items-center gap-2">
      <Button variant="outline" onClick={onClear}>
        {label}
      </Button>
      <Select value={operator} onValueChange={setOperator}>
        <SelectTrigger>
          <SelectValue placeholder="Select an option" />
        </SelectTrigger>
        <SelectContent>
          {operatorOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Select value={valueType} onValueChange={setValueType}>
        <SelectTrigger>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
