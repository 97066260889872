import { Button } from '@/components/ui/button';
import { MultiSelect } from '@/components/ui/multi-select';
import useParamState from '@/hooks/useParamState';
import { AssetURL } from '@/types/api/response/assets';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import { getAssetURLsOverviewQuery, useGetAssetURLsQuery } from '@/v2/queries/assets';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

interface URLDisplayProps {
  httpAssetId: number;
}

const URLDisplay = ({ httpAssetId }: URLDisplayProps) => {
  const [currentPage, setCurrentPage] = useParamState<number>('page', 1);

  const [selectedTechnology, setSelectedTechnology] = useParamState('technology', []);
  const [selectedExtension, setSelectedExtension] = useParamState('extension', []);
  const [selectedStatusCode, setSelectedStatusCode] = useParamState('statusCode', []);

  const { data: urlStats } = useQuery(getAssetURLsOverviewQuery(httpAssetId));

  const {
    data: urls,
    isLoading: urlsLoading,
    error: urlsError,
  } = useGetAssetURLsQuery({
    assetId: httpAssetId,
    page: currentPage,
    technology: selectedTechnology.join(','),
    extension: selectedExtension.join(','),
    statusCode: selectedStatusCode.join(','),
  });

  const columnHelper = createColumnHelper<AssetURL>();

  const columns: ColumnDef<AssetURL>[] = [
    columnHelper.accessor('url', {
      header: 'URL',
      cell: ({ row }) => (
        <Link target="_blank" to={row.original.url}>
          {row.original.url}
        </Link>
      ),
    }),
    columnHelper.accessor('status_code', {
      header: 'Status Code',
      cell: ({ row }) => row.original.status_code,
    }),
    columnHelper.accessor('technologies', {
      header: 'Technologies',
      cell: ({ row }) =>
        row.original.technologies.join(', ').length > 0
          ? row.original.technologies.join(', ')
          : '-',
    }),
    columnHelper.accessor('redirect_url', {
      header: 'Redirect Domain',
      cell: ({ row }) => {
        const redirectUrl = row.original.redirect_url;
        if (!redirectUrl) return 'N/A';
        try {
          return new URL(redirectUrl).hostname;
        } catch (e: unknown) {
          return '-';
        }
      },
    }),
    columnHelper.accessor('sources', {
      header: 'Sources',
      cell: ({ row }) => Object.keys(row.original.sources).join(', '),
    }),
    columnHelper.accessor('last_scanned', {
      header: 'Last Seen',
      cell: ({ row }) => new Date(row.original.last_scanned).toLocaleDateString(),
    }),
    columnHelper.display({
      id: 'actions',
      cell: ({ row }) => (
        <Button
          className="text-xs bg-v2-orange text-white"
          onClick={() => window.open(`/http_analyzer?url=${row.original.url}`, '_blank')}
        >
          Analyze
        </Button>
      ),
    }),
  ];

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const onExtensionChange = (value: string[]) => {
    setSelectedExtension(value);
    if (currentPage !== 1) setCurrentPage(1);
  };

  const onStatusCodeChange = (value: string[]) => {
    setSelectedStatusCode(value);
    if (currentPage !== 1) setCurrentPage(1);
  };

  const onTechnologyChange = (value: string[]) => {
    setSelectedTechnology(value);
    if (currentPage !== 1) setCurrentPage(1);
  };

  const mappedTechnologies =
    urlStats?.technology_counts?.map((technology) => ({
      value: technology.technology,
      label: `${technology.technology} (${technology.count})`,
    })) || [];

  const mappedExtensions =
    urlStats?.extension_counts?.map((extension) => ({
      value: extension.extension,
      label: `${extension.extension ? extension.extension : 'Unknown'} (${extension.count})`,
    })) || [];

  const mappedStatusCodes =
    urlStats?.status_code_counts?.map((statusCode) => ({
      value: statusCode.status_code,
      label: `${statusCode.status_code} (${statusCode.count})`,
    })) || [];

  return (
    <div>
      {urlsLoading ? (
        <h1>Loading...</h1>
      ) : urlsError ? (
        <h1 className="text-red-500">{urlsError.message}</h1>
      ) : (
        <>
          {urlStats && (
            <div className="flex gap-2 mb-6">
              <MultiSelect
                options={mappedTechnologies}
                value={selectedTechnology}
                placeholder="Select Technology"
                onValueChange={onTechnologyChange}
                className="w-fit"
              />
              <MultiSelect
                options={mappedExtensions}
                value={selectedExtension}
                placeholder="Select File Extension"
                onValueChange={onExtensionChange}
                className="w-fit"
              />
              <MultiSelect
                options={mappedStatusCodes}
                value={selectedStatusCode}
                placeholder="Select Status Code"
                onValueChange={onStatusCodeChange}
                className="w-fit"
              />
            </div>
          )}
          {urls?.results.length > 0 ? (
            <DataTable
              columns={columns}
              data={urls?.results ?? []}
              currentPage={currentPage}
              totalPages={urls?.total_pages ?? 0}
              totalEntries={urls?.total_items ?? 0}
              onPageChange={handlePageChange}
              error={urlsError}
              loading={urlsLoading}
              maxWidth="max-w-[1800px]"
              tableHeight="calc(100vh - 320px)"
            />
          ) : (
            <h1>No Known URLs</h1>
          )}
        </>
      )}
    </div>
  );
};

export default URLDisplay;
