import React, {useEffect, useState} from 'react';

const imageSizes = {
  small: 200,
  medium: 400,
  large: 600,
  default: 400
};

// Image Renderer Component that will include credentials in the fetch request
const ImageRenderer = ({ src, alt, size = 'medium' }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(src, { credentials: 'include' });
        if (!response.ok) throw new Error('Failed to fetch image');
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        setImageSrc(objectUrl);
      } catch (error) {
        console.error('Error loading image:', error);
        setImageSrc(null);
      }
    };

    fetchImage();

    return () => {
      if (imageSrc) URL.revokeObjectURL(imageSrc);
    };
  }, [src]);

  if (!imageSrc) return <span>Loading image...</span>;
  
  const width = imageSizes[size] || imageSizes.default;

  return <img 
    src={imageSrc} 
    alt={alt} 
    width={width}
    style={{ maxWidth: '100%', height: 'auto' }}
  />;
};

export default ImageRenderer;