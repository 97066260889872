import { forgotPasswordApi } from '@/api/auth.api';
import { Form, FormControl, FormDescription, FormField, FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import AuthBackground from '@/v2/components/AuthBackground/AuthBackground';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const ForgotPasswordSchema = z.object({
  email: z.string().email({ message: 'Please enter a valid email address' }),
});

const ForgotPassword = () => {
  const form = useForm<z.infer<typeof ForgotPasswordSchema>>({
    resolver: zodResolver(ForgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });

  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const onSubmit = async (data: z.infer<typeof ForgotPasswordSchema>) => {
    const { email } = data;

    try {
      setError('');
      setMessage('');

      const response = await forgotPasswordApi(email);

      if (response.ok) {
        setMessage('If this email is associated with an account, a reset link will be sent.');
      } else {
        const errorData = await response.json();
        let errorMessage = '';
        for (const field in errorData.error) {
          if (field !== '0') {
            errorMessage += field.toUpperCase();
          }
          if (errorData.error[field].isArray) {
            errorMessage += errorData.error[field][0];
          } else {
            errorMessage += ' ' + errorData.error[field];
          }
        }
        setError(errorMessage || 'There was an error sending the reset link.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('There was an error sending the reset link.');
    }
  };

  return (
    <div className="relative flex justify-center items-center max-h-screen bg-v2-black overflow-hidden gap-16">
      <div className="relative flex justify-between items-center min-h-screen bg-v2-black overflow-hidden min-w-[500px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full max-w-[400px]">
            <h1 className="text-v2-white text-2xl mb-6 uppercase tracking-wider text-center font-lato font-bold">
              Password Recovery
            </h1>

            {error && (
              <div className="mb-4 p-3 rounded-md bg-red-500/10 border border-red-500 text-red-500">
                {error}
              </div>
            )}

            {message && (
              <div className="mb-4 p-3 rounded-md bg-green-500/10 border border-green-500 text-green-500">
                {message}
              </div>
            )}

            <div className="mb-6">
              <FormField
                control={form.control}
                name="email"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="email"
                        required
                        placeholder="Email"
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>Enter your account email address</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>

            <button
              type="submit"
              className="w-full py-3 px-3 bg-v2-orange text-v2-white text-base rounded-md
                   cursor-pointer transition-colors hover:bg-v2-orange/90 font-poppins font-semibold"
            >
              Reset password
            </button>

            <p className="text-v2-gray text-center mt-6 text-sm">
              I don&apos;t know the username and email.{' '}
              <a
                href="mailto:support@specular.ai"
                className="text-v2-orange no-underline hover:underline"
              >
                Email us for recovery
              </a>
            </p>
          </form>
        </Form>
      </div>
      <AuthBackground />
    </div>
  );
};

export default ForgotPassword;
