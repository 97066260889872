import { useState } from 'react';
import { Badge } from '@/components/ui/badge';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Issue, ISSUE_STATUSES } from '@/types/api/response/issues';
import { Pencil } from 'lucide-react';

interface StatusSelectorProps {
  currentStatus: Issue['status'];
  onStatusChange: (status: Issue['status']) => void;
  triggerClassName?: string;
}

export const StatusSelector = ({
  currentStatus,
  onStatusChange,
  triggerClassName = 'text-left'
}: StatusSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  return (
    <Popover open={isOpen} onOpenChange={handleOpenChange}>
      <PopoverTrigger className={triggerClassName} onClick={() => setIsOpen(!isOpen)}>
        <div className="group flex items-center gap-2">
          <Badge className={ISSUE_STATUSES[currentStatus as keyof typeof ISSUE_STATUSES]}>
            {currentStatus.replace('_', ' ')}
          </Badge>
          <Pencil className="h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity" />
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-40 p-0 bg-gray-50 border border-gray-300">
        <div className="flex flex-col">
          {Object.keys(ISSUE_STATUSES).map((status) => (
            <button
              key={status}
              className={`p-2 text-left hover:bg-gray-200 ${
                status === currentStatus ? 'bg-gray-100' : ''
              }`}
              onClick={() => {
                onStatusChange(status as Issue['status']);
                setIsOpen(false);
              }}
            >
              <Badge className={ISSUE_STATUSES[status as keyof typeof ISSUE_STATUSES]}>
                {status.replace('_', ' ')}
              </Badge>
            </button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
}; 