import React, { useState, useEffect } from 'react';
import {
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  List,
  ListItem,
  PopoverArrow,
  PopoverBody,
  Button,
  InputGroup,
  InputRightElement,
  Select,
  Flex,
  Box,
  HStack,
  InputLeftElement
} from '@chakra-ui/react';
import {getAssetSuggestions} from "./NotesAPI";
import {getDomainSuggestions} from "../GridComponents/GridAPI";
import {Icon, CloseIcon, SearchIcon } from '@chakra-ui/icons'; // Import the CloseIcon for the clear button


const AutoCompleteSearchBar = ({ placeholder, onSelect, onSearch, type="assets", color = "white", width="320px", allowSearch=false }) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [filterType, setFilterType] = useState('startswith'); // State to manage filter type

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (inputValue.length > 1) { // Consider fetching suggestions for inputs longer than 1 character
        let data;
        if(type === "assets") {
          data = await getAssetSuggestions(inputValue, filterType);
        }
        else if(type === "domains"){
          data = await getDomainSuggestions(inputValue, filterType);
        }
        else{
          console.error('Unable fetch suggestion type')
          data = [];
        }
        setSuggestions(data); // Assuming the API returns an array of suggestions
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };
    fetchSuggestions();
  }, [inputValue, filterType]);

  const handleSearch = () => {
    // Perform search operation using inputValue
    // This example directly uses onSelect, adjust according to your needs
    onSelect(inputValue);
    setIsOpen(false); // Optionally close the suggestions popover
  };

  const handleClearSelection = () => {
    setInputValue(''); // Clear the input value
    setIsOpen(false); // Close the suggestions popover
    onSelect(null); // Notify the parent component that the selection has been cleared
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <Popover autoFocus={false} isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <PopoverTrigger>
        <InputGroup size="sm" width={width}>
          <InputLeftElement width="7.0rem" height="100%" paddingEnd={2}>
            <Select mt={0} size="xs" border="None" borderRight="2px" color={color} focusBorderColor={color}
                    onChange={(e)=> setFilterType(e.target.value)} value={filterType}>
              <option value="startswith">Starts With</option>
              <option value="contains">Contains</option>
            </Select>
          </InputLeftElement>
          <Input
              pl="7.0rem"
              placeholder={placeholder}
              _placeholder={{color: color}}
              value={inputValue}
              onChange={handleChange}
              onFocus={() => inputValue.length > 1 && setIsOpen(true)}
              color={color}
              focusBorderColor={color}
              size="xs"
          />
          {inputValue && ( // Show the clear button only if there is an input value
              <InputRightElement height="100%">
                <Button size="xs" onClick={handleClearSelection} variant="ghost" minWidth="fit-content"
                        isRound={true} _hover={{bg: 'gray.500'}} paddingEnd={2}>
                  <Icon as={CloseIcon} boxSize="6px" color={color}/>
                </Button>
                {allowSearch && ( // Show the search button only if allowSearch is true}
                    <Button size="xs" onClick={handleSearch} variant="ghost" minWidth="fit-content"
                            isRound={true} _hover={{bg: 'gray.500'}} marginRight="4">
                      <Icon as={SearchIcon} boxSize="6px" color={color}/>
                    </Button>
                )
                }
              </InputRightElement>
          )}
        </InputGroup>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow/>
        <PopoverBody>
          <List>
            {(suggestions.length === 0 && !inputValue)? (
                <ListItem color="Black">No results found</ListItem>
            ) : (
                suggestions.map((suggestion, index) => {
                  if (type === "domains") {
                    // For domain type, display only the domain name
                    return (
                        <ListItem
                            key={index}
                            color="white"
                            cursor="pointer"
                            _hover={{bg: 'gray.100'}}
                            onClick={() => {
                              setInputValue(suggestion.domainname); // Assume suggestion has a domainname property
                              onSelect(suggestion.domainname); // Assuming you have an id to select
                              setIsOpen(false);
                            }}
                        >
                          {suggestion.domainname}
                        </ListItem>
                    );
                  } else {
                    // For assets, display domain name and port
                    return (
                        <ListItem
                            key={index}
                            color="white"
                            cursor="pointer"
                            _hover={{bg: 'gray.100'}}
                            onClick={() => {
                              setInputValue(suggestion.domain.domainname + `:` + suggestion.port);
                              onSelect(suggestion.id);
                              setIsOpen(false);
                            }}
                        >
                          {suggestion.domain.domainname + `:` + suggestion.port}
                        </ListItem>
                    );
                  }
                })
            )}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default AutoCompleteSearchBar