import { useState, useCallback } from 'react';
import { debounce } from 'lodash';

export const USE_LOCAL_STORAGE_DEFAULT_DEBOUNCE_TIME = 0;
export const USE_LOCAL_STORAGE_KEY_PREFIX = 'SPE-ULS_';

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  debounceTime: number = USE_LOCAL_STORAGE_DEFAULT_DEBOUNCE_TIME,
): [T, (value: T) => void] {
  const prefixedKey = `${USE_LOCAL_STORAGE_KEY_PREFIX}${key}`;

  const [storedValue, setStoredValue] = useState<T>((): T => {
    try {
      return JSON.parse(window?.localStorage.getItem(prefixedKey) || 'undefined') ?? initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key "${prefixedKey}":`, error);
      return initialValue;
    }
  });

  const setItem = useCallback(
    (value: T) => {
      try {
        window?.localStorage.setItem(prefixedKey, JSON.stringify(value));
      } catch (error) {
        console.warn(`Error setting localStorage key "${prefixedKey}":`, error);
      }
    },
    [prefixedKey],
  );

  const debouncedSetItem = useCallback(
    (value: T) => {
      if (debounceTime === 0) {
        setItem(value);
        return;
      }

      const debouncedFn = debounce(() => {
        setItem(value);
      }, debounceTime);

      debouncedFn();
    },
    [debounceTime, setItem],
  );

  const setValue = (value: T) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      debouncedSetItem(valueToStore);
    } catch (error) {
      console.warn(`Error setting localStorage key "${prefixedKey}":`, error);
    }
  };

  return [storedValue, setValue];
}
