import {
  addDomainApi,
  getDomainsApi,
  GetDomainsParams,
  searchDomainsApi,
  SearchDomainsParams,
} from '@/api/domains.api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const getDomainsQuery = (searchParamsState: GetDomainsParams) => ({
  queryKey: ['domains', searchParamsState],
  queryFn: () => getDomainsApi(searchParamsState),
});

export const getDomainSuggestionsQuery = (
  searchSuggestionsParams: SearchDomainsParams,
  enabled: boolean,
) => ({
  queryKey: ['domainSuggestions', searchSuggestionsParams],
  queryFn: () => searchDomainsApi(searchSuggestionsParams),
  enabled,
});

export const useAddDomain = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ domains, notes }: { domains: string[]; notes?: string }) =>
      addDomainApi(domains, notes || ''),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['domains'] });
      onSuccess?.();
    },
    onError: (error) => {
      onError?.();
      console.error(error);
    },
  });
};
