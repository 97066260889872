export const AssetsCurrentStateIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 3H8.5V2C8.5 1.86739 8.44732 1.74021 8.35355 1.64645C8.25979 1.55268 8.13261 1.5 8 1.5C7.86739 1.5 7.74021 1.55268 7.64645 1.64645C7.55268 1.74021 7.5 1.86739 7.5 2V3H2.5C2.23478 3 1.98043 3.10536 1.79289 3.29289C1.60536 3.48043 1.5 3.73478 1.5 4V11.5C1.5 11.7652 1.60536 12.0196 1.79289 12.2071C1.98043 12.3946 2.23478 12.5 2.5 12.5H4.96L3.60938 14.1875C3.52649 14.2911 3.48816 14.4234 3.50282 14.5552C3.51747 14.6871 3.5839 14.8077 3.6875 14.8906C3.7911 14.9735 3.92338 15.0118 4.05524 14.9972C4.1871 14.9825 4.30774 14.9161 4.39062 14.8125L6.24 12.5H9.76L11.6094 14.8125C11.6504 14.8638 11.7012 14.9065 11.7587 14.9382C11.8162 14.9699 11.8795 14.9899 11.9448 14.9972C12.01 15.0044 12.0761 14.9988 12.1392 14.9805C12.2023 14.9622 12.2612 14.9317 12.3125 14.8906C12.3638 14.8496 12.4065 14.7988 12.4382 14.7413C12.4699 14.6838 12.4899 14.6205 12.4972 14.5552C12.5044 14.49 12.4988 14.4239 12.4805 14.3608C12.4622 14.2977 12.4317 14.2388 12.3906 14.1875L11.04 12.5H13.5C13.7652 12.5 14.0196 12.3946 14.2071 12.2071C14.3946 12.0196 14.5 11.7652 14.5 11.5V4C14.5 3.73478 14.3946 3.48043 14.2071 3.29289C14.0196 3.10536 13.7652 3 13.5 3ZM13.5 11.5H2.5V4H13.5V11.5Z"
        fill="#122733"
      />
    </svg>
  );
};

export const AssetsMagnifyingGlassIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_233_1628)">
        <path
          d="M21.5 21.875C21.5 22.2458 21.39 22.6084 21.184 22.9167C20.978 23.225 20.6851 23.4654 20.3425 23.6073C19.9999 23.7492 19.6229 23.7863 19.2592 23.714C18.8955 23.6416 18.5614 23.463 18.2992 23.2008C18.037 22.9386 17.8584 22.6045 17.786 22.2408C17.7137 21.8771 17.7508 21.5001 17.8927 21.1575C18.0346 20.8149 18.275 20.522 18.5833 20.316C18.8916 20.11 19.2542 20 19.625 20C20.1223 20 20.5992 20.1975 20.9508 20.5492C21.3025 20.9008 21.5 21.3777 21.5 21.875ZM28.25 16.25V28.25C28.25 28.6478 28.092 29.0294 27.8107 29.3107C27.5294 29.592 27.1478 29.75 26.75 29.75H13.25C12.8522 29.75 12.4706 29.592 12.1893 29.3107C11.908 29.0294 11.75 28.6478 11.75 28.25V11.75C11.75 11.3522 11.908 10.9706 12.1893 10.6893C12.4706 10.408 12.8522 10.25 13.25 10.25H22.25C22.3485 10.2499 22.4461 10.2693 22.5371 10.3069C22.6282 10.3445 22.7109 10.3997 22.7806 10.4694L28.0306 15.7194C28.1003 15.7891 28.1555 15.8718 28.1931 15.9629C28.2307 16.0539 28.2501 16.1515 28.25 16.25ZM23.5306 24.7194L22.4806 23.6703C22.9233 22.967 23.0912 22.1252 22.9524 21.3058C22.8137 20.4865 22.3779 19.747 21.7283 19.2286C21.0788 18.7102 20.261 18.4494 19.4313 18.4958C18.6016 18.5423 17.818 18.8928 17.2304 19.4804C16.6428 20.068 16.2923 20.8516 16.2458 21.6813C16.1994 22.511 16.4602 23.3288 16.9786 23.9783C17.497 24.6279 18.2365 25.0637 19.0558 25.2024C19.8752 25.3412 20.717 25.1733 21.4203 24.7306L22.4694 25.7806C22.5391 25.8503 22.6218 25.9056 22.7128 25.9433C22.8039 25.981 22.9015 26.0004 23 26.0004C23.0985 26.0004 23.1961 25.981 23.2872 25.9433C23.3782 25.9056 23.4609 25.8503 23.5306 25.7806C23.6003 25.7109 23.6556 25.6282 23.6933 25.5372C23.731 25.4461 23.7504 25.3485 23.7504 25.25C23.7504 25.1515 23.731 25.0539 23.6933 24.9628C23.6556 24.8718 23.6003 24.7891 23.5306 24.7194ZM26.375 16.25L22.25 12.125V16.25H26.375Z"
          fill="#F05941"
        />
      </g>
      <defs>
        <clipPath id="clip0_233_1628">
          <rect width="24" height="24" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AssetsPlusIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_233_1629)">
        <path
          d="M20 11C15.038 11 11 15.038 11 20C11 24.962 15.038 29 20 29C24.962 29 29 24.962 29 20C29 15.038 24.962 11 20 11ZM20 13C23.858 13 27 16.142 27 20C27 23.858 23.858 27 20 27C16.142 27 13 23.858 13 20C13 16.142 16.142 13 20 13ZM20 16C19.448 16 19 16.448 19 17V19H17C16.448 19 16 19.448 16 20C16 20.552 16.448 21 17 21H19V23C19 23.552 19.448 24 20 24C20.552 24 21 23.552 21 23V21H23C23.552 21 24 20.552 24 20C24 19.448 23.552 19 23 19H21V17C21 16.448 20.552 16 20 16Z"
          fill="#F05941"
        />
      </g>
      <defs>
        <clipPath id="clip0_233_1629">
          <rect width="24" height="24" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};
