import MarkdownViewer from '@/components/Markdown/MarkdownViewer';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useFeatureFlag } from '@/context/FeatureFlagContext';
import { toast } from '@/hooks/use-toast';
import { useSyncedParams } from '@/hooks/useSynchedState';
import { AssetDetailsWithDetails } from '@/types/api/response/assets';
import { Vulnerability } from '@/types/api/response/investigations';
import { useTriggerAIScan } from '@/v2/queries/assets';
import {
  AlertCircle,
  AlertTriangle,
  Building,
  FileCode,
  FileText,
  Package,
  Shield,
  ShieldAlert,
  Sparkles,
  Tag,
  Target,
  Terminal,
  TrendingUp,
} from 'lucide-react';
import { useEffect, useState } from 'react';
type AssetSearchParamsType = {
  cve?: string;
};

export default function AssetDetailVulnerabilities({ asset }: { asset: AssetDetailsWithDetails }) {
  const isAiAssistEnabled = useFeatureFlag('VULNERABILITES_AI_ANALYSIS');
  const { synchedState } = useSyncedParams<AssetSearchParamsType>({
    cve: '',
  });

  const [showAiDetails, setShowAiDetails] = useState(false);
  const [selectedCVE, setSelectedCVE] = useState<Vulnerability | null>(null);
  const { mutate: triggerAIScan } = useTriggerAIScan();

  useEffect(() => {
    if (synchedState.cve) {
      const element = document.getElementById(`vulnerability-${synchedState.cve}`);
      if (element) {
        element.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
    }
  }, [synchedState.cve]);

  const handleAIScan = (vulnerability: Vulnerability) => {
    toast({
      title: `AI Scan started for ${vulnerability.cve_id}`,
      description: 'Please wait while we process your request',
    });
    setSelectedCVE(vulnerability);
    setShowAiDetails(false);
    triggerAIScan(vulnerability.cve_id);
  };

  const handleCloseAiDetails = () => {
    setSelectedCVE(null);
    setShowAiDetails(false);
  };

  const handleOpenAiDetails = (vulnerability: Vulnerability) => {
    setSelectedCVE(vulnerability);
    setShowAiDetails(true);
  };

  return (
    <>
      <Sheet open={showAiDetails} onOpenChange={handleCloseAiDetails}>
        <SheetContent className="w-full sm:max-w-[80vw] md:max-w-[70vw] lg:max-w-[50vw] overflow-y-auto">
          <SheetHeader className="mb-4">
            <SheetTitle>AI Details for {selectedCVE?.cve_id}</SheetTitle>
            <SheetDescription>AI Details for the vulnerability</SheetDescription>
          </SheetHeader>
          {selectedCVE?.ai_summary ? (
            <Tabs defaultValue="summary" className="w-full">
              <TabsList>
                <TabsTrigger value="summary">Summary</TabsTrigger>
                <TabsTrigger value="exploit">Exploit</TabsTrigger>
                <TabsTrigger value="remediation">Remediation</TabsTrigger>
              </TabsList>
              <TabsContent value="summary" className="w-full">
                {selectedCVE?.ai_summary && (
                  <div className="grid gap-6 pb-6">
                    <Card>
                      <CardHeader>
                        <CardTitle className="flex items-center gap-2">
                          <Shield className="h-5 w-5" />
                          Vulnerability Summary
                        </CardTitle>
                        <CardDescription>
                          AI-generated analysis of the vulnerability
                        </CardDescription>
                      </CardHeader>
                      <CardContent>
                        <p className="text-sm leading-relaxed text-black">
                          {selectedCVE.ai_summary}
                        </p>
                      </CardContent>
                    </Card>
                  </div>
                )}
              </TabsContent>
              <TabsContent value="exploit" className="w-full">
                {selectedCVE?.ai_exploit ? (
                  <div className="grid gap-6 mt-4">
                    {/*                  <Alert variant="default" className="bg-red-50 border-red-200">
                    <AlertTriangle className="h-4 w-4 text-red-500" />
                    <AlertTitle className="text-red-700">Security Warning</AlertTitle>
                    <AlertDescription className="text-red-900">
                      This exploit information is for educational and research purposes only. Ensure
                      you have proper authorization before testing any exploits.
                    </AlertDescription>
                  </Alert> */}

                    {selectedCVE.ai_exploit.steps && (
                      <Card>
                        <CardHeader>
                          <CardTitle className="flex items-center gap-2">
                            <Terminal className="h-5 w-5 text-red-500" />
                            Exploit Overview
                          </CardTitle>
                          <CardDescription>{selectedCVE.ai_exploit.steps.brief}</CardDescription>
                        </CardHeader>
                      </Card>
                    )}

                    {selectedCVE.ai_exploit.code && (
                      <Card>
                        <CardHeader>
                          <CardTitle className="flex items-center gap-2">
                            <FileCode className="h-5 w-5 text-red-500" />
                            Proof of Concept
                          </CardTitle>
                          <CardDescription>
                            Step-by-step exploitation process with code examples
                          </CardDescription>
                        </CardHeader>
                        <CardContent className="grid gap-6">
                          {selectedCVE.ai_exploit.steps.steps.map((step, idx) => (
                            <div key={idx} className="grid gap-2">
                              <div className="flex gap-3 items-start">
                                <Badge
                                  variant="outline"
                                  className="h-6 w-6 shrink-0 p-0 flex items-center justify-center bg-red-50 text-red-700 border-red-200"
                                >
                                  {idx + 1}
                                </Badge>
                                <MarkdownViewer
                                  markdown={step}
                                  className="bg-gray-100 p-4 rounded-lg hover:opacity-95"
                                />
                              </div>
                            </div>
                          ))}
                        </CardContent>
                      </Card>
                    )}

                    <Card>
                      <CardHeader>
                        <CardTitle className="flex items-center gap-2">
                          <FileText className="h-5 w-5 text-red-500" />
                          Exploit Code
                        </CardTitle>
                        <CardDescription>Ready-to-use proof of concept code</CardDescription>
                      </CardHeader>
                      <CardContent>
                        <MarkdownViewer
                          markdown={'```\n' + selectedCVE.ai_exploit.code + '\n```'}
                          className="bg-gray-100 p-4 rounded-lg hover:opacity-95"
                        />
                      </CardContent>
                    </Card>
                  </div>
                ) : (
                  <div className="flex flex-col gap-4 items-center justify-center p-8">
                    <div className="text-gray-500">No Exploit available</div>
                  </div>
                )}
              </TabsContent>
              <TabsContent value="remediation" className="w-full">
                {selectedCVE?.ai_remediation ? (
                  <div className="grid gap-6 mt-4">
                    <Alert variant="default" className="bg-orange-50 border-orange-200">
                      <Terminal className="h-4 w-4 text-orange-500" />
                      <AlertTitle className="text-orange-700">Remediation Overview</AlertTitle>
                      <AlertDescription className="text-orange-900">
                        {selectedCVE.ai_remediation.brief}
                      </AlertDescription>
                    </Alert>

                    <Card>
                      <CardHeader>
                        <CardTitle className="flex items-center gap-2">
                          <FileCode className="h-5 w-5 text-orange-500" />
                          Remediation Steps
                        </CardTitle>
                        <CardDescription>
                          Follow these steps to address the vulnerability
                        </CardDescription>
                      </CardHeader>
                      <CardContent className="grid gap-6">
                        {selectedCVE.ai_remediation.steps.map((step, idx) => {
                          return (
                            <div key={idx} className="grid gap-2">
                              <div className="flex gap-3 items-start">
                                <Badge
                                  variant="outline"
                                  className="h-6 w-6 shrink-0 p-0 flex items-center justify-center bg-orange-50 text-orange-700 border-orange-200"
                                >
                                  {idx + 1}
                                </Badge>
                                <MarkdownViewer
                                  markdown={step}
                                  className="bg-gray-100 p-4 rounded-lg  hover:opacity-95 "
                                />
                              </div>
                            </div>
                          );
                        })}
                      </CardContent>
                    </Card>
                  </div>
                ) : (
                  <div className="flex flex-col gap-4 items-center justify-center p-8">
                    <div className="text-gray-500">No Remediation available</div>
                  </div>
                )}
              </TabsContent>
            </Tabs>
          ) : (
            <div className="flex flex-col gap-4 items-center justify-center p-8">
              <div className="text-gray-500">No AI analysis available</div>
              <Button className="gap-2" onClick={() => handleAIScan(selectedCVE)}>
                <Sparkles className="h-4 w-4" />
                Run AI Analysis
              </Button>
            </div>
          )}
        </SheetContent>
      </Sheet>
      <div className="pb-4 max-h-[300px] lg:max-h-[500px] xl:max-h-[700px] overflow-y-auto">
        {asset.vulnerabilities.length > 0 ? (
          <div className="mb-4">
            <h2 className="text-2xl font-bold mb-4">Vulnerabilities</h2>
            <div className="space-y-4">
              {asset.vulnerabilities.map((vulnerability, index) => (
                <div key={vulnerability.cve_id} id={`vulnerability-${vulnerability.cve_id}`}>
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-2 w-fit">
                      <AlertCircle className="mt-1" />
                      <p className="flex items-center gap-2 w-fit">
                        CVE:{' '}
                        <a
                          href={`https://nvd.nist.gov/vuln/detail/${vulnerability.cve_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          {vulnerability.cve_id}
                        </a>
                      </p>
                    </div>
                    <div className="flex items-center gap-2 w-fit">
                      <ShieldAlert className="mt-1" />
                      <p>
                        <span className="text-black">CVSSv3 Base Score:</span>{' '}
                        {vulnerability.cvss3_base_score}
                      </p>
                    </div>
                    <div className="flex items-center gap-2 w-fit">
                      <TrendingUp className="mt-1" />
                      <p>
                        <span className="text-black">CVSSv3 Exploitability Score:</span>{' '}
                        {vulnerability.cvss3_exploitability_score}
                      </p>
                    </div>
                    <div className="flex items-center gap-2 w-fit">
                      <Building className="mt-1" />
                      <p>
                        <span className="text-black">Vendor:</span> {vulnerability.cpe_uris.vendor}
                      </p>
                    </div>
                    <div className="flex items-center gap-2 w-fit">
                      <Package className="mt-1" />
                      <p>
                        <span className="text-black">Product:</span>{' '}
                        {vulnerability.cpe_uris.product}
                      </p>
                    </div>
                    <div className="flex items-center gap-2 w-fit">
                      <FileText className="mt-1 h-8 w-8" />
                      <p className="text-sm  text-gray-700">
                        <span className="text-black">Description:</span> {vulnerability.description}
                      </p>
                    </div>
                    {isAiAssistEnabled && (
                      <Button
                        onClick={() => handleOpenAiDetails(vulnerability)}
                        variant="outline"
                        className="gap-2 border-2 border-orange-500 hover:bg-orange-50 hover:text-orange-700 w-fit"
                      >
                        <>
                          <Sparkles className="h-4 w-4 text-orange-500" />
                          <span className="bg-gradient-to-r from-orange-500 to-orange-700 bg-clip-text text-transparent font-semibold">
                            AI Assist
                          </span>
                        </>
                      </Button>
                    )}
                  </div>
                  {index < asset.vulnerabilities.length - 1 && (
                    <hr className="my-4 border-gray-600" />
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p>No vulnerabilities found for this asset.</p>
        )}
        <h2 className="text-2xl font-bold mb-4">Nuclei Vulnerabilities</h2>
        <ul>
          {asset.nuclei_vulnerabilities.length > 0 ? (
            asset.nuclei_vulnerabilities.map((vulnerability, idx) => (
              <li key={idx}>
                <div className="grid grid-cols-[24px,1fr] gap-4">
                  <FileCode className="mt-1" />
                  <p>
                    Reference:{' '}
                    {vulnerability.nuclei_template.id.toLowerCase().includes('cve') ? (
                      <a
                        href={`https://nvd.nist.gov/vuln/detail/${vulnerability.nuclei_template.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {vulnerability.nuclei_template.id}
                      </a>
                    ) : (
                      vulnerability.nuclei_template.id
                    )}
                  </p>
                  <Tag className="mt-1" />
                  <p>
                    <span className="text-black">Template Name:</span>{' '}
                    {vulnerability.nuclei_template.name}
                  </p>
                  <AlertTriangle className="mt-1" />
                  <p>
                    <span className="text-black">Severity:</span>{' '}
                    {vulnerability.nuclei_template.severity}
                  </p>
                  <Target className="mt-1" />
                  <p>
                    <span className="text-black">Matched At:</span> {vulnerability.matched_at}
                  </p>
                  <FileText className="mt-1" />
                  <p>
                    <span className="text-black">Description:</span>{' '}
                    {vulnerability.nuclei_template.description &&
                    vulnerability.nuclei_template.description.length > 0
                      ? vulnerability.nuclei_template.description
                      : 'N/A'}
                  </p>
                </div>
                {idx < asset.nuclei_vulnerabilities.length - 1 && (
                  <hr className="my-4 border-gray-600" />
                )}
              </li>
            ))
          ) : (
            <p>No nuclei vulnerabilities found for this asset.</p>
          )}
        </ul>
      </div>
    </>
  );
}
