import Markdown from 'react-markdown';

export default function MarkdownViewer({
  markdown,
  className,
  onClick,
}: {
  markdown: string;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <div className={className} onClick={onClick}>
      <Markdown className="prose">{markdown}</Markdown>
    </div>
  );
}
