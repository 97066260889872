import clsx from 'clsx';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar } from '../components/Navbar/Navbar';

const LoggedInLayout = () => {
  return (
    <div className={clsx('logged-in-layout', 'flex', 'flex-row', 'h-screen')}>
      <Navbar />

      <main className={clsx('content', 'flex-1', 'overflow-y-auto')}>
        <Suspense fallback={<div></div>}>
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
};

export default LoggedInLayout;
