import { UpdateIssuePayload } from '@/api/issues.api';
import MarkdownEditor from '@/components/Markdown/MarkdownEditor';
import MarkdownViewer from '@/components/Markdown/MarkdownViewer';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { NVDVulnerability } from '@/types/api/response/nvd';
import { useGetIssue, useUpdateIssueMutation } from '@/v2/queries/issues';
import { getUsersQuery } from '@/v2/queries/users';
import { useQuery } from '@tanstack/react-query';
import { ArrowLeft } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AssigneeSelector } from './AssigneeSelector';
import { getSeverityDisplay } from './Issues';
import { getResourceDisplayValue, ResourceSelector } from './ResourceSelector';
import { StatusSelector } from './StatusSelector';
import { VulnerabilitySelector } from './VulnerabilitySelector';

const IssueDetails = () => {
  const { id } = useParams();
  const { mutate: updateIssue } = useUpdateIssueMutation();
  const { data: issue, isPending: loading, error } = useGetIssue(Number(id));
  const { data: users, isPending: loadingUsers } = useQuery(getUsersQuery());
  const navigate = useNavigate();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [selectedResourceType, setSelectedResourceType] = useState<string | null>(null);
  const [selectedResource, setSelectedResource] = useState<string | null>(null);
  const [selectedVulnerabilityType, setSelectedVulnerabilityType] = useState<string | null>(null);
  const [selectedVulnerability, setSelectedVulnerability] = useState<any | null>(null);

  useEffect(() => {
    if (issue?.affected_resource) {
      setSelectedResourceType(issue.affected_resource_model_type);
      setSelectedResource(
        getResourceDisplayValue(issue.affected_resource, issue.affected_resource_model_type),
      );
    }
    if (issue?.vulnerability) {
      setSelectedVulnerability(issue.vulnerability ?? null);
    }
  }, [issue]);

  const handleUpdate = async (updates: Partial<UpdateIssuePayload>) => {
    if (!issue) return;
    try {
      updateIssue({
        issueId: issue.id,
        updates: {
          ...issue,
          ...updates,
        },
      });
    } catch (err) {
      console.error('Failed to update issue:', err);
    }
  };

  const handleResourceSelect = (resourceType: string | null, resource: any) => {
    setSelectedResourceType(resourceType);
    setSelectedResource(resource);

    if (resource && resourceType) {
      handleUpdate({
        affected_resource_model_type: resourceType,
        affected_resource_object_id: resource.id,
      });
    }
  };

  const handleClearResource = () => {
    setSelectedResource(null);
    setSelectedResourceType(null);
    setSelectedVulnerability(null);
    setSelectedVulnerabilityType(null);

    if (issue) {
      handleUpdate({
        affected_resource_model_type: null,
        affected_resource_object_id: null,
        vuln_model_type: null,
        vuln_object_id: null,
      });
    }
  };

  const handleVulnerabilitySelect = (
    vulnerabilityType: string | null,
    vulnerability: NVDVulnerability | null,
  ) => {
    setSelectedVulnerabilityType(vulnerabilityType);
    setSelectedVulnerability(vulnerability);

    if (vulnerability && vulnerabilityType) {
      handleUpdate({
        vuln_model_type: vulnerabilityType,
        vuln_object_id: vulnerability.id,
      });
    }
  };

  const handleClearVulnerability = () => {
    setSelectedVulnerability(null);
    setSelectedVulnerabilityType(null);

    if (issue) {
      handleUpdate({
        vuln_model_type: null,
        vuln_object_id: null,
      });
    }
  };

  const handleTitleBlur = () => {
    handleUpdate({ title: editedTitle });
    setIsEditingTitle(false);
  };

  const handleDescriptionBlur = () => {
    handleUpdate({ description: editedDescription });
    setIsEditingDescription(false);
  };

  if (loading) {
    return (
      <div className="container mx-auto p-4 max-w-4xl">
        <div className="bg-table_header_bg p-2 rounded mb-4 flex justify-between items-center">
          <div className="flex items-center gap-4">
            <Button onClick={() => navigate('/issues')}>
              <ArrowLeft className="mr-0.5 h-4 w-4" />
              Issues
            </Button>
          </div>
        </div>
        <Card className="bg-white text-gray-900">
          <CardContent className="flex items-center justify-center p-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
          </CardContent>
        </Card>
      </div>
    );
  }

  if (error || !issue) {
    return (
      <div className="container mx-auto p-4">
        <Card>
          <CardContent className="text-center text-red-500 p-4">
            {error.message || 'Issue not found'}
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 max-w-4xl">
      <div className="bg-table_header_bg p-2 rounded mb-4 flex justify-between items-center">
        <div className="flex items-center gap-4">
          <Button onClick={() => navigate('/issues')}>
            <ArrowLeft className="mr-0.5 h-4 w-4" />
            Issues
          </Button>
        </div>
      </div>

      <Card className="bg-white text-gray-900">
        <CardHeader className="flex flex-row items-start justify-between space-y-0">
          <div className="flex-grow space-y-4">
            <div className="flex items-center space-x-2">
              {getSeverityDisplay(issue.severity, true, 8)}
            </div>
            {isEditingTitle ? (
              <Input
                value={editedTitle}
                onChange={(e) => setEditedTitle(e.target.value)}
                onBlur={handleTitleBlur}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleTitleBlur();
                  }
                }}
                className="text-xl font-bold bg-gray-100 w-[90%]"
                autoFocus
              />
            ) : (
              <CardTitle
                className="text-2xl font-bold cursor-pointer hover:opacity-80 w-[90%]"
                onClick={() => {
                  setEditedTitle(issue.title);
                  setIsEditingTitle(true);
                }}
              >
                {issue.title}
              </CardTitle>
            )}
            <div className="mt-6">
              <h3 className="font-semibold mb-1">{selectedResource ? selectedResourceType : ''}</h3>
              <ResourceSelector
                selectedResourceType={selectedResourceType}
                selectedResource={issue.affected_resource}
                onResourceSelect={handleResourceSelect}
                onClear={handleClearResource}
              />
            </div>
            <div className="mt-6">
              <h3 className="font-semibold mb-1">{selectedVulnerability ? 'Vulnerability' : ''}</h3>
              <VulnerabilitySelector
                selectedVulnerabilityType={selectedVulnerabilityType}
                selectedVulnerability={selectedVulnerability}
                onVulnerabilitySelect={handleVulnerabilitySelect}
                onClear={handleClearVulnerability}
              />
            </div>
          </div>
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold mb-1">Assigned to</h3>
              <AssigneeSelector
                users={users?.entries}
                assigneeId={issue.assigned_to?.user?.id}
                assigneeName={issue.assigned_to?.user?.username}
                onAssigneeChange={(userId) => handleUpdate({ assigned_to_id: userId })}
                loading={loadingUsers}
              />
            </div>
            <div>
              <h3 className="font-semibold mb-1">Status</h3>
              <StatusSelector
                currentStatus={issue.status}
                onStatusChange={(status) => handleUpdate({ status })}
              />
            </div>
            <div>
              <h3 className="font-semibold mb-1">Issue Number</h3>
              <p>#{issue.issue_number}</p>
            </div>
            <div className="text-sm text-gray-600 space-y-1">
              {issue.updated_at && <p>Updated: {new Date(issue.updated_at).toLocaleString()}</p>}
              <p>Created: {new Date(issue.created_at).toLocaleString()}</p>
            </div>
          </div>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="mt-6">
            <h3 className="font-semibold mb-2">Description</h3>
            {isEditingDescription ? (
              <MarkdownEditor
                markdown={editedDescription}
                className="rounded-lg h-[300px]"
                onChange={(markdown) => setEditedDescription(markdown)}
                onBlur={handleDescriptionBlur}
                autoFocus
              />
            ) : (
              <MarkdownViewer
                markdown={issue.description}
                className="bg-gray-100 p-4 rounded-lg cursor-pointer hover:opacity-95 min-h-[300px]"
                onClick={() => {
                  setEditedDescription(issue.description);
                  setIsEditingDescription(true);
                }}
              />
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default IssueDetails;
