import { User } from '@/types/api/response/user';
import { cn } from '@/lib/utils';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useState, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

interface AuthDropdownProps {
  userData: User;
  userOrganization: string;
  handleLogoff: () => void;
}

const AuthDropdown = ({ userData, userOrganization, handleLogoff }: AuthDropdownProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const CurrentChevronIcon = useMemo(
    () => (isDropdownOpen ? ChevronUp : ChevronDown),
    [isDropdownOpen],
  );

  return (
    <div className="flex items-center relative">
      <button
        className={cn(
          'flex items-center gap-2 text-v2-gray hover:text-v2-gray-hover',
          isDropdownOpen && 'text-v2-gray-hover',
        )}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <span>{userData?.username ?? 'User'}</span>
        <CurrentChevronIcon className="flex-none w-4 h-4" />
      </button>

      <div
        className={cn(
          'absolute top-full right-0 mt-2 w-max max-w-96 bg-white rounded-md shadow-lg py-1',
          !isDropdownOpen && 'hidden',
        )}
      >
        <div className="px-4 py-2 text-sm text-gray-700 border-b">
          Organization: {userOrganization}
        </div>
        <NavLink
          to="/change-password"
          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          Change Password
        </NavLink>
        <button
          onClick={handleLogoff}
          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          Log Off
        </button>
      </div>
    </div>
  );
};

export default AuthDropdown;
