//import Markdown from "react-markdown";
import Markdown from "./Markdown";
import AutoCompleteSearchBar from "./AutoCompleteSearchBar";
import remarkGfm from "remark-gfm";
import React, {useState, useRef} from 'react';
import './Notes.css';
import {uploadImage} from "./NotesAPI";
import {Box, Button, Input, Text} from '@chakra-ui/react';

const NoteCreator = ({ headerContent, initialContent, onSave, onEdit, onCloseAction, technologyAssetId}) => {
    const [newNoteText, setNewNoteText] = useState(initialContent || '');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isMinimized, setIsMinimized] = useState(false);
    //const [textSize, setTextSize] = useState('medium'); // New state for text size
    const [showPreview, setShowPreview] = useState(false); // State to control preview visibility
    const [wasFullScreenBeforeMinimize, setWasFullScreenBeforeMinimize] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [selectedAssetId, setSelectedAsset] = useState(null); // If creating note with search functionality, holds chosen asset
    const [imageSize, setImageSize] = useState('medium'); // New state for image size
    const fileInputRef = useRef(null);
    const textareaRef = useRef(null);

    const handleShowPreview = () => {
        setShowPreview(!showPreview); // Toggle preview visibility
        if (!isFullScreen) {
            setIsFullScreen(true); // Expand the modal if not already in full-screen mode
        }
    };

    const insertTextAtCursor = (text) => {
        const textarea = textareaRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const newText = newNoteText.substring(0, start) + text + newNoteText.substring(end);
        setNewNoteText(newText);
        textarea.focus();
        textarea.setSelectionRange(start + text.length, start + text.length);
    };

    /*
    // Functions used by Bold/Italicize buttons, currently not used. Can edit later
    const insertText = (before, after = '') => {
        const textarea = textareaRef.current;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const selectedText = newNoteText.substring(start, end);
        const newText = `${before}${selectedText}${after}`;
        const updatedText = newNoteText.substring(0, start) + newText + newNoteText.substring(end);

        setNewNoteText(updatedText);
        textarea.focus();
        textarea.setSelectionRange(start + before.length, start + newText.length);
    };


    const handleBold = () => insertText('**', '**');
    const handleItalic = () => insertText('*', '*');
    //const handleUnderline = () => insertText('<u>', '</u>');

    const handleTextSizeChange = (e) => {
        setTextSize(e.target.value);
    };
    */

    const handleSave = () => {
        onSave(selectedAssetId, newNoteText);
        setNewNoteText('');
        //onClose;
    };

    const handleAssetSelect = (assetSelected) => {
        console.log("Selected asset:", selectedAssetId);
        // Handle the selected asset as needed, e.g., setting state
        setSelectedAsset(assetSelected);
        technologyAssetId = assetSelected;

    };

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
        if(showPreview){
            setShowPreview(false);
        }
    };

    const toggleMinimize = () => {
        if (!isMinimized) {
            // Remember the current full-screen state before minimizing
            setWasFullScreenBeforeMinimize(isFullScreen);
            setIsFullScreen(false); // Ensure full-screen is off when minimized
        } else {
            // Restore the previous full-screen state when unminimized
            setIsFullScreen(wasFullScreenBeforeMinimize);
        }
        setIsMinimized(!isMinimized);
    };

    const handleModalClick = () => {
        if (isMinimized) {
            toggleMinimize();
        }
    };


    const handleImageUpload = async (event) => {
        setUploadingImage(true);
        const file = event.target.files[0];
        const resp = await uploadImage(file);
        setUploadingImage(false);

        if (resp && resp.imageUrl) {
            // Insert image Markdown into the note text

            // const sizeSuffix = imageSize === 'small' ? '=200' : imageSize === 'large' ? '=800' : '=400';
            // const markdownImageText = `![Image](${resp.imageUrl} ${sizeSuffix}x)\n`;

            const markdownImageText = `![Image](${resp.imageUrl})\n`;
            insertTextAtCursor(markdownImageText);
        } else {
            // Handle upload error
            console.error('Image upload failed');
        }
    };

    return (
        <Box className={`note-creator-modal ${isFullScreen ? 'full-screen' : ''} ${isMinimized ? 'minimized' : ''}`} onClick={handleModalClick}>
            <Box className="modal-header">
                {headerContent ? (
                    <Text fontSize="xs" className="modal-title">{headerContent}</Text>
                ) : (
                    // Render AutocompleteSearchBar when headerContent is null
                    <AutoCompleteSearchBar
                        placeholder="Start typing to find an asset"
                        onSelect={handleAssetSelect}
                        color="black"
                    />
                )}
                <Box className="modal-controls">
                    {!isMinimized &&
                        <Button size="xs" color="black" onClick={toggleMinimize} className="control-btn minimize-btn">_</Button>
                    }
                    {!isMinimized &&
                        <Button size="xs" color="black" onClick={toggleFullScreen} className="control-btn full-screen-btn">
                            {isFullScreen ? '↙' : '↗'} {/* Change arrow direction based on isFullScreen */}
                        </Button>
                    }
                    <Button size="xs" color="black" onClick={onCloseAction} className="control-btn close-btn">X</Button>
                </Box>
            </Box>
            <Box className="note-creator-content">
                <textarea className="note-textarea"
                          ref={textareaRef}
                          value={newNoteText}
                          onChange={(e) => setNewNoteText(e.target.value)}
                          placeholder="Start writing.. Markdown supported"
                />
                {showPreview && (
                    <div className='markdown-preview'>
                        <Markdown
                            //remarkPlugins={[remarkGfm]}
                        >{newNoteText}</Markdown>
                    </div>
                )}
            {/*Temporarily removing, buttons for formatting text
            <div className="formatting-buttons">
                <div className="tooltip">
                        <button onClick={handleBold}><strong>B</strong></button>
                        <span className="tooltiptext">Bold</span>
                    </div>
                <div className="tooltip">
                    <button onClick={handleItalic}><strong><em>I</em></strong></button>
                    <span className="tooltiptext">Italic</span>
                </div>
                <div className="text-size-dropdown">
                    <select onChange={handleTextSizeChange} value={textSize}>
                        <option value="small">Small</option>
                        <option value="medium">Medium</option>
                        <option value="large">Large</option>
                    </select>
                </div>
            </div>*/}
            </Box>
            <div className="modal-footer">
                {uploadingImage ? (
                    <span>Uploading image...</span>
                ) : (
                    <>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            style={{display: 'none'}}
                            ref={fileInputRef}/>
                        <button className='save-note-button' onClick={() => fileInputRef.current && fileInputRef.current.click()}>Upload Image</button>
                        <button className='save-note-button' onClick={handleSave}>Save and Publish</button>
                    </>
                )}
                <button className="preview-button" onClick={handleShowPreview}>
                        {showPreview ? 'Hide Preview' : 'Show Preview'}
                </button>
            </div>
        </Box>
    );
}

export default NoteCreator