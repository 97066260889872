import { AssetDetailsWithDetails, AssetHistory } from '@/types/api/response/assets';
import { ImageWithModal } from '@/v2/components/ImageWithModal/ImageWithModal';
import { useState } from 'react';

interface HistoryViewProps {
  rowData: AssetDetailsWithDetails;
  historyData: AssetHistory[];
  isFetching: boolean;
}

const HistoryView = ({ rowData, historyData, isFetching }: HistoryViewProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedOlderSnapshot, setSelectedOlderSnapshot] = useState(null);

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    const formattedTime = date.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${formattedDate} at ${formattedTime}`;
  }

  const rowHistoryData = historyData;

  if (isFetching) {
    return (
      <div className="w-full animate-pulse">
        {/* Tab List Skeleton */}
        <div className="flex border-b border-gray-200">
          {[1, 2, 3].map((_, index) => (
            <div key={index} className="px-2 py-1 min-w-[120px] h-8 bg-gray-200 rounded mr-2" />
          ))}
        </div>

        {/* Content Skeleton */}
        <div className="mt-4 flex justify-between">
          <div className="space-y-4 w-1/2">
            {/* Basic Info Skeleton */}
            <div className="space-y-2">
              {[1, 2, 3].map((_, index) => (
                <div key={index} className="h-4 bg-gray-200 rounded w-3/4" />
              ))}
            </div>

            {/* DNS Info Skeleton */}
            <div className="space-y-2">
              <div className="h-5 bg-gray-200 rounded w-1/4 mb-4" />
              {[1, 2, 3].map((_, index) => (
                <div key={index} className="h-4 bg-gray-200 rounded w-2/3" />
              ))}
            </div>

            {/* HTTP Info Skeleton */}
            <div className="space-y-2">
              <div className="h-5 bg-gray-200 rounded w-1/4 mb-4" />
              {[1, 2, 3, 4].map((_, index) => (
                <div key={index} className="h-4 bg-gray-200 rounded w-3/4" />
              ))}
            </div>
          </div>

          {/* Screenshot Skeleton */}
          <div className="w-[400px] h-[300px] bg-gray-200 rounded" />
        </div>
      </div>
    );
  }

  if (!rowHistoryData) {
    return <div>Loading history...</div>;
  }

  if (!rowHistoryData || rowHistoryData.length === 0) {
    return <div className="no-history">No History</div>;
  }

  const latestEntries = rowHistoryData.slice(0, 3);
  const olderEntries = rowHistoryData.slice(3);

  const handleOlderSnapshotChange = (event) => {
    const selectedIndex = event.target.value;
    setSelectedOlderSnapshot(olderEntries[selectedIndex]);
  };

  const hostname = rowData.domain ? rowData.domain.domainname : rowData.ipaddressv4;

  return (
    <div className="w-full">
      {/* Tab List */}
      <div className="flex border-b border-gray-200">
        {latestEntries.map((entry, index) => (
          <button
            key={index}
            className={`px-2 py-1 min-w-[120px] text-xs ${
              selectedTab === index
                ? 'text-blue-500 border-b-2 border-blue-500'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
            onClick={() => setSelectedTab(index)}
          >
            {formatTimestamp(entry.last_scanned)}
          </button>
        ))}
        {olderEntries.length > 0 && (
          <div className="relative">
            <select
              className="px-2 py-1 min-w-[120px] text-xs border-none focus:ring-0 hover:bg-gray-100"
              onChange={handleOlderSnapshotChange}
              onClick={(e) => e.stopPropagation()}
            >
              <option>Older Snapshots</option>
              {olderEntries.map((entry, index) => (
                <option key={index} value={index}>
                  {formatTimestamp(entry.last_scanned)}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      {/* Tab Panels */}
      <div className="mt-4 ">
        {latestEntries.map((entry, index) => (
          <div key={index} className={`${selectedTab === index ? 'block' : 'hidden'} space-y-4`}>
            <div className="flex justify-between">
              <div className="space-y-4">
                <div className="asset-overview-text">
                  <div className="text-left">
                    <div>
                      <strong>Hostname:</strong> {hostname}
                    </div>
                    <div>
                      <strong>Port:</strong> {rowData.port}
                    </div>
                    <strong>Scanned At:</strong> {formatTimestamp(entry.last_scanned)}
                  </div>

                  {/* DNS Information */}
                  <div className="mt-4">
                    <strong className="block mb-2">DNS Information</strong>
                    {entry.dns_record && entry.dns_record.cname && (
                      <div className="flex gap-2">
                        <div className="font-medium">CNAME:</div>
                        <div>{entry.dns_record.cname || 'Not Available'}</div>
                      </div>
                    )}
                    {entry.dns_record && entry.dns_record.ipaddress.length > 0 && (
                      <div className="flex gap-2">
                        <div className="font-medium">IP Address:</div>
                        <div>{entry.dns_record.ipaddress[0].ipaddress}</div>
                      </div>
                    )}
                    {entry.dns_record &&
                      entry.dns_record.ipaddress.length > 0 &&
                      entry.dns_record.ipaddress[0].asn && (
                        <>
                          <div className="flex gap-2">
                            <div className="font-medium">ASN #:</div>
                            <div>{entry.dns_record.ipaddress[0].asn.asn}</div>
                          </div>
                          <div className="flex gap-2">
                            <div className="font-medium">IP Range:</div>
                            <div>{entry.dns_record.ipaddress[0].asn.iprange}</div>
                          </div>
                          <div className="flex gap-2">
                            <div className="font-medium">Owner:</div>
                            <div>{entry.dns_record.ipaddress[0].asn.owner}</div>
                          </div>
                          <br />
                        </>
                      )}
                  </div>

                  {/* HTTP Information */}
                  <div className="mt-4">
                    <strong className="block mb-2">HTTP Information</strong>
                    {!entry.homepage_status_code && (
                      <div className="text-red-600">
                        Unable to connect to {hostname} on {rowData.port}
                      </div>
                    )}
                    {entry.homepage_server_header && (
                      <div className="flex gap-2">
                        <div className="font-medium">Server Header:</div>
                        <div>{entry.homepage_server_header || 'None'}</div>
                      </div>
                    )}
                    {entry.homepage_title && (
                      <div className="flex gap-2">
                        <div className="font-medium">HTML Title:</div>
                        <div>{entry.homepage_title || 'None'}</div>
                      </div>
                    )}
                    {entry.homepage_redirect_url && (
                      <div className="flex gap-2">
                        <div className="font-medium">Redirect URL:</div>
                        <div>{entry.homepage_redirect_url || 'None'}</div>
                      </div>
                    )}
                    {entry.homepage_status_code && (
                      <div className="flex gap-2">
                        <div className="font-medium">HTTP Status Code:</div>
                        <div>{entry.homepage_status_code || 'None'}</div>
                      </div>
                    )}
                  </div>

                  {/* Technologies */}
                  <div className="mt-4">
                    <strong className="block mb-2">Technologies:</strong>
                    {entry.technologies.length === 0 ? (
                      <div className="text-gray-600">No technologies identified</div>
                    ) : (
                      <div className="space-y-1">
                        {entry.technologies.sort().map((tech, index) => (
                          <div key={index}>{tech}</div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Screenshot */}
              <div>
                <ImageWithModal imageUrl={entry.homepage_image} hostname={hostname} />
              </div>
            </div>
          </div>
        ))}

        {/* Selected Older Snapshot Panel */}
        {selectedOlderSnapshot && selectedOlderSnapshot !== 'Older Snapshots' && (
          <div className="space-y-4">
            <div className="flex justify-between">
              <div className="space-y-4">
                <div className="asset-overview-text">
                  <div className="text-left">
                    <div>
                      <strong>Hostname:</strong> {hostname}
                    </div>
                    <div>
                      <strong>Port:</strong> {rowData.port}
                    </div>
                    <strong>Scanned At:</strong>{' '}
                    {formatTimestamp(selectedOlderSnapshot.last_scanned)}
                  </div>

                  {/* DNS Information */}
                  <div className="mt-4">
                    <strong className="block mb-2">DNS Information</strong>
                    {selectedOlderSnapshot.dns_record && selectedOlderSnapshot.dns_record.cname && (
                      <div className="flex gap-2">
                        <div className="font-medium">CNAME:</div>
                        <div>{selectedOlderSnapshot.dns_record.cname || 'Not Available'}</div>
                      </div>
                    )}
                    {selectedOlderSnapshot.dns_record &&
                      selectedOlderSnapshot.dns_record.ipaddress.length > 0 && (
                        <div className="flex gap-2">
                          <div className="font-medium">IP Address:</div>
                          <div>{selectedOlderSnapshot.dns_record.ipaddress[0].ipaddress}</div>
                        </div>
                      )}
                    {selectedOlderSnapshot.dns_record &&
                      selectedOlderSnapshot.dns_record.ipaddress.length > 0 &&
                      selectedOlderSnapshot.dns_record.ipaddress[0].asn && (
                        <>
                          <div className="flex gap-2">
                            <div className="font-medium">ASN #:</div>
                            <div>{selectedOlderSnapshot.dns_record.ipaddress[0].asn.asn}</div>
                          </div>
                          <div className="flex gap-2">
                            <div className="font-medium">IP Range:</div>
                            <div>{selectedOlderSnapshot.dns_record.ipaddress[0].asn.iprange}</div>
                          </div>
                          <div className="flex gap-2">
                            <div className="font-medium">Owner:</div>
                            <div>{selectedOlderSnapshot.dns_record.ipaddress[0].asn.owner}</div>
                          </div>
                          <br />
                        </>
                      )}
                  </div>

                  {/* HTTP Information */}
                  <div className="mt-4">
                    <strong className="block mb-2">HTTP Information</strong>
                    {!selectedOlderSnapshot.homepage_status_code && (
                      <div className="text-red-600">
                        Unable to connect to {hostname} on {selectedOlderSnapshot.port}
                      </div>
                    )}
                    {selectedOlderSnapshot.homepage_server_header && (
                      <div className="flex gap-2">
                        <div className="font-medium">Server Header:</div>
                        <div>{selectedOlderSnapshot.homepage_server_header || 'None'}</div>
                      </div>
                    )}
                    {selectedOlderSnapshot.homepage_title && (
                      <div className="flex gap-2">
                        <div className="font-medium">HTML Title:</div>
                        <div>{selectedOlderSnapshot.homepage_title || 'None'}</div>
                      </div>
                    )}
                    {selectedOlderSnapshot.homepage_redirect_url && (
                      <div className="flex gap-2">
                        <div className="font-medium">Redirect URL:</div>
                        <div>{selectedOlderSnapshot.homepage_redirect_url || 'None'}</div>
                      </div>
                    )}
                    {selectedOlderSnapshot.homepage_status_code && (
                      <div className="flex gap-2">
                        <div className="font-medium">HTTP Status Code:</div>
                        <div>{selectedOlderSnapshot.homepage_status_code || 'None'}</div>
                      </div>
                    )}
                  </div>

                  {/* Technologies */}
                  <div className="mt-4">
                    <strong className="block mb-2">Technologies:</strong>
                    {selectedOlderSnapshot.technologies.length === 0 ? (
                      <div className="text-gray-600">No technologies identified</div>
                    ) : (
                      <div className="space-y-1">
                        {selectedOlderSnapshot.technologies.sort().map((tech, index) => (
                          <div key={index}>{tech}</div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Screenshot */}
              <div>
                {selectedOlderSnapshot.homepage_image ? (
                  <img
                    src={selectedOlderSnapshot.homepage_image}
                    alt="Screenshot"
                    className="max-h-[600px] max-w-full cursor-pointer"
                  />
                ) : (
                  <div className="w-[400px] h-[300px] bg-gray-200 rounded flex items-center justify-center">
                    <span className="text-gray-500">No Image Available</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryView;
