const baseApiUrl = import.meta.env.VITE_REACT_APP_API_URL;
export const fetchASNOverview = async (base_query='', lookup='/assets') => {
    try {
        let queryURLParams = new URLSearchParams();
        if(base_query) {
            if (base_query === '/vulnoverview') {
                queryURLParams.append('base_query', 'vulnoverview')
            }
            else if (base_query === '/vulnscanoverview') {
                queryURLParams.append('base_query', 'vulnscanoverview')
            }
        }

        let api_endpoint = '';
        if (lookup === '/assets'){
            api_endpoint = `${baseApiUrl}/assets/asnoverview?${queryURLParams}`
        }
        else{
            // assuming domain
            api_endpoint = `${baseApiUrl}/domain/asnoverview?${queryURLParams}`
        }

        const response = await fetch(api_endpoint,
            {
                method: 'GET',
                credentials: 'include'
            }
        );
        if (!response.ok) {
            throw new Error('Failed getting notes')
        }
        const data = await response.json();
        return data
        // Handle the received history data
        // You might want to store this in a state or handle it differently
    } catch (error) {
        console.error('Error fetching history data:', error);
    }
};


export const fetchVulnerabilityASNOverview = async () => {
    try {
        let queryURLParams = new URLSearchParams();

        const response = await fetch(`${baseApiUrl}/vulnerabilities/asnoverview`,
            {
                method: 'GET',
                credentials: 'include'
            }
        );
        if (!response.ok) {
            throw new Error('Failed getting notes')
        }
        const data = await response.json();
        return data
    } catch (error) {
        console.error('Error fetching history data:', error);
    }
};

export const fetchNucleiVulnerabilityASNOverview = async () => {
    try {
        let queryURLParams = new URLSearchParams();

        const response = await fetch(`${baseApiUrl}/vulnerabilities/nuclei/asnoverview`,
            {
                method: 'GET',
                credentials: 'include'
            }
        );
        if (!response.ok) {
            throw new Error('Failed getting notes')
        }
        const data = await response.json();
        return data
    } catch (error) {
        console.error('Error fetching history data:', error);
    }
};

export const fetchTechnologyData = async () => {
    try {
        let queryURLParams = new URLSearchParams();
        const api_endpoint = `${baseApiUrl}/technology-suggestions`


        const response = await fetch(api_endpoint,
            {
                method: 'GET',
                credentials: 'include'
            }
        );
        if (!response.ok) {
            throw new Error('Failed getting technology data')
        }
        const data = await response.json();
        return data
        // Handle the received history data
        // You might want to store this in a state or handle it differently
    } catch (error) {
        console.error('Error fetching technology data:', error);
    }
};

export const getDomainSuggestions = async (startingWith, filterType="startswith") => {
    try {
        let url;
        if (filterType === "startswith") {
            url = `${baseApiUrl}/domain/search?startswith=${encodeURIComponent(startingWith)}`
        }
        else if (filterType === "contains") {
            url = `${baseApiUrl}/domain/search?contains=${encodeURIComponent(startingWith)}`
        }
        else{
            throw new Error('Invalid filter type')
        }

        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include'
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error searching assets')
    }
}

export const fetchHistoryData = async (rowId) => {
            try {
                const response = await fetch(`${baseApiUrl}/assets/${rowId}/history`,
                    {
                            method: 'GET',
                            credentials: 'include'
                        }
                    );
                if (!response.ok)
                {
                    throw new Error('Unable to get history data');
                }
                const data = await response.json();
                return data;

            } catch (error) {
                console.error('Error fetching history data:', error);
            }
        };

export const getAssetSuggestions = async (startingWith, filterType = "startswith") => {
    try {
        let url;
        if (filterType === "startswith") {
            url = `${baseApiUrl}/assets/search?startswith=${startingWith}`;
        } else if (filterType === "contains") {
            url = `${baseApiUrl}/assets/search?contains=${startingWith}`;
        } else {
            throw new Error('Invalid filter type');
        }

        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include'
        });
        if (!response.ok) {
            throw new Error('Failed to fetch asset suggestions');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching asset suggestions:', error);
        return [];
    }
};