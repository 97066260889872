import { Input } from '@/components/ui/input';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';
import DateTimeRangePicker from '../../../pages/Investigation/DateTimeRangePicker';
interface TextWithDateInputProps {
  ipFilter: string | null;
  fromDate: string | null;
  toDate: string | null;
  quickSelect: string | null;
  handleIpFilterChange: (value: string | null) => void;
  handleDateRangeChange: (value: DateRange) => void;
  handleQuickSelectChange: (value: string | null) => void;
}

export default function TextWithDateInput({
  ipFilter,
  fromDate,
  toDate,
  quickSelect,
  handleIpFilterChange,
  handleDateRangeChange,
  handleQuickSelectChange,
}: TextWithDateInputProps) {
  const [search, setSearch] = useState<string>(ipFilter);

  const handleSearchChange = (value: string) => {
    if (value.length > 0) {
      setSearch(value);
    } else {
      setSearch('');
      handleIpFilterChange(null);
    }
  };
  return (
    <div className="flex items-start w-fit">
      <Input
        type="text"
        className="min-w-[300px]"
        placeholder="Type IP address or CIDR (e.g. 10.0.0.0/24)"
        onChange={(e) => handleSearchChange(e.target.value)}
        value={search}
        showClearButton={!!search}
        onClear={() => {
          setSearch('');
          handleIpFilterChange(null);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleIpFilterChange(search);
          }
        }}
      />
      <DateTimeRangePicker
        dateRange={{
          from: fromDate ? new Date(fromDate) : undefined,
          to: toDate ? new Date(toDate) : undefined,
        }}
        setDateRange={handleDateRangeChange}
        quickSelect={quickSelect}
        setQuickSelect={handleQuickSelectChange}
      />
    </div>
  );
}
