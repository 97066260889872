import fetchWrapper from '@/lib/fetchWrapper';
import { ASNData } from '@/types/api/response/global';
import {
  PortChangesResponse,
  PortStatsResponse,
  VulnerabilitiesCount,
  VulnerabilitiesProductsAndVendorsList,
  VulnerabilitiesScanResponse,
  VulnerabilitiesScanResultsResponse,
  VulnerabilityASNOverview,
  VulnerabilityResponseList,
} from '@/types/api/response/investigations';

const endpoint = '/vulnoverview';

export type GetVulnerabilitiesParams = {
  page?: number;
  sort_by?: string;
  search?: string;
  http_asset?: number;
  domainname?: string;
  domainname_filter_operator?: string;
  asn?: string;
  cve_name?: string;
  cve_name_filter_operator?: string;
  product?: string;
  product_filter_operator?: string;
  vendor?: string;
  vendor_filter_operator?: string;
  severity?: string;
  severity_filter_operator?: string;
  first_observed?: string;
  first_observed_filter_operator?: string;
  last_observed?: string;
  last_observed_filter_operator?: string;
};

type GetVulnerabilitiesScanParams = {
  cveFilterField: string;
  cveFilterOperator: string;
  page: number;
};

export type GetVulnerabilitiesScanResultsParams = {
  page: number;
  asn?: string;
  http_asset?: number | null;
  domainname?: string;
  filter_type?: string;
  severity?: string;
};

export const getVulnerabilitiesApi = async (
  params: GetVulnerabilitiesParams,
): Promise<VulnerabilityResponseList> => {
  const paramsBody: Record<string, string | number | undefined> = {
    page: params.page || 1,
  };

  if (params.sort_by) paramsBody.sort_by = params.sort_by;
  if (params.search) paramsBody.search = params.search;
  if (params.http_asset) paramsBody.http_asset = params.http_asset;

  if (params.domainname) {
    paramsBody.domainname = params.domainname;
    if (
      params.domainname_filter_operator &&
      ['startswith', 'contains', 'equals'].includes(params.domainname_filter_operator)
    ) {
      paramsBody.domainname_filter_operator = params.domainname_filter_operator;
    }
  }

  if (params.asn) paramsBody.asn = params.asn;

  if (params.cve_name) {
    paramsBody.cve_name = params.cve_name;
    if (
      params.cve_name_filter_operator &&
      ['contains', 'equals'].includes(params.cve_name_filter_operator)
    ) {
      paramsBody.cve_name_filter_operator = params.cve_name_filter_operator;
    }
  }

  if (params.product) {
    paramsBody.product = params.product;
    if (params.product_filter_operator && params.product_filter_operator === 'contains') {
      paramsBody.product_filter_operator = params.product_filter_operator;
    }
  }

  if (params.vendor) {
    paramsBody.vendor = params.vendor;
    if (params.vendor_filter_operator && params.vendor_filter_operator === 'contains') {
      paramsBody.vendor_filter_operator = params.vendor_filter_operator;
    }
  }

  if (params.severity) {
    paramsBody.severity = params.severity;
    if (
      params.severity_filter_operator &&
      ['equals', 'less_than', 'greater_than'].includes(params.severity_filter_operator)
    ) {
      paramsBody.severity_filter_operator = params.severity_filter_operator;
    }
  }

  if (params.first_observed) {
    paramsBody.first_observed = params.first_observed;
    if (
      params.first_observed_filter_operator &&
      ['older_than', 'newer_than'].includes(params.first_observed_filter_operator)
    ) {
      paramsBody.first_observed_filter_operator = params.first_observed_filter_operator;
    }
  }

  if (params.last_observed) {
    paramsBody.last_observed = params.last_observed;
    if (
      params.last_observed_filter_operator &&
      ['older_than', 'newer_than'].includes(params.last_observed_filter_operator)
    ) {
      paramsBody.last_observed_filter_operator = params.last_observed_filter_operator;
    }
  }
  const response = await fetchWrapper.get<VulnerabilityResponseList>(endpoint, {
    params: paramsBody,
  });
  return response.data;
};

export const getVulnerabilitiesCountApi = async (): Promise<VulnerabilitiesCount> => {
  const response = await fetchWrapper.get<VulnerabilitiesCount>('/vulncount');
  return response.data;
};

export const getVulnerabilityASNOverviewApi = async (): Promise<VulnerabilityASNOverview[]> => {
  const response = await fetchWrapper.get<VulnerabilityASNOverview[]>(
    '/vulnerabilities/asnoverview',
  );
  return response.data;
};

export const getVulnerabilitiesScanApi = async ({
  cveFilterField,
  cveFilterOperator,
  page,
}: GetVulnerabilitiesScanParams): Promise<VulnerabilitiesScanResponse> => {
  const response = await fetchWrapper.get<VulnerabilitiesScanResponse>('/vulnscan/nucleiscan', {
    params: {
      cve_filter_field: cveFilterField,
      cve_filter_operator: cveFilterOperator,
      page,
    },
  });
  return response.data;
};

export const getVulnerabilitiesScanResultsApi = async ({
  page,
  asn,
  http_asset,
  domainname,
  filter_type,
  severity,
}: GetVulnerabilitiesScanResultsParams): Promise<VulnerabilitiesScanResultsResponse> => {
  const params: GetVulnerabilitiesScanResultsParams = {
    page,
  };
  if (asn) {
    params.asn = asn;
  }
  if (http_asset !== null && http_asset !== undefined) {
    params.http_asset = http_asset;
  } else {
    delete params.http_asset;
  }
  if (domainname) {
    params.domainname = domainname;
  }
  if (filter_type) {
    params.filter_type = filter_type;
  }
  if (severity) {
    params.severity = severity;
  }
  const response = await fetchWrapper.get<VulnerabilitiesScanResultsResponse>(
    '/vulnscan/nucleiscanresults',
    {
      params,
    },
  );
  return response.data;
};

export const getVulnerabilitiesNucleiASNOverviewApi = async (): Promise<ASNData[]> => {
  const response = await fetchWrapper.get<ASNData[]>('/vulnerabilities/nuclei/asnoverview');
  return response.data;
};

export interface GetPortScanChangesParams {
  page: number;
  ports?: number[];
  services?: string[];
  versions?: string[];
  state?: string[];
  from_date?: string;
  to_date?: string;
  ipaddress?: string;
}

export type GetPortStatsParams = {
  from_date?: string;
  to_date?: string;
  page?: number;
};

export const getPortScanChangesApi = async ({
  page,
  state,
  from_date,
  to_date,
  ipaddress,
  ports,
  services,
  versions,
}: GetPortScanChangesParams): Promise<PortChangesResponse> => {
  const params: Record<string, string | number> = { page };

  if (state) params.state = state.join(',');
  if (from_date) params.from_date = from_date;
  if (to_date) params.to_date = to_date;
  if (ipaddress) params.ipaddress = ipaddress;
  if (ports) params.ports = ports.join(',');
  if (services) params.services = services.join(',');
  if (versions) params.versions = versions.join(',');
  const response = await fetchWrapper.get<PortChangesResponse>('/port-scan-changes', {
    params,
  });
  return response.data;
};

export const fetchPortStats = async ({
  from_date,
  to_date,
  page,
}: GetPortStatsParams): Promise<PortStatsResponse> => {
  const params: Record<string, string | number> = { page };
  if (from_date) params.from_date = from_date;
  if (to_date) params.to_date = to_date;
  const response = await fetchWrapper.get<PortStatsResponse>('/port-scan-stats', { params });
  return response.data;
};

export const getVulnerabilitiesProductsAndVendorsListApi =
  async (): Promise<VulnerabilitiesProductsAndVendorsList> => {
    const response = await fetchWrapper.get<VulnerabilitiesProductsAndVendorsList>(
      '/vuln-product-suggestions',
    );
    return response.data;
  };
