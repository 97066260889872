import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Root from './v2/root';

(async () => {
  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Suspense fallback={<div></div>}>
        <Root />
      </Suspense>
    </React.StrictMode>,
  );
})();
