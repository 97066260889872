import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ImageRenderer from '../components/ImageRenderer';

const CodeBlock = ({node, inline, className, children, ...props}) => {
  const match = /language-(\w+)/.exec(className || '')
  return !inline && match ? (
    <pre style={{ background: 'black', color: 'offwhite' }} {...props}>
      <code className={className} children={String(children).replace(/\n$/, '')} />
    </pre>
  ) : (
    <code className={className} {...props} />
  )
}

const Markdown = ({ children, imageSize="medium", ...props }) => {
  return (
      <ReactMarkdown
          children={children}
          remarkPlugins={[remarkGfm]}
          components={{
              img: (props) => <ImageRenderer {...props} size={imageSize} />
          }}
          {...props}
      />
  );
};

export default Markdown;
