import { ServiceResponse } from '@/types/api/response/services';
import { ApiResponse, fetchWrapper } from '../lib/fetchWrapper';

type ServiceParams = {
  serviceversion?: string;
  ports?: string;
  services?: string;
  ipaddress?: string;
  state?: string;
  cpe?: string;
  page?: number;
};

export const getServices = async (params: ServiceParams): Promise<ServiceResponse> => {
  const response = await fetchWrapper.get<ServiceResponse>('/portservice', { params });
  return response.data;
};

export const addIPAddress = async (data: {
  ipaddress: string[];
  notes: string;
}): Promise<ApiResponse<void>> => {
  const response = await fetchWrapper.post<ApiResponse<void>>('/ipaddress/', data);
  return response.data;
};
