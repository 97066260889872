import React from 'react';

interface LoadingSpinnerProps {
  size?: 'small' | 'medium' | 'large';
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = 'medium' }) => {
  const sizeClasses = {
    small: 'w-4 h-4',
    medium: 'w-8 h-8',
    large: 'w-12 h-12',
  };

  return (
    <>
      <style>
        {`
          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
      <div className="flex items-center justify-center">
        <div
          className={`${sizeClasses[size]} border-[3px] border-blue-500/30 border-t-blue-500 rounded-full`}
          style={{
            animation: 'spin 1s linear infinite',
            borderRadius: '100%',
          }}
          role="status"
          aria-label="Loading"
        />
      </div>
    </>
  );
};

export default LoadingSpinner;
