import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type SerializableValue = string | number | boolean | object | null | undefined;

/**
 * A custom hook that syncs state with a URL search parameter with improved type safety and flexibility.
 *
 * @param key The search parameter key to sync with.
 * @param defaultValue The default value for the state.
 * @param options Optional configuration for parsing and serialization.
 * @returns A tuple containing the current state and a setter function.
 */
function useParamState<T extends SerializableValue>(
  key: string,
  defaultValue: T,
  options: {
    parse?: (value: string) => T;
    serialize?: (value: T) => string;
    resetToDefault?: boolean;
    preserveOtherParams?: boolean;
  } = {},
): [T, (newValue: Partial<T> | T | null) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key);

  // Default parse and serialize methods
  const defaultParse = useCallback((value: string) => {
    try {
      return JSON.parse(value) as T;
    } catch {
      return value as T;
    }
  }, []);

  const defaultSerialize = useCallback((value: T) => {
    return value === null || value === undefined
      ? ''
      : typeof value === 'string'
        ? value
        : JSON.stringify(value);
  }, []);

  // Use custom or default parse/serialize methods
  const parse = options.parse || defaultParse;
  const serialize = options.serialize || defaultSerialize;

  // Initial state computation
  const [state, setState] = useState<T>(() => {
    if (paramValue === null) {
      return defaultValue;
    }
    return parse(paramValue);
  });

  const setParamState = useCallback(
    (newValue: Partial<T> | T | null) => {
      // Handle null/undefined cases
      if (newValue === null || newValue === undefined) {
        const shouldResetToDefault = options.resetToDefault ?? true;

        setState(shouldResetToDefault ? defaultValue : (undefined as T));

        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete(key);
        setSearchParams(newSearchParams, { replace: true });
        return;
      }

      // Handle object updates
      const updatedValue =
        typeof newValue === 'object' &&
        newValue !== null &&
        !Array.isArray(newValue) &&
        typeof state === 'object'
          ? { ...(state as object), ...(newValue as object) }
          : newValue;

      setState(updatedValue as T);

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(key, serialize(updatedValue as T));
      setSearchParams(newSearchParams, { replace: true });
    },
    [key, searchParams, setSearchParams, state, defaultValue, serialize, options.resetToDefault],
  );

  return [state, setParamState];
}

export default useParamState;
