import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

const AnonymousLayout = () => {
  return (
    <div className="anonymous-layout">
      <main className="content">
        <Suspense fallback={<div></div>}>
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
};

export default AnonymousLayout;
