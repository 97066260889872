import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import MarkdownEditor from '@/components/Markdown/MarkdownEditor';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { createIssue } from '@/api/issues.api';
import { UserWithOrganization } from '@/types/api/response/user';
import { Edit } from 'lucide-react';
import { ResourceSelector } from './ResourceSelector';
import { useState } from 'react';
import { NVDVulnerability } from '@/types/api/response/nvd';
import { VulnerabilitySelector } from './VulnerabilitySelector';

// TODO: Is it a good place to store this definition?
const newIssueSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  description: z.string().min(1, 'Description is required'),
  severity: z.enum(['informational', 'low', 'medium', 'high', 'critical']),
  affected_resource_model_type: z.string().nullable(),
  affected_resource_object_id: z.number().nullable(),
  vuln_model_type: z.string().nullable(),
  vuln_object_id: z.number().nullable(),
  assignedToId: z.string().optional(),
});

export type NewIssuePayload = z.infer<typeof newIssueSchema>;

type NewIssueProps = {
  users: UserWithOrganization[];
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onSubmit: (data: NewIssuePayload) => Promise<void>;
};

export const NewIssue = ({ users, isOpen, onOpenChange, onSubmit }: NewIssueProps) => {
  const [selectedResource, setSelectedResource] = useState<any>(null);
  const [selectedResourceType, setSelectedResourceType] = useState<string | null>(null);
  const [selectedVulnerabilityType, setSelectedVulnerabilityType] = useState<string | null>(null);
  const [selectedVulnerability, setSelectedVulnerability] = useState<NVDVulnerability | null>(null);

  const form = useForm<NewIssuePayload>({
    resolver: zodResolver(newIssueSchema),
    defaultValues: {
      title: '',
      description: '',
      severity: 'informational',
      affected_resource_model_type: null,
      affected_resource_object_id: null,
      vuln_model_type: null,
      vuln_object_id: null,
      assignedToId: '',
    },
  });

  const handleResourceSelect = (resourceType: string | null, resource: any) => {
    if (resourceType && !resource) {
      setSelectedResourceType(resourceType);
      form.setValue('affected_resource_model_type', resourceType);
      return;
    }

    setSelectedResourceType(resourceType);
    setSelectedResource(resource);
    form.setValue('affected_resource_model_type', resourceType);
    form.setValue('affected_resource_object_id', resource?.id);
  };

  const handleVulnerabilitySelect = (
    vulnerabilityType: string | null,
    vulnerability: NVDVulnerability | null,
  ) => {
    setSelectedVulnerabilityType(vulnerabilityType);
    form.setValue('vuln_model_type', vulnerabilityType);

    if (vulnerability) {
      setSelectedVulnerability(vulnerability);
      form.setValue('vuln_object_id', vulnerability.id);
    }
  };

  const handleResourceClear = () => {
    setSelectedResource(null);
    setSelectedResourceType(null);
    form.setValue('affected_resource_model_type', null);
    form.setValue('affected_resource_object_id', null);
  };

  const handleSubmit = async (data: NewIssuePayload) => {
    try {
      await createIssue(data);
      await onSubmit(data);
      form.reset();
      setSelectedResource(null);
      setSelectedResourceType(null);
    } catch (error) {
      console.error('Failed to create issue:', error);
    }
  };

  const handleOpenChange = (open: boolean) => {
    onOpenChange(open);
    if (!open) {
      setSelectedResource(null);
      setSelectedResourceType(null);
      setSelectedVulnerability(null);
      setSelectedVulnerabilityType(null);
      form.reset();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="sm:max-w-[600px] h-[90vh] overflow-y-auto">
        <DialogHeader className="pb-2">
          <DialogTitle className="text-[#f05941] font-semibold text-lg">New Issue</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col h-full -mt-4">
            <div className="space-y-4 flex-1">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-foreground flex items-center group">
                      Title <span className="text-red-500">*</span>
                      <Edit className="ml-2 text-gray-500 group-hover:visible invisible" />
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Enter a clear, concise title"
                        className="text-foreground"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="affected_resource_model_type"
                render={() => (
                  <FormItem>
                    <FormLabel className="text-foreground">
                      {selectedResource ? selectedResourceType : ''}
                    </FormLabel>
                    <ResourceSelector
                      selectedResourceType={selectedResourceType}
                      selectedResource={selectedResource}
                      onResourceSelect={handleResourceSelect}
                      onClear={handleResourceClear}
                    />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="vuln_model_type"
                render={() => (
                  <FormItem>
                    <FormLabel className="text-foreground">
                      {selectedVulnerability ? selectedVulnerabilityType : ''}
                    </FormLabel>
                    <VulnerabilitySelector
                      selectedVulnerabilityType={selectedVulnerabilityType}
                      selectedVulnerability={selectedVulnerability}
                      onVulnerabilitySelect={handleVulnerabilitySelect}
                      onClear={() => {
                        setSelectedVulnerability(null);
                        setSelectedVulnerabilityType(null);
                        form.setValue('vuln_model_type', null);
                        form.setValue('vuln_object_id', null);
                      }}
                    />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-foreground flex items-center group">
                      Description <span className="text-red-500">*</span>
                      <Edit className="ml-2 text-gray-500 group-hover:visible invisible" />
                    </FormLabel>
                    <FormControl>
                      <MarkdownEditor
                        markdown={field.value}
                        placeholder="Provide detailed information about the issue"
                        className="h-[250px]"
                        onChange={field.onChange}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="severity"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-foreground">
                      Severity Level <span className="text-red-500">*</span>
                    </FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger className="w-full border rounded-md bg-background text-foreground">
                          <SelectValue placeholder="Choose the severity level" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem
                          key="informational"
                          value="informational"
                          className="text-foreground"
                        >
                          Informational
                        </SelectItem>
                        <SelectItem key="low" value="low" className="text-foreground">
                          Low
                        </SelectItem>
                        <SelectItem value="medium" className="text-foreground">
                          Medium
                        </SelectItem>
                        <SelectItem value="high" className="text-foreground">
                          High
                        </SelectItem>
                        <SelectItem value="critical" className="text-foreground">
                          Critical
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="assignedToId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-foreground">Assigned To</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger className="w-full border rounded-md bg-background text-foreground">
                          <SelectValue
                            placeholder="Select a user (optional)"
                            className="text-foreground"
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {users.map((user) => (
                          <SelectItem
                            key={user.id}
                            value={user.id.toString()}
                            className="text-foreground"
                          >
                            {user.user.username}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
            </div>
            <Button type="submit" className="w-full mt-6">
              Create Issue
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
