import { Button } from '@/components/ui/button';
import { AssigneeSelector } from '@/pages/Issues/AssigneeSelector';
import { NewIssue } from '@/pages/Issues/NewIssue';
import { StatusSelector } from '@/pages/Issues/StatusSelector';
import { Issue, ISSUE_STATUSES } from '@/types/api/response/issues';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import {
  getIssuesQuery,
  useInvalidateIssuesQuery,
  useUpdateIssueMutation,
} from '@/v2/queries/issues';
import { getUsersQuery } from '@/v2/queries/users';
import { formatTimestamp } from '@/v2/utils';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { AlertCircle, AlertTriangle, FileWarning, Info, Siren } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Issues = () => {
  const [page, setPage] = useState(1);
  const [isNewIssueOpen, setIsNewIssueOpen] = useState(false);

  const navigate = useNavigate();

  const { data, error, isPending } = useQuery(getIssuesQuery(page));
  const { mutate: updateIssue } = useUpdateIssueMutation();
  const invalidateIssuesQuery = useInvalidateIssuesQuery();
  const { data: users } = useQuery(getUsersQuery());

  type IssueStatus = keyof typeof ISSUE_STATUSES;

  const getSeverityDisplay = (
    severity: Issue['severity'],
    showLabel: boolean = false,
    size: number = 5,
  ) => {
    const icons = {
      critical: <Siren className={`w-${size} h-${size} text-red-500`} fillOpacity={0.2} />,
      high: <AlertCircle className={`w-${size} h-${size} text-red-500`} fillOpacity={0.2} />,
      medium: <AlertTriangle className={`w-${size} h-${size} text-yellow-500`} fillOpacity={0.2} />,
      low: <FileWarning className={`w-${size} h-${size} text-green-500`} fillOpacity={0.2} />,
      informational: <Info className={`w-${size} h-${size} text-blue-500`} fillOpacity={0.2} />,
    };

    return (
      <div className="flex items-center space-x-2">
        {icons[severity]}
        {showLabel && <span className="capitalize">{severity}</span>}
      </div>
    );
  };

  const handleStatusChange = (issue: Issue, status: IssueStatus) => {
    updateIssue({
      issueId: issue.id,
      updates: {
        ...issue,
        status,
      },
    });
  };

  const getStatusDisplay = (issue: Issue) => (
    <StatusSelector
      currentStatus={issue.status}
      onStatusChange={(status) => handleStatusChange(issue, status as IssueStatus)}
      triggerClassName="status-popover flex justify-start"
    />
  );

  const getAffectedResourceDisplay = (issue: Issue) => {
    switch (issue.affected_resource_model_type) {
      case 'IPAddressV4':
        return (issue.affected_resource as { ipaddress?: string })?.ipaddress ?? '-';
      case 'HTTPAsset':
        return (issue.affected_resource as { hostname?: string })?.hostname ?? '-';
      case 'Domains':
        return (issue.affected_resource as { domainname?: string })?.domainname ?? '-';
      default:
        return '-';
    }
  };

  const handleUpdate = (issue: Issue, updates: Partial<Issue>) => {
    updateIssue({
      issueId: issue.id,
      updates: {
        ...issue,
        ...updates,
      },
    });
  };

  const columnHelper = createColumnHelper<Issue>();

  const columns: ColumnDef<Issue>[] = [
    columnHelper.accessor('severity', {
      header: 'Severity',
      cell: ({ row }) => getSeverityDisplay(row.original.severity),
    }),
    columnHelper.accessor('title', {
      header: 'Title',
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: ({ row }) => getStatusDisplay(row.original),
    }),
    columnHelper.accessor((row) => row.affected_resource_object_id, {
      header: 'Affected Resource',
      cell: ({ row }) => getAffectedResourceDisplay(row.original),
    }),
    columnHelper.accessor('assigned_to.user.username', {
      header: 'Assigned To',
      cell: ({ row }) => (
        <AssigneeSelector
          users={users?.entries ?? []}
          assigneeId={row.original.assigned_to?.user?.id}
          assigneeName={row.original.assigned_to?.user?.username}
          onAssigneeChange={(userId) => handleUpdate(row.original, { assigned_to_id: userId })}
          data-click-bypass="true"
        />
      ),
    }),
    columnHelper.accessor('id', {
      header: 'ID',
    }),
    columnHelper.accessor('created_at', {
      header: 'Created At',
      cell: ({ row }) =>
        formatTimestamp(row.original.created_at, {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
    }),
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleRowClick = (row: Issue) => {
    navigate(`/issues/${row.id}`);
  };

  const onSubmit = async () => {
    invalidateIssuesQuery();
    setIsNewIssueOpen(false);
  };

  return (
    <div className="p-6 max-w-[2000px] mx-auto">
      <div className="flex mb-4">
        <Button onClick={() => setIsNewIssueOpen(true)} className="bg-v2-orange text-white">
          New Issue
        </Button>
      </div>
      <DataTable
        data={data?.entries ?? []}
        columns={columns}
        currentPage={parseInt(data?.page_number ?? '1')}
        totalPages={data?.total_pages ?? 1}
        totalEntries={data?.total_count ?? 0}
        onPageChange={handlePageChange}
        error={error}
        loading={isPending}
        maxWidth="w-full"
        onRowClick={handleRowClick}
      />
      <NewIssue
        users={users?.entries ?? []}
        isOpen={isNewIssueOpen}
        onOpenChange={setIsNewIssueOpen}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default Issues;
