import { Input } from '@/components/ui/input';
import { MultiSelect } from '@/components/ui/multi-select';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { DateRange } from 'react-day-picker';
import CVESearch from '../CVESearch/CVESearch';
import DateInputComponent from '../DateInputComponent/DateInputComponent';
import GenericInputWithSelect from '../GenericInputWithSelect/GenericInputWithSelect';
import GenericSelectWithOperator from '../GenericSelectWithOperator/GenericSelectWithOperator';
import SeveritySearch from '../SeveritySearch/SeveritySearch';
import TextWithDateInput from '../TextWithDateInput/TextWithDateInput';
import { AvailableFilter } from './UniversalFilter';
interface FilterRendererProps {
  filter: AvailableFilter;
  onUpdate: (filter: AvailableFilter, value: string | string[] | DateRange) => void;
  onClear: (filter: AvailableFilter) => void;
  value: string;
}

export function FilterRenderer({ filter, onUpdate, onClear }: FilterRendererProps) {
  const renderFilter = () => {
    switch (filter.type) {
      case 'select':
        return (
          <Select value={filter.state as string} onValueChange={(value) => onUpdate(filter, value)}>
            <SelectTrigger>
              <SelectValue placeholder={filter.placeholder} />
            </SelectTrigger>
            <SelectContent>
              {filter.options?.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        );

      case 'multiSelect':
        return (
          <MultiSelect
            options={filter.options || []}
            onValueChange={(value) => onUpdate(filter, value)}
            value={filter.state as string[]}
            placeholder={filter.placeholder}
            className="w-fit"
          />
        );

      case 'domainSearch':
        return (
          <GenericInputWithSelect
            inputValue={filter.state as string}
            setInputValue={(value) => onUpdate(filter, value)}
            inputType={filter.valueType}
            setInputType={(value) => filter.setValueType?.(value)}
            onClear={() => onClear(filter)}
            placeholder={filter.placeholder}
            options={filter.options || []}
            label={filter.label}
            defaultValue={filter.defaultValue}
          />
        );

      case 'cveSearch':
        return (
          <CVESearch
            inputValue={filter.state as string}
            setInputValue={(value) => onUpdate(filter, value)}
            inputType={filter.valueType}
            setInputType={(value) => filter.setValueType?.(value)}
            onClear={() => onClear(filter)}
          />
        );

      case 'severitySearch':
        return (
          <SeveritySearch
            inputValue={filter.state as string}
            setInputValue={(value) => onUpdate(filter, value)}
            inputType={filter.valueType}
            setInputType={(value) => filter.setValueType?.(value)}
            onClear={() => onClear(filter)}
          />
        );

      case 'text-with-operator':
        return (
          <GenericInputWithSelect
            inputValue={filter.state as string}
            setInputValue={(value) => onUpdate(filter, value)}
            inputType={filter.valueType}
            setInputType={(value) => filter.setValueType?.(value)}
            onClear={() => onClear(filter)}
            placeholder={filter.placeholder}
            options={filter.options || []}
            label={filter.label}
            defaultValue={filter.defaultValue}
          />
        );
      case 'text':
        return (
          <Input
            placeholder={filter.placeholder}
            className="min-w-[200px] w-full"
            value={filter.state as string}
            type="text"
            onChange={(e) => onUpdate(filter, e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                filter.onSearch?.();
              }
            }}
            showClearButton={!!(filter.state as string)}
            onClear={() => onUpdate(filter, '')}
          />
        );
      case 'text-with-date':
        return (
          <TextWithDateInput
            ipFilter={filter.state as string}
            fromDate={filter.fromDate as string}
            toDate={filter.toDate as string}
            quickSelect={filter.quickSelect as string}
            handleIpFilterChange={(value) => onUpdate(filter, value)}
            handleDateRangeChange={(value) => onUpdate(filter, value)}
            handleQuickSelectChange={(value) => onUpdate(filter, value)}
          />
        );

      case 'date-input':
        return (
          <DateInputComponent
            fromDate={filter.fromDate as string}
            toDate={filter.toDate as string}
            quickSelect={filter.state as string}
            setDateRange={filter.setDateRange}
            setQuickSelect={(value) => onUpdate(filter, value)}
          />
        );

      case 'select-with-operator':
        return (
          <GenericSelectWithOperator
            options={filter.options || []}
            valueType={filter.state as string}
            setValueType={filter.setState}
            onClear={() => onClear(filter)}
            operator={filter.valueType as string}
            setOperator={filter.setValueType}
            operatorOptions={filter.operatorOptions || []}
            label={filter.label}
            placeholder={filter.placeholder}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="w-full p-2">
      <div className="flex items-center gap-1 w-full">{renderFilter()}</div>
    </div>
  );
}
