import { useState } from 'react';

interface ImageWithModalProps {
  imageUrl: string;
  hostname: string;
}

export const ImageWithModal = ({ imageUrl, hostname }: ImageWithModalProps) => {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  return imageUrl ? (
    <>
      <img
        src={imageUrl}
        alt={`Screenshot of ${hostname}`}
        className="rounded-lg shadow-md mx-auto max-h-[600px] aspect-[9/16] cursor-pointer hover:opacity-90 transition-opacity"
        onClick={() => setIsImageModalOpen(true)}
      />
      {isImageModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-80 z-50 flex items-center justify-center p-4 cursor-pointer"
          onClick={() => setIsImageModalOpen(false)}
        >
          <div className="relative max-w-[90vw] max-h-[90vh]">
            <img
              src={imageUrl}
              alt={`Screenshot of ${hostname}`}
              className="max-w-full max-h-[90vh] object-contain"
            />
            <button
              className="absolute top-4 right-4 text-white hover:text-gray-300 bg-black bg-opacity-50 rounded-full p-2"
              onClick={() => setIsImageModalOpen(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </>
  ) : (
    <div className="rounded-lg shadow-md mx-auto max-h-[600px] aspect-[9/16] bg-gray-800 flex items-center justify-center">
      <p className="text-gray-400">No screenshot available</p>
    </div>
  );
};
