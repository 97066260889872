import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type UseSyncedParams<T> = {
  synchedState: T;
  setSynchedState: (newState: Partial<T>) => void;
};

type PrimitiveValue = string | number;
type SyncedValue = PrimitiveValue | PrimitiveValue[] | undefined | null;

// Helper functions to parse and stringify values for URL parameters
const parseValue = (value: string, defaultValue: SyncedValue): SyncedValue => {
  if (Array.isArray(defaultValue)) {
    return value === ''
      ? []
      : value.split(',').map((v) => (typeof defaultValue[0] === 'number' ? Number(v) : v));
  }
  return typeof defaultValue === 'number' ? Number(value) : value;
};

const stringifyValue = (value: SyncedValue): string => {
  if (Array.isArray(value)) {
    return value.join(',');
  }
  return String(value);
};

export function useSyncedParams<T extends Record<string, SyncedValue>>(
  defaultState: T,
): UseSyncedParams<T> {
  const [searchParams, setSearchParams] = useSearchParams();

  const [synchedState, setSynchedStateInternal] = useState<T>(() => {
    const initialState = { ...defaultState };

    Object.keys(defaultState).forEach((key) => {
      const paramValue = searchParams.get(key);
      if (paramValue !== null) {
        initialState[key as keyof T] = parseValue(paramValue, defaultState[key]) as T[keyof T];
      }
    });

    return initialState;
  });

  // Update local state when URL params change
  useEffect(() => {
    const updatedState: Partial<T> = {};
    let hasChanges = false;

    Object.keys(defaultState).forEach((key) => {
      const paramValue = searchParams.get(key);
      const currentValue = synchedState[key];
      const defaultValue = defaultState[key];

      const normalizedParamValue =
        paramValue !== null ? parseValue(paramValue, defaultValue) : defaultValue;

      if (JSON.stringify(normalizedParamValue) !== JSON.stringify(currentValue)) {
        updatedState[key as keyof T] = normalizedParamValue as T[keyof T];
        hasChanges = true;
      }
    });

    if (hasChanges) {
      setSynchedStateInternal((prevState) => ({ ...prevState, ...updatedState }));
    }
  }, [searchParams, synchedState, defaultState]);

  // Function to update state and URL
  const setSynchedState = useCallback(
    (newState: Partial<T>) => {
      setSynchedStateInternal((prevState) => ({ ...prevState, ...newState }));

      const updatedParams = new URLSearchParams(searchParams);

      Object.keys(newState).forEach((key) => {
        const value = newState[key as keyof T];
        if (value !== undefined && value !== null) {
          updatedParams.set(key, stringifyValue(value));
        } else {
          updatedParams.delete(key);
        }
      });

      setSearchParams(updatedParams);
    },
    [searchParams, setSearchParams],
  );

  return { synchedState, setSynchedState };
}
