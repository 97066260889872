import { Form, FormControl, FormDescription, FormField, FormItem } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useAuth } from '@/Helpers/AuthContext';
import AuthBackground from '@/v2/components/AuthBackground/AuthBackground';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';

const formSchema = z.object({
  username: z.string().min(2, { message: 'Username must be at least 2 characters' }).max(50),
  password: z.string().min(8, { message: 'Password must be at least 8 characters' }),
});

const Login = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login, isAuthenticated } = useAuth();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/issues');
    }
  }, [isAuthenticated, navigate]);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    const { username, password } = data;
    try {
      const response = await login(username, password);
      if (!response.ok) {
        console.log('Failed to login');
        const tempError = await response.json();
        let errorMessage = '';
        for (const field in tempError.error) {
          if (field !== '0') {
            errorMessage += field.toUpperCase();
          }
          if (tempError.error[field].isArray) {
            errorMessage += tempError.error[field][0];
          } else {
            errorMessage += ' ' + tempError.error[field];
          }
        }
        setError(errorMessage);
      } else {
        navigate('/');
        return response;
      }
    } catch (error) {
      console.error('Failed to login', error);
      setError('Failed to login. Please try again.');
    }
  };

  return (
    <div className="relative flex justify-center items-center max-h-screen bg-v2-black overflow-hidden gap-16">
      <div className="relative flex justify-between items-center min-h-screen bg-v2-black overflow-hidden min-w-[500px]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full max-w-[400px]">
            <h1 className="text-v2-white text-2xl mb-8 uppercase tracking-wider text-center font-lato font-bold">
              Log in
            </h1>

            {error && (
              <div className="mb-4 p-3 rounded-md bg-red-500/10 border border-red-500 text-red-500">
                {error}
              </div>
            )}

            <div className="mb-6">
              <FormField
                control={form.control}
                name="username"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="text"
                        required
                        placeholder="Username"
                        autoComplete="username"
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>Enter your username</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-2">
              <FormField
                control={form.control}
                name="password"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        {...field}
                        className={`w-full py-3 px-3 bg-transparent border border-v2-white/20 rounded-md 
                          text-v2-white text-base outline-none transition-colors placeholder:text-v2-white/60
                          focus:border-v2-white/50 ${fieldState.error ? 'border-red-500' : ''}`}
                        type="password"
                        required
                        placeholder="Password"
                        autoComplete="current-password"
                        onWheel={(e) => e.currentTarget.blur()}
                      />
                    </FormControl>
                    {fieldState.error ? (
                      <FormDescription className="text-red-500">
                        {fieldState.error.message}
                      </FormDescription>
                    ) : (
                      <FormDescription>Enter your password</FormDescription>
                    )}
                  </FormItem>
                )}
              />
            </div>

            <div className="flex justify-end mb-6">
              <Link
                to="/forgot-password"
                className="text-v2-white/50 text-sm hover:text-v2-orange transition-colors"
              >
                Forgot Password?
              </Link>
            </div>

            <button
              type="submit"
              className="w-full py-3 px-3 bg-v2-orange text-v2-white text-base rounded-md
                   cursor-pointer transition-colors hover:bg-v2-orange/90 font-poppins font-semibold"
            >
              Log in
            </button>

            <p className="text-v2-white/50 text-center mt-6 text-sm">
              Don&apos;t have an account yet?
              <Link to="/register" className="text-v2-orange no-underline ml-2 hover:underline">
                Registration
              </Link>
            </p>
          </form>
        </Form>
      </div>
      <AuthBackground />
    </div>
  );
};

export default Login;
