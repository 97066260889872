import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import AssetSources from '@/GridComponents/AssetSources';
import { useToast } from '@/hooks/use-toast';
import { DomainResponse } from '@/types/api/response/domains';
import { DataTable } from '@/v2/components/DataTable/DataTable';
import UniversalFilter, { AvailableFilter } from '@/v2/components/UniversalFilter/UniversalFilter';
import { getDomainsQuery, useAddDomain } from '@/v2/queries/domains';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { parseAsInteger, parseAsString, useQueryState } from 'nuqs';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
const formSchema = z.object({
  domain: z.string().min(1, 'Domain is required'),
  notes: z.string().optional(),
});

const Domains = () => {
  const [page, setPage] = useQueryState('page', parseAsInteger.withDefault(1));
  const [domainSearchValue, setDomainSearchValue] = useQueryState(
    'domain_search_value',
    parseAsString.withDefault(''),
  );
  const [domainSearchType, setDomainSearchType] = useQueryState(
    'domain_search_type',
    parseAsString.withDefault('startswith'),
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      domain: '',
      notes: '',
    },
  });
  const { toast } = useToast();

  const searchParamsState = domainSearchValue
    ? { domainname: domainSearchValue, filter_type: domainSearchType, page }
    : { page };

  const { data, isLoading, error } = useQuery(getDomainsQuery(searchParamsState));

  const { mutate: addDomain } = useAddDomain({
    onSuccess: () => {
      form.reset();
      toast({
        title: 'Domain added successfully',
      });
    },
    onError: () => {
      toast({
        title: 'Failed to add domain',
      });
    },
  });

  const handleSelectDomains = (value: string | string[]) => {
    setDomainSearchValue(value as string);
    setPage(1);
  };

  const handleSelectDomainType = (value: string) => {
    setDomainSearchType(value);
    setPage(1);
  };

  const columns: ColumnDef<DomainResponse['entries'][0]>[] = [
    {
      header: 'Domain',
      accessorKey: 'domainname',
      cell: ({ row }) => {
        return (
          <div
            onClick={() => handleSelectDomains(row.original.domainname)}
            className="cursor-pointer hover:underline"
            title="Click to select domain"
          >
            {row.original.domainname}
          </div>
        );
      },
    },
    {
      header: 'DNS Record',
      accessorKey: 'dns_record',
      cell: ({ row }) => {
        const dnsRecord = row.original.dns_record;
        if (!dnsRecord) return <div>No DNS Record</div>;

        return (
          <div>
            <div>CNAME: {dnsRecord.cname || 'N/A'}</div>
            <div className="flex flex-col gap-2">
              {dnsRecord.ipaddress.map((ip) => (
                <div key={ip.id}>
                  IP: {ip.ipaddress}
                  {ip.asn && (
                    <div>
                      ASN: {ip.asn.asn} ({ip.asn.owner})
                      <br />
                      Range: {ip.asn.iprange}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        );
      },
    },
    {
      header: 'Sources',
      accessorKey: 'sources',
      cell: ({ row }) => {
        if (!row.original.sources || Object.keys(row.original.sources).length === 0)
          return <div>No Sources</div>;
        return <AssetSources entry={row.original} />;
      },
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    addDomain({
      domains: data.domain.split('\n'),
      notes: data.notes,
    });
  };

  const filters: AvailableFilter[] = [
    {
      label: 'Domain',
      state: domainSearchValue,
      setState: handleSelectDomains,
      setValueType: handleSelectDomainType,
      valueType: domainSearchType,
      type: 'domainSearch',
      key: 'domain_search_value',
      placeholder: 'Search by domain',
      onClear: () => {
        setDomainSearchValue('');
        setDomainSearchType('startswith');
      },
      options: [
        {
          label: 'Starts with',
          value: 'startswith',
        },
        {
          label: 'Contains',
          value: 'contains',
        },
      ],
    },
  ];

  const handleClearAllFilters = () => {
    setDomainSearchValue('');
    setDomainSearchType('startswith');
  };

  return (
    <div className="p-4 max-w-[2000px] mx-auto">
      <div className="flex flex-row gap-4  w-full">
        <UniversalFilter
          filters={filters}
          clearAllFilters={handleClearAllFilters}
          className="mb-4"
        />
        <Dialog>
          <DialogTrigger className="self-start">
            <Button className="self-start" variant="outline">
              Add Domain
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add Domain</DialogTitle>
              <DialogDescription>Add a new domain to the asset list.</DialogDescription>
            </DialogHeader>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <FormField
                  control={form.control}
                  name="domain"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Domain</FormLabel>
                      <FormDescription>One domain per line, no separator</FormDescription>
                      <FormControl>
                        <Textarea {...field} placeholder="Enter one domain per line" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="notes"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Notes</FormLabel>
                      <FormDescription>
                        Optional text to indicate why this domain is associated with your
                        organization.
                      </FormDescription>
                      <FormControl>
                        <Input {...field} placeholder="Any relevant notes" />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <Button className="w-full" type="submit">
                  Add Domain
                </Button>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      </div>
      <DataTable
        columns={columns}
        data={data?.entries || []}
        currentPage={page}
        totalPages={data?.total_pages}
        totalEntries={data?.total_count}
        onPageChange={handlePageChange}
        loading={isLoading}
        error={error}
        tableHeight="calc(100vh - 220px)"
      />
    </div>
  );
};

export default Domains;
