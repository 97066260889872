import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useState } from 'react';
interface SeveritySearchProps {
  inputValue: string;
  setInputValue: (value: string) => void;
  inputType: string;
  setInputType: (value: string) => void;
  onClear: () => void;
}

export default function SeveritySearch({
  inputValue,
  setInputValue,
  inputType,
  setInputType,
  onClear,
}: SeveritySearchProps) {
  const [search, setSearch] = useState<string>(inputValue);

  const handleInputTypeChange = (value: string) => {
    setInputType(value);
  };

  const handleSearchChange = (value: string) => {
    if (value.length > 0) {
      setSearch(value);
    } else {
      setSearch('');
      onClear();
    }
  };

  const onSubmit = () => {
    setInputValue(search);
  };

  const handleClear = () => {
    setSearch('');
    setInputType('greater_than');
    setInputValue('');
    onClear();
  };

  return (
    <div className="flex flex-row w-fit">
      <Button variant="outline" className="font-normal">
        Severity
      </Button>
      <Select onValueChange={handleInputTypeChange} defaultValue="greater_than" value={inputType}>
        <SelectTrigger className="w-fit">
          <SelectValue placeholder="Search by" />
        </SelectTrigger>
        <SelectContent defaultValue="greater_than">
          <SelectItem value="greater_than">Greater than</SelectItem>
          <SelectItem value="less_than">Less than</SelectItem>
        </SelectContent>
      </Select>
      <Input
        placeholder={`Search by severity`}
        value={search}
        onChange={(e) => handleSearchChange(e.target.value)}
        showClearButton={search.length > 0}
        onClear={handleClear}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSubmit();
          }
        }}
      />
    </div>
  );
}
