import { ChevronLeft, ChevronRight } from 'lucide-react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  totalEntries: number;
  onPageChange: (page: number) => void;
  prefetchChangePage?: (nextPage: number) => void;
}

export default function Component({
  currentPage,
  totalPages,
  totalEntries,
  onPageChange,
  prefetchChangePage = () => {},
}: PaginationProps) {
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  const visiblePages = pages.slice(
    Math.max(0, currentPage - 2),
    Math.min(currentPage + 1, totalPages),
  );

  const handleNumberClick = (page: number) => {
    onPageChange(page);
    if (page + 1 <= totalPages) {
      prefetchChangePage?.(page + 1);
    }
  };

  return (
    <div
      className="flex items-center justify-between px-2"
      onMouseEnter={() => prefetchChangePage?.(currentPage + 1)}
    >
      <div className="flex items-center gap-1">
        <button
          onClick={() => {
            onPageChange(currentPage - 1);
            prefetchChangePage?.(currentPage - 1);
          }}
          disabled={currentPage === 1}
          className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 w-9"
          aria-label="Previous page"
        >
          <ChevronLeft className="h-4 w-4" />
        </button>
        {visiblePages[0] > 1 && (
          <>
            <button
              onClick={() => onPageChange(1)}
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 w-9"
            >
              1
            </button>
            {visiblePages[0] > 2 && <span className="px-2">...</span>}
          </>
        )}
        {visiblePages.map((page) => (
          <button
            onMouseEnter={() => prefetchChangePage(page)}
            key={page}
            onClick={() => onPageChange(page)}
            className={`inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 w-9 ${
              currentPage === page
                ? 'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground'
                : ''
            }`}
          >
            {page}
          </button>
        ))}
        {visiblePages[visiblePages.length - 1] < totalPages && (
          <>
            {visiblePages[visiblePages.length - 1] < totalPages - 1 && (
              <span className="px-2">...</span>
            )}
            <button
              onClick={() => onPageChange(totalPages)}
              className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 w-9"
            >
              {totalPages}
            </button>
          </>
        )}
        <button
          onClick={() => {
            onPageChange(currentPage + 1);
            prefetchChangePage?.(currentPage + 1);
          }}
          disabled={currentPage === totalPages}
          className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 w-9"
          aria-label="Next page"
        >
          <ChevronRight className="h-4 w-4" />
        </button>
      </div>
      <div className="text-sm text-muted-foreground">
        Total Entries: <span className="font-semibold text-foreground">{totalEntries}</span>
      </div>
    </div>
  );
}
