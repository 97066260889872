import { fetchWrapper } from '../lib/fetchWrapper';
import { NewIssuePayload } from '../pages/Issues/NewIssue';
import { Issue, IssuesResponse } from '../types/api/response/issues';

export type UpdateIssuePayload = Partial<{
  title: string;
  description: string;
  severity: 'informational' | 'low' | 'medium' | 'high' | 'critical';
  assigned_to_id: number;
  status: string;
  affected_resource_model_type: string;
  affected_resource_object_id: number;
  vuln_model_type: string;
  vuln_object_id: number;
}>;

export const createIssue = async (issue: NewIssuePayload): Promise<Issue> => {
  const { data } = await fetchWrapper.post<Issue>('/issues', {
    severity: issue.severity,
    title: issue.title,
    description: issue.description,
    assigned_to_id: parseInt(issue.assignedToId),
    affected_resource_model_type: issue.affected_resource_model_type,
    affected_resource_object_id: issue.affected_resource_object_id,
    vuln_model_type: issue.vuln_model_type,
    vuln_object_id: issue.vuln_object_id,
  });
  return data;
};

export const getIssue = async (issueId: number): Promise<Issue> => {
  const { data } = await fetchWrapper.get<Issue>(`/issues/${issueId}`);
  return data;
};

export const getIssues = async (page: number = 1): Promise<IssuesResponse> => {
  const { data } = await fetchWrapper.get<IssuesResponse>('/issues', {
    params: {
      page: page,
    },
  });
  return data;
};

export const updateIssue = async (issueId: number, updates: UpdateIssuePayload): Promise<Issue> => {
  const { data } = await fetchWrapper.patch<Issue>(`/issues/${issueId}`, {
    ...updates,
    ...(updates.assigned_to_id && { assigned_to_id: parseInt(updates.assigned_to_id.toString()) }),
  });
  return data;
};

export default createIssue;
