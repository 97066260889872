import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { FilterIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { FilterRenderer } from './FilterRenderer';

// Improved type safety with literal types
export type FilterType =
  | 'select'
  | 'text'
  | 'multiSelect'
  | 'domainSearch'
  | 'text-with-operator'
  | 'cveSearch'
  | 'severitySearch'
  | 'text-with-date'
  | 'date-input'
  | 'select-with-operator';

export interface FilterOption {
  label: string;
  value: string;
}

export interface AvailableFilter {
  type: FilterType;
  key: string;
  label: string;
  setState?: (value: string | string[] | DateRange | string | null) => void;
  state?: string | string[] | DateRange | string | null;
  setValueType?: (value: string) => void;
  valueType?: string;
  placeholder: string;
  onSearch?: () => void;
  onClear?: () => void;
  options?: FilterOption[];
  defaultValue?: string;
  handleIpFilterChange?: (value: string | null) => void;
  handleQuickSelectChange?: (value: string | null) => void;
  fromDate?: string;
  toDate?: string;
  quickSelect?: string;
  setDateRange?: (value: DateRange) => void;
  operatorOptions?: { label: string; value: string }[];
}

interface UniversalFilterProps {
  filters: AvailableFilter[];
  clearAllFilters: () => void;
  className?: string;
}

function UniversalFilter({
  filters,
  clearAllFilters: clearAllFiltersCallback,
  className = '',
}: UniversalFilterProps) {
  const [selectedFilters, setSelectedFilters] = useState<AvailableFilter[]>([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string | null>(null);

  const handleFilterSelect = (value: string) => {
    setValue(value);
  };

  const handleSelectionClose = (open: boolean) => {
    setOpen(open);
    setValue(null);
  };

  // Memoized active filters check
  const isAnyFilterActive = useMemo(
    () =>
      selectedFilters.some((filter) => {
        if (Array.isArray(filter.state)) {
          return filter.state.length > 0;
        }
        return filter.state !== '';
      }),
    [selectedFilters],
  );

  // Keep selected filters in sync with active filters
  useEffect(() => {
    const activeFilters = filters.filter((filter) => {
      if (Array.isArray(filter.state)) {
        return filter.state.length > 0;
      }
      return filter.state !== '' && filter.state !== null;
    });

    if (JSON.stringify(activeFilters) !== JSON.stringify(selectedFilters)) {
      setSelectedFilters(activeFilters);
    }
  }, [filters]);

  const updateFilterValue = useCallback((filter: AvailableFilter, value: string | string[]) => {
    setOpen(false);
    setValue(null);
    filter.setState(value);
    if (filter.type === 'text-with-operator') return;
    setSelectedFilters((prev) =>
      prev.map((f) => (f.key === filter.key ? { ...f, state: value } : f)),
    );
  }, []);

  const clearFilter = useCallback((filter: AvailableFilter) => {
    // Reset filter state based on type
    if (filter.type === 'multiSelect') {
      filter.setState([]);
    } else {
      filter.setState('');
    }
    // Call any additional clear/search handlers
    filter.onClear?.();

    // Remove from selected filters
    setSelectedFilters((prev) => prev.filter((f) => f.key !== filter.key));
  }, []);

  const handleClearAllFilters = useCallback(() => {
    selectedFilters.forEach(clearFilter);
    clearAllFiltersCallback();
    setSelectedFilters([]);
  }, [selectedFilters, clearAllFiltersCallback, clearFilter]);

  // Improved filter value display logic
  const getFilterDisplay = useMemo(() => {
    return selectedFilters
      .filter((f) => f.state)
      .map((filter) => {
        const value = (() => {
          switch (filter.type) {
            case 'select':
              return filter.options?.find((o) => o.value === filter.state)?.label;
            case 'multiSelect':
              return (filter.state as string[])
                .map((v) => filter.options?.find((o) => o.value === v)?.label)
                .filter(Boolean)
                .join(', ');
            case 'text-with-operator':
              return `${filter.options?.find((o) => o.value === filter.valueType)?.label || filter.options?.find((o) => o.value === filter.defaultValue)?.label} ${filter.state}`;
            case 'severitySearch':
              return `${
                filter.options?.find((o) => o.value === filter.valueType)?.label ||
                filter.options?.find((o) => o.value === filter.defaultValue)?.label
              } ${filter.state}`;
            case 'cveSearch':
              return `${
                filter.options?.find((o) => o.value === filter.valueType)?.label ||
                filter.options?.find((o) => o.value === filter.defaultValue)?.label
              } ${filter.state}`;
            case 'text-with-date':
              return `${filter.state} Last ${filter.quickSelect} ${filter.fromDate} ${filter.toDate}`;
            case 'date-input':
              return `${filter.state === 'custom' ? 'custom' : 'Last ' + filter.state}`;
            case 'select-with-operator':
              return `${filter.operatorOptions?.find((o) => o.value === filter.valueType)?.label || filter.options?.find((o) => o.value === filter.defaultValue)?.label} ${filter.state}`;
            default:
              return filter.state;
          }
        })();
        return {
          key: filter.key,
          display: `${filter.label}: ${value}`,
          filter,
        };
      });
  }, [selectedFilters]);

  return (
    <div className={cn(`flex flex-row gap-6 w-full justify-start`, className)}>
      <Popover open={open} onOpenChange={handleSelectionClose}>
        <PopoverTrigger asChild>
          <Button variant="outline" role="combobox" aria-expanded={open} title="Add filter">
            <FilterIcon className="w-4 h-4" />
            {isAnyFilterActive && (
              <span className="ml-2 text-xs bg-primary/20 rounded-full px-2">
                {selectedFilters.length}
              </span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="min-w-[200px] p-0 w-full">
          {value ? (
            <div className="p-2">
              <FilterRenderer
                filter={filters.find((filter) => filter.key === value)}
                onUpdate={updateFilterValue}
                onClear={clearFilter}
                value={value}
              />
            </div>
          ) : (
            <Command>
              <CommandInput placeholder="Search filter..." />
              <CommandList>
                <CommandEmpty>No filter found.</CommandEmpty>
                <CommandGroup>
                  {filters.map((filter) => (
                    <CommandItem
                      key={filter.key}
                      value={filter.key}
                      onSelect={(currentValue) => handleFilterSelect(currentValue)}
                    >
                      {filter.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          )}
        </PopoverContent>
      </Popover>
      {selectedFilters.length > 0 && (
        <div className="ml-2 flex gap-2 flex-wrap">
          {getFilterDisplay.map(({ key, display, filter }) => (
            <div
              key={key}
              className="flex items-center gap-1 bg-slate-100 text-sm text-muted-foreground rounded-md px-2 py-1 "
            >
              <Popover>
                <PopoverTrigger asChild>
                  <span className="cursor-pointer">{display}</span>
                </PopoverTrigger>
                <PopoverContent className={filter.type === 'domainSearch' ? 'w-fit ' : 'w-full'}>
                  <div>
                    <FilterRenderer
                      filter={filter}
                      onUpdate={updateFilterValue}
                      onClear={clearFilter}
                      value={key}
                    />
                  </div>
                </PopoverContent>
              </Popover>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  clearFilter(filter);
                }}
                className="ml-1 text-muted-foreground hover:text-foreground"
              >
                ×
              </button>
            </div>
          ))}
        </div>
      )}
      {isAnyFilterActive && (
        <Button variant="outline" onClick={handleClearAllFilters}>
          Clear all filters
        </Button>
      )}
    </div>
  );
}

export default UniversalFilter;
