import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Link, List, ListItem } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const AssetSources = ({ entry }) => {
  const [collapsedDomains, setCollapsedDomains] = useState({});

  useEffect(() => {
    if (entry && entry.sources) {
      const domains = Object.keys(entry.sources);
      const initialCollapsedStates = domains.reduce((acc, domain, index) => {
        // Collapse all except the last domain
        acc[domain] = index !== domains.length - 1;
        return acc;
      }, {});
      setCollapsedDomains(initialCollapsedStates);
    }
  }, []);

  const toggleCollapse = (domain) => {
    setCollapsedDomains((prev) => ({ ...prev, [domain]: !prev[domain] }));
  };

  return (
    <Box maxHeight="500px" overflowY="auto">
      <Box className="asset-overview-container">
        <Box className="asset-overview-content">
          {entry &&
            entry.sources &&
            Object.entries(entry.sources).map(([domain, sources], domainIdx, arr) => (
              <Box
                key={domainIdx}
                className="domain-container"
                style={{ marginLeft: `${domainIdx * 20}px`, marginBottom: '20px' }}
              >
                <Button
                  size="sm"
                  onClick={() => toggleCollapse(domain)}
                  width="fit-content"
                  leftIcon={collapsedDomains[domain] ? <ChevronRightIcon /> : <ChevronDownIcon />}
                >
                  {domain}
                </Button>
                {domainIdx < arr.length - 1 && collapsedDomains[domain] && (
                  <Box
                    className="custom-arrow"
                    style={{
                      content: '',
                      position: 'absolute',
                      marginLeft: '50px',
                      borderLeft:
                        '10px solid transparent' /* Adjust left side of the arrow for a sharper look */,
                      borderRight:
                        '10px solid transparent' /* Adjust right side of the arrow for a sharper look */,
                      borderTop:
                        '20px solid black' /* Adjust bottom side of the arrow, pointing down */,
                    }}
                  ></Box>
                )}
                {!collapsedDomains[domain] && (
                  <List marginLeft={10} marginTop={5}>
                    {sources.map((source, idx) => {
                      if (source.includes('CRT.SH')) {
                        const crt_id = source.split(':')[1];
                        const base_url = 'https://crt.sh?id=';
                        const url = base_url.concat(crt_id);
                        return (
                          <ListItem key={idx}>
                            <Link href={url} isExternal className="source-link">
                              {source}
                            </Link>
                          </ListItem>
                        );
                      } else {
                        return <ListItem key={idx}>{source}</ListItem>;
                      }
                    })}
                  </List>
                )}
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AssetSources;
