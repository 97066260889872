import type { UpdateIssuePayload } from '@/api/issues.api';
import { getIssues, updateIssue } from '@/api/issues.api';
import { getUsers } from '@/api/users.api';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Issue, ISSUE_STATUSES } from '@/types/api/response/issues';
import { UserWithOrganization } from '@/types/api/response/user';
import { AlertCircle, AlertTriangle, FileWarning, Info, Siren } from 'lucide-react';
import { Suspense, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageNumbering from '../../GridComponents/PageNumbering';
import { AssigneeSelector } from './AssigneeSelector';
import { NewIssue, NewIssuePayload } from './NewIssue';
import { getResourceDisplayValue } from './ResourceSelector';
import { StatusSelector } from './StatusSelector';

type IssueStatus = keyof typeof ISSUE_STATUSES;

export const getSeverityDisplay = (
  severity: Issue['severity'],
  showLabel: boolean = false,
  size: number = 5,
) => {
  const icons = {
    critical: <Siren className={`w-${size} h-${size} text-red-500`} fillOpacity={0.2} />,
    high: <AlertCircle className={`w-${size} h-${size} text-red-500`} fillOpacity={0.2} />,
    medium: <AlertTriangle className={`w-${size} h-${size} text-yellow-500`} fillOpacity={0.2} />,
    low: <FileWarning className={`w-${size} h-${size} text-green-500`} fillOpacity={0.2} />,
    informational: <Info className={`w-${size} h-${size} text-blue-500`} fillOpacity={0.2} />,
  };

  return (
    <div className="flex items-center space-x-2">
      {icons[severity]}
      {showLabel && <span className="capitalize">{severity}</span>}
    </div>
  );
};

const Issues = () => {
  const [isNewIssueOpen, setIsNewIssueOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Issue[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [error, setError] = useState<string | null>(null);
  const [users, setUsers] = useState<UserWithOrganization[]>([]);
  const navigate = useNavigate();

  const fetchIssues = async () => {
    try {
      setLoading(true);
      const response = await getIssues(page);
      setData(response.entries);
      setTotalPages(response.total_pages);
    } catch (err) {
      setError('Failed to load issues');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIssues();
  }, [page]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        setUsers(response.entries);
      } catch (err) {
        console.error('Failed to fetch users:', err);
      }
    };

    fetchUsers();
  }, []);

  const onSubmit = async (data: NewIssuePayload) => {
    setIsNewIssueOpen(false);
    await fetchIssues();
  };

  const totalCount = data?.length ?? 0;

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleIssueUpdate = async (issueId: number, updates: UpdateIssuePayload) => {
    try {
      await updateIssue(issueId, updates);
      setData((prevData) =>
        prevData.map((issue) =>
          issue.id === issueId ? { ...issue, ...(updates as Partial<Issue>) } : issue,
        ),
      );
    } catch (err) {
      console.error('Failed to update issue:', err);
    }
  };

  const handleStatusChange = async (issueId: number, newStatus: IssueStatus) => {
    const updates: UpdateIssuePayload = { status: newStatus };
    const issue = data.find((issue) => issue.id === issueId);
    if (issue) {
      updates.affected_resource_model_type = issue.affected_resource_model_type;
      updates.affected_resource_object_id = issue.affected_resource_object_id;
    }
    await handleIssueUpdate(issueId, updates);
  };

  const handleAssigneeChange = async (issueId: number, newAssigneeId: string) => {
    const updates: UpdateIssuePayload = { assigned_to_id: parseInt(newAssigneeId) };
    const issue = data.find((issue) => issue.id === issueId);
    if (issue) {
      updates.affected_resource_model_type = issue.affected_resource_model_type;
      updates.affected_resource_object_id = issue.affected_resource_object_id;
    }
    await handleIssueUpdate(issueId, updates);
  };

  const handleIssueClick = (issueId: number, event: React.MouseEvent) => {
    if (
      (event.target as HTMLElement).closest('.status-popover, .assignee-popover, [role="dialog"]')
    ) {
      return;
    }
    navigate(`/issues/${issueId}`);
  };

  const TableRowSkeleton = () => (
    <TableRow>
      <TableCell className="text-center">
        <Skeleton className="h-4 w-[80px] mx-auto" />
      </TableCell>
      <TableCell className="text-center">
        <Skeleton className="h-4 w-[100px] mx-auto" />
      </TableCell>
      <TableCell className="text-center">
        <Skeleton className="h-4 w-[250px] mx-auto" />
      </TableCell>
      <TableCell className="text-center">
        <Skeleton className="h-4 w-[150px] mx-auto" />
      </TableCell>
      <TableCell className="text-center">
        <Skeleton className="h-4 w-[150px] mx-auto" />
      </TableCell>
      <TableCell className="text-center">
        <Skeleton className="h-4 w-[120px] mx-auto" />
      </TableCell>
      <TableCell className="text-center">
        <Skeleton className="h-4 w-[150px] mx-auto" />
      </TableCell>
    </TableRow>
  );

  return (
    <div className="container mx-auto p-4">
      <div className="bg-table_header_bg p-2 rounded mb-4 flex justify-between items-center border-b border-gray-300">
        <div className="flex items-center gap-4">
          <Button
            onClick={() => setIsNewIssueOpen(true)}
            className="bg-[#f05941] hover:bg-[#f05941]/90"
          >
            New Issue
          </Button>
        </div>
        <PageNumbering totalPages={totalPages} offset={page} handlePageChange={handlePageChange} />
      </div>

      <NewIssue
        users={users}
        isOpen={isNewIssueOpen}
        onOpenChange={setIsNewIssueOpen}
        onSubmit={onSubmit}
      />

      <div className="w-full rounded-md border">
        <Table>
          <TableHeader className="bg-gray-100">
            <TableRow>
              <TableHead className="text-black font-semibold text-left">Severity</TableHead>
              <TableHead className="text-black font-semibold text-left">Title</TableHead>
              <TableHead className="text-black font-semibold text-left">Status</TableHead>
              <TableHead className="text-black font-semibold text-left">
                Affected Resource
              </TableHead>
              <TableHead className="text-black font-semibold text-left">Assigned To</TableHead>
              <TableHead className="text-black font-semibold text-left">ID</TableHead>
              <TableHead className="text-black font-semibold text-left">Created</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className="bg-white">
            <Suspense
              fallback={[...Array(10)].map((_, index) => (
                <TableRowSkeleton key={index} />
              ))}
            >
              {loading
                ? [...Array(10)].map((_, index) => <TableRowSkeleton key={index} />)
                : data.map((issue) => (
                    <TableRow
                      key={issue.id}
                      className="cursor-pointer hover:bg-gray-300/30"
                      onClick={(e) => handleIssueClick(issue.id, e)}
                    >
                      <TableCell className="text-left text-black">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <div className="inline-flex items-center justify-start transition-transform hover:scale-110">
                                {getSeverityDisplay(issue.severity)}
                              </div>
                            </TooltipTrigger>
                            <TooltipContent className="capitalize">{issue.severity}</TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </TableCell>
                      <TableCell className="text-left text-black">{issue.title}</TableCell>
                      <TableCell className="text-left text-black">
                        <StatusSelector
                          currentStatus={issue.status}
                          onStatusChange={(status) =>
                            handleStatusChange(issue.id, status as IssueStatus)
                          }
                          triggerClassName="status-popover flex justify-start"
                        />
                      </TableCell>
                      <TableCell className="text-left text-black">
                        {getResourceDisplayValue(
                          issue.affected_resource,
                          issue.affected_resource_model_type,
                        )}
                      </TableCell>
                      <TableCell className="text-left text-black">
                        <AssigneeSelector
                          users={users}
                          assigneeId={issue.assigned_to?.user?.id}
                          assigneeName={issue.assigned_to?.user?.username}
                          onAssigneeChange={(userId) =>
                            handleAssigneeChange(issue.id, userId.toString())
                          }
                          triggerClassName="assignee-popover flex justify-start"
                        />
                      </TableCell>
                      <TableCell className="text-left text-black">{issue.issue_number}</TableCell>
                      <TableCell className="text-left text-gray-400 text-xs">
                        {new Date(issue.created_at).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
            </Suspense>
          </TableBody>
        </Table>
      </div>
      <div className="flex justify-between items-center p-2.5 border-b border-gray-200">
        <p className="text-sm">Total Issues: {totalCount}</p>
        <PageNumbering totalPages={totalPages} offset={page} handlePageChange={handlePageChange} />
      </div>
    </div>
  );
};

export default Issues;
