import { SuggestionsSelector } from '@/components/common/SuggestionsSelector';
import { getNVDSuggestions } from '../../api/nvd.api';
import { NVDVulnerability } from '../../types/api/response/nvd';

interface VulnerabilitySelectorProps {
  selectedVulnerabilityType: string | null;
  selectedVulnerability: NVDVulnerability | null;
  onVulnerabilitySelect: (
    vulnerabilityType: string | null,
    vulnerability: NVDVulnerability | null,
  ) => void;
  onClear: () => void;
}

const getVulnerabilityDisplayValue = (vulnerability: NVDVulnerability | null) => {
  if (!vulnerability) return null;
  return <span className="flex items-center">{vulnerability.cve_id || 'Unknown CVE'}</span>;
};

const getVulnerabilityDisplaySuggestion = (vulnerability: NVDVulnerability) => {
  return vulnerability.cve_id || 'Unknown CVE';
};

const fetchVulnerabilitySuggestions = async (vulnerabilityType: string, query: string) => {
  const response = await getNVDSuggestions(query);
  return response.entries || [];
};

export const VulnerabilitySelector = ({
  selectedVulnerabilityType,
  selectedVulnerability,
  onVulnerabilitySelect,
  onClear,
}: VulnerabilitySelectorProps) => {
  const vulnerabilityTypes = ['NVDVulnerabilities'];

  return (
    <SuggestionsSelector
      placeholderText="Vulnerability"
      modelTypes={vulnerabilityTypes}
      selectedModelType={selectedVulnerabilityType}
      selectedItem={selectedVulnerability}
      onSelect={onVulnerabilitySelect}
      onClear={onClear}
      fetchSuggestions={fetchVulnerabilitySuggestions}
      getDisplayValue={getVulnerabilityDisplayValue}
      getDisplaySuggestion={getVulnerabilityDisplaySuggestion}
    />
  );
};
