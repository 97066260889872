import { fetchWrapper } from '../lib/fetchWrapper';
import { NVDVulnerability, NVDSearchResponse } from '../types/api/response/nvd';

export const getNVDById = async (id: number): Promise<NVDVulnerability> => {
  const { data } = await fetchWrapper.get<NVDVulnerability>(`/nvd/${encodeURIComponent(id)}`);
  return data;
};

export const getNVDByCVE = async (cveId: string): Promise<NVDVulnerability> => {
  const { data } = await fetchWrapper.get<NVDVulnerability>('/nvd', {
    params: {
      cve_id: cveId,
    },
  });
  return data;
};

export const getNVDSuggestions = async (
  search?: string,
  size: number = 20,
  page: number = 1
): Promise<NVDSearchResponse> => {
  const { data } = await fetchWrapper.get<NVDSearchResponse>('/nvd', {
    params: {
      search,
      size,
      page,
    },
  });
  return data;
};

export default {
  getNVDById,
  getNVDByCVE,
  getNVDSuggestions,
};
