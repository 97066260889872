import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { UserWithOrganization } from '@/types/api/response/user';
import { Loader2, Pencil } from 'lucide-react';
import { useState } from 'react';

interface AssigneeSelectorProps {
  users: UserWithOrganization[];
  assigneeId?: number;
  assigneeName?: string;
  onAssigneeChange: (userId: number) => void;
  triggerClassName?: string;
  loading?: boolean;
}

export const AssigneeSelector = ({
  users,
  assigneeId,
  assigneeName = 'Unassigned',
  onAssigneeChange,
  triggerClassName = 'cursor-pointer hover:underline text-left',
  loading = false,
}: AssigneeSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  const handleAssigneeSelect = (userId: number) => {
    const selectedUser = users.find((user) => user.id === userId);
    if (selectedUser) {
      onAssigneeChange(userId);
      setIsOpen(false);
    }
  };

  return (
    <Popover open={isOpen} onOpenChange={handleOpenChange}>
      <PopoverTrigger
        className={triggerClassName}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <div className="group flex items-center gap-2">
          <span>{assigneeName}</span>
          <Pencil className="h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity" />
        </div>
      </PopoverTrigger>
      <PopoverContent
        className="w-64 p-0 bg-gray-50 border border-gray-300"
        data-click-bypass="true"
      >
        <div className="flex flex-col max-h-[200px] overflow-y-auto [&::-webkit-scrollbar-thumb]:bg-gray-200 [&::-webkit-scrollbar]:w-2">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <Loader2 className="h-4 w-4 animate-spin" />
            </div>
          ) : (
            users.map((user) => (
              <button
                key={user.id}
                className={`p-2 text-left hover:bg-gray-100 text-gray-900 ${
                  user.id === assigneeId ? 'bg-gray-50' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAssigneeSelect(user.id);
                }}
              >
                <div className="flex items-center gap-2">
                  <span>{user.user.username}</span>
                </div>
              </button>
            ))
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
