import { Button, Flex, HStack, Text, Tooltip, useDisclosure, useToast } from '@chakra-ui/react';
import { faDownload, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useState } from 'react';
import remarkGfm from 'remark-gfm';
import { useAuth } from '../Helpers/AuthContext'; // Import useAuth from AuthContext
import Markdown from './Markdown';
import './Notes.css';
import { createAlertFromNote } from './NotesAPI';

const NotesList = ({ notes, onDelete, onEdit }) => {
  const { username } = useAuth();
  const [editNote, setEditNote] = useState(null); // State to manage the note being edited
  const [noteText, setNoteText] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [severity, setSeverity] = useState('');
  const [title, setTitle] = useState('');
  const toast = useToast();

  const areDatesWithinOneSecond = (dateStr1, dateStr2) => {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);

    const differenceInSeconds = Math.abs(date1 - date2) / 1000;
    return differenceInSeconds <= 1;
  };

  const handleEditNote = (note) => {
    //setEditNote(note); // Set the note to be edited
    onEdit(note);
  };

  const handleDeleteNote = (noteId) => {
    onDelete(noteId);
    // Implement the logic to handle note deletion
  };

  const preloadImages = (element) => {
    const images = element.getElementsByTagName('img');
    const loadPromises = Array.from(images).map(
      (image) =>
        new Promise((resolve, reject) => {
          if (image.complete && image.naturalHeight !== 0) {
            resolve();
          } else {
            image.onload = resolve;
            image.onerror = reject;
          }
        }),
    );
    return Promise.all(loadPromises);
  };

  const handleDownloadNote = async (note) => {
    const markdownPreviewElement = document.querySelector(`#markdown-preview-${note.id}`);

    if (markdownPreviewElement) {
      await preloadImages(markdownPreviewElement);

      const canvas = await html2canvas(markdownPreviewElement, { useCORS: true });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`note-${note.id}.pdf`);
    }
  };

  const handleCloseNoteCreator = () => {
    setEditNote(null); // Close the NoteCreator modal
  };

  const handleAlertCreate = async (noteID) => {
    try {
      const response = await createAlertFromNote(noteID, severity, title);
      toast({
        title: 'Alert created.',
        description: 'Your alert has been successfully created.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        title: 'An error occurred.',
        description: 'Unable to create your alert.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      console.error(error);
    }
  };

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="notes-list">
      {!notes ? (
        <Text padding={2}>No existing notes for this asset.</Text>
      ) : (
        notes.map((note, index) => (
          <div key={index} className="note">
            <Flex justifyContent="space-between" alignItems="center" width="100%">
              <HStack>
                <Text fontSize="md" className="username">
                  {note.user}
                </Text>
                <Text fontSize="xs" className="date">
                  {formatDate(note.created)}
                </Text>
                {!areDatesWithinOneSecond(note.created, note.last_modified) && (
                  <Text fontSize="xs" className="date">
                    Last modified: {formatDate(note.last_modified)}
                  </Text>
                )}
              </HStack>
              {note.user === username && (
                <HStack spacing={0}>
                  <Tooltip size="xs" label="Download Note" hasArrow>
                    <Button
                      m={1}
                      size="xs"
                      color="black"
                      className="download-note-button"
                      onClick={() => handleDownloadNote(note)}
                    >
                      <FontAwesomeIcon color="black" icon={faDownload} />
                    </Button>
                  </Tooltip>
                  <Tooltip size="xs" label="Edit Note" hasArrow>
                    <Button
                      m={1}
                      size="xs"
                      color="black"
                      className="edit-note-button"
                      onClick={() => handleEditNote(note)}
                      aria-label="Edit Note"
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </Button>
                  </Tooltip>
                  <Tooltip size="xs" label="Delete Note" hasArrow>
                    <Button
                      m={1}
                      size="xs"
                      color="black"
                      className="delete-note-button"
                      onClick={() => handleDeleteNote(note.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </Tooltip>
                </HStack>
              )}
            </Flex>
            <div className="markdown-preview" id={`markdown-preview-${note.id}`}>
              <Markdown remarkPlugins={[remarkGfm]}>{note.text}</Markdown>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default NotesList;
