import { ScrollArea } from '@/components/ui/scroll-area';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';
import Pagination from '@/v2/components/Pagination/Pagination';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  className?: string;
  containerClassName?: string;
  loading?: boolean;
  tableHeight?: string;
  currentPage: number;
  totalPages: number;
  totalEntries: number;
  onPageChange: (page: number) => void;
  error: Error | null;
  maxWidth?: string;
  onRowClick?: (row: TData) => void;
  onRowHover?: (row: TData) => void;
  prefetchChangePage?: (nextPage: number) => void;
}

function LoadingRow({ columns }: { columns: number }) {
  return (
    <TableRow>
      {Array.from({ length: columns }).map((_, index) => (
        <TableCell key={index} className="text-center">
          <Skeleton className="h-6 w-24 mx-auto" />
        </TableCell>
      ))}
    </TableRow>
  );
}

export function DataTable<TData, TValue>({
  columns,
  data,
  className,
  containerClassName,
  loading = false,
  tableHeight = 'calc(100vh - 230px)',
  currentPage,
  totalPages,
  totalEntries,
  onPageChange,
  error,
  maxWidth = 'w-full',
  onRowClick,
  onRowHover,
  prefetchChangePage,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (error) {
    return (
      <div className="w-full p-4 text-center text-red-500 border rounded-md">
        <p>Error: {error.message}</p>
      </div>
    );
  }

  return (
    <div className={`${maxWidth} mx-auto`}>
      <ScrollArea
        style={{ maxHeight: tableHeight }}
        className={cn('w-full rounded-md border mb-4 overflow-auto', containerClassName)}
      >
        <Table className={cn('w-full', className)}>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="font-semibold text-left">
                      {loading ? (
                        <Skeleton className="h-6 w-24 mx-auto" />
                      ) : header.isPlaceholder ? null : (
                        flexRender(header.column.columnDef.header, header.getContext())
                      )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {loading ? (
              Array.from({ length: 5 }).map((_, index) => (
                <LoadingRow key={index} columns={columns.length} />
              ))
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  onMouseEnter={() => onRowHover?.(row.original)}
                  onClick={(e) => {
                    if (window.getSelection()?.toString()) {
                      return;
                    }

                    const target = e.target as HTMLElement;
                    const isInsidePopover = !!target.closest(
                      '[role="dialog"], .status-popover, [data-click-bypass="true"]',
                    );
                    const isClickBypass = !!target.closest('[data-click-bypass="true"]');

                    if (!isInsidePopover && !isClickBypass && onRowClick) {
                      onRowClick(row.original);
                    }
                  }}
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  className={onRowClick ? 'cursor-pointer' : ''}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="text-left">
                      {loading ? (
                        <Skeleton className="h-6 w-24 mx-auto" />
                      ) : (
                        flexRender(cell.column.columnDef.cell, cell.getContext())
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ScrollArea>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        totalEntries={totalEntries}
        onPageChange={onPageChange}
        prefetchChangePage={prefetchChangePage}
      />
    </div>
  );
}
