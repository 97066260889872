interface SmartAIProps {
  className?: string;
  width?: number | string;
  height?: number | string;
  fill?: string;
}

const SmartAI = ({ className, width = 128, height = 128, fill = 'currentColor' }: SmartAIProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      width={width}
      height={height}
      className={className}
      fill={fill}
    >
      <path d="M49.7,43.72l-12,37.44A1.39,1.39,0,0,0,39,83h3.36a1.39,1.39,0,0,0,1.33-1l1.88-6H62l-.23-.37L63.83,82a1.39,1.39,0,0,0,1.33,1h3.36a1.39,1.39,0,0,0,1.33-1.82l-12-37.44a1.4,1.4,0,0,0-1.33-1H51A1.39,1.39,0,0,0,49.7,43.72ZM47.57,70l6.19-19.27L60,70Z" />
      <rect x="79" y="42.75" width="6" height="40.24" rx="1.4" />
      <path d="M67.26,98H38.16A8.16,8.16,0,0,1,30,89.84V38.16A8.16,8.16,0,0,1,38.16,30H89.84A8.16,8.16,0,0,1,98,38.16V64A2.07,2.07,0,0,0,100.12,66h0a11,11,0,0,1,4.91,1.14,2.06,2.06,0,0,0,3-1.87V38.16A18.16,18.16,0,0,0,89.84,20H38.16A18.16,18.16,0,0,0,20,38.16V89.84A18.16,18.16,0,0,0,38.16,108h33.3a2.07,2.07,0,0,0,1.47-3.53,16.13,16.13,0,0,1-3.71-5.06A2.11,2.11,0,0,0,67.26,98Z" />
      <path d="M111.73,101.8l9.06-3.57a1,1,0,0,0,0-1.86l-9.06-3.57a12.61,12.61,0,0,1-7.09-7.09l-3.57-9a1,1,0,0,0-.93-.63,1,1,0,0,0-.93.63l-3.57,9a12.58,12.58,0,0,1-7.09,7.09l-9,3.57a1,1,0,0,0,0,1.86l9,3.57a12.58,12.58,0,0,1,7.09,7.09l3.57,9a1,1,0,0,0,.93.64,1,1,0,0,0,.93-.64l3.57-9A12.61,12.61,0,0,1,111.73,101.8Z" />
    </svg>
  );
};

export default SmartAI;
