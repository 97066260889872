import Cookies from "js-cookie";
import { cookieNames } from "../../constants";

const baseApiUrl = import.meta.env.VITE_REACT_APP_API_URL;

export const changePassword = async (currentPassword, newPassword) => {
    try {
        const CSRFToken = Cookies.get(cookieNames.csrfToken);
        const response = await fetch(`${baseApiUrl}/change-password`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                "X-CSRFToken": CSRFToken,
            },
            body: JSON.stringify({
                current_password: currentPassword,
                new_password: newPassword,
            }),
        });

        return response;
    } catch (error) {
        console.error('Error changing password:', error);
        throw error;
    }
};