import { useFeatureFlag } from '@/context/FeatureFlagContext';
import { cn } from '@/lib/utils';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CodeToggle,
  CreateLink,
  InsertCodeBlock,
  ListsToggle,
  MDXEditor,
  Separator,
  codeBlockPlugin,
  codeMirrorPlugin,
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  toolbarPlugin,
} from '@mdxeditor/editor';
import { useCallback } from 'react';

export default function MarkdownEditor({
  markdown,
  className,
  placeholder,
  onChange,
  onBlur,
  autoFocus,
}: {
  markdown: string;
  placeholder?: string;
  className?: string;
  onChange: (markdown: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
  autoFocus?: boolean;
}) {
  const isV2MarkdownEditorEnabled = useFeatureFlag('V2_MARKDOWN_EDITOR');

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLElement>) => {
      if (e.currentTarget.contains(e.relatedTarget as Node)) {
        return;
      }

      onBlur?.(e);
    },
    [onBlur],
  );

  const buildPlugins = useCallback(
    () => [
      toolbarPlugin({
        toolbarContents: () => (
          <>
            <BlockTypeSelect />
            <BoldItalicUnderlineToggles />
            <Separator />
            <ListsToggle />
            <Separator />
            <CreateLink />
            <Separator />
            <CodeToggle />
            <InsertCodeBlock />
          </>
        ),
      }),
      headingsPlugin(),
      listsPlugin(),
      linkPlugin(),
      linkDialogPlugin(),
      codeBlockPlugin({ defaultCodeBlockLanguage: 'text' }),
      codeMirrorPlugin({
        codeBlockLanguages: {
          text: 'Plain Text',
          shell: 'Shell',
          python: 'Python',
          javascript: 'JavaScript',
          typescript: 'TypeScript',
          json: 'JSON',
          html: 'HTML',
          css: 'CSS',
        },
      }),
    ],
    [],
  );

  return (
    <div className={cn('markdown-editor', className)} tabIndex={-1} onBlur={handleBlur}>
      {isV2MarkdownEditorEnabled === null ? (
        <div className="flex items-center justify-center h-full">
          <div className="w-8 h-8 border-2 border-t-slate-500 border-r-slate-500 border-b-slate-500 border-l-transparent rounded-[50%] animate-[spin_1s_linear_infinite]" />
        </div>
      ) : isV2MarkdownEditorEnabled ? (
        <MDXEditor
          className="w-full h-full border border-input rounded-md shadow-sm overflow-y-auto"
          markdown={markdown}
          plugins={buildPlugins()}
          contentEditableClassName="prose-sm"
          placeholder={<span>{placeholder}</span>}
          onChange={onChange}
          autoFocus={autoFocus}
        />
      ) : (
        <textarea
          className="w-full h-full appearance-none outline-none text-sm border border-input p-2 rounded-md shadow-sm"
          value={markdown}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
      )}
    </div>
  );
}
