import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from "@/components/ui/input";
import { X, ChevronDown } from 'lucide-react';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from '@/components/ui/dropdown-menu';

interface SuggestionsSelectorProps<T> {
  modelTypes: string[];
  selectedModelType: string | null;
  selectedItem: T | null;
  onSelect: (modelType: string | null, item: T | null) => void;
  onClear: () => void;
  fetchSuggestions: (modelType: string, query: string) => Promise<T[]>;
  getDisplayValue: (item: T, modelType: string) => React.ReactNode;
  getDisplaySuggestion: (item: T, modelType: string) => React.ReactNode;
  placeholderText?: string;
}

export function SuggestionsSelector<T>({
  modelTypes,
  selectedModelType,
  selectedItem,
  onSelect,
  onClear,
  fetchSuggestions,
  getDisplayValue,
  getDisplaySuggestion,
  placeholderText = 'Select Type'
}: SuggestionsSelectorProps<T>) {
  const [suggestions, setSuggestions] = useState<T[]>([]);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    
    if (value.length > 1 && selectedModelType) {
      const data = await fetchSuggestions(selectedModelType, value);
      setSuggestions(data);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (suggestion: T) => {
    onSelect(selectedModelType, suggestion);
    setSuggestions([]);
    setInputValue('');
  };

  const handleModelTypeSelect = (modelType: string) => {
    onSelect(modelType, null);
    setInputValue('');
    setSuggestions([]);
  };

  return (
    <div className="flex flex-col space-y-2">
      {selectedItem ? (
        <div className="flex items-center gap-2 w-[90%]">
          <Button
            variant="ghost"
            size="icon"
            onClick={onClear}
            className="h-8 w-8 p-0 bg-red-500/10 hover:bg-red-500/20 rounded-full"
          >
            <X className="h-4 w-4 text-red-500" />
          </Button>
          <div className="flex-grow p-2 rounded">
            <span className="text-black dark:text-white">
              {getDisplayValue(selectedItem, selectedModelType!)}
            </span>
          </div>
        </div>
      ) : (
        <>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="w-[200px] justify-between bg-black text-white hover:bg-black hover:text-white/80">
                {selectedModelType ? selectedModelType : placeholderText}
                <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-gray-50 w-[200px]">
              {modelTypes.map((type) => (
                <DropdownMenuItem
                  key={type}
                  onClick={() => handleModelTypeSelect(type)}
                  className="hover:bg-gray-100"
                >
                  {type}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>

          {selectedModelType && (
            <div className="flex flex-col space-y-2">
              <Input
                value={inputValue}
                onChange={handleInputChange}
                placeholder={`Search ${selectedModelType}`}
                className="bg-transparent border-gray-500 text-black dark:text-white placeholder:text-gray-500 w-1/2"
              />
              {inputValue && suggestions.length > 0 && (
                <div className="bg-gray-50 shadow-md rounded max-h-40 overflow-y-auto">
                  {suggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      className="p-2 cursor-pointer hover:bg-gray-200 text-black"
                      onClick={() => handleSuggestionSelect(suggestion)}
                    >
                      {getDisplaySuggestion(suggestion, selectedModelType)}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
} 